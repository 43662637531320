import { useState } from 'react'
import { Star } from '@mui/icons-material'

const DoctorDetails = ({ doctor, onClose }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className="h-[85vh] w-full flex flex-col">
      <div className="flex flex-col h-full w-full max-w-7xl mx-auto">
        <div className="bg-white rounded-lg flex-1 overflow-hidden flex flex-col">
          {/* Header */}
          <div className="px-6 py-4 border-b flex justify-between">
            <h2 className="text-xl font-semibold text-gray-800">
              Informations du médecin
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Fermer
              </button>
            </div>
          </div>

          <div className="flex-1 flex flex-col overflow-hidden">
            {/* Doctor Information Section */}
            <div className="p-6 space-y-4">
              {/* Main Info Grid */}
              <div className="grid grid-cols-4 gap-4">
                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Nom
                  </label>
                  <div className="w-full px-2 py-1 border rounded-md bg-gray-50">
                    {doctor.firstName}
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Prénom
                  </label>
                  <div className="w-full px-2 py-1 border rounded-md bg-gray-50">
                    {doctor.lastName}
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Numero RPPS
                  </label>
                  <div className="w-full px-2 py-1 border rounded-md bg-gray-50">
                    {doctor.rppsNumber}
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Spécialité
                  </label>
                  <div className="w-full px-2 py-1 border rounded-md bg-gray-50">
                    {doctor.skill || '-'}
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <div className="w-full px-2 py-1 border rounded-md bg-gray-50">
                    {doctor.email || '-'}
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Téléphone
                  </label>
                  <div className="w-full px-2 py-1 border rounded-md bg-gray-50">
                    {doctor.mobileNumber || '-'}
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Fax
                  </label>
                  <div className="w-full px-2 py-1 border rounded-md bg-gray-50">
                    {doctor.phoneNumber || '-'}
                  </div>
                </div>
              </div>
            </div>

            {/* Addresses Section */}
            <div className="px-6 pb-6 flex-1 flex flex-col min-h-0">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold text-gray-800">
                  Addresses
                </h3>
              </div>

              {/* Fixed Tabs */}
              <div className="border-b border-gray-200">
                <div className="flex space-x-2">
                  {doctor.addresses.map((address, index) => (
                    <button
                      key={index}
                      type="button"
                      onClick={() => setActiveTab(index)}
                      className={`px-4 py-2 text-sm font-medium rounded-t-lg ${
                        activeTab === index
                          ? 'bg-indigo-50 text-purple-600 border-t border-l border-r border-gray-200'
                          : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                      }`}
                    >
                      {address.locationName || `Address ${index + 1}`}
                    </button>
                  ))}
                </div>
              </div>

              {/* Scrollable Content */}
              <div className="flex-1 overflow-y-auto mt-4">
                {doctor.addresses.map((address, index) => (
                  <div
                    key={index}
                    className={`${
                      activeTab === index ? 'block' : 'hidden'
                    } bg-white rounded-md`}
                  >
                    <div className="relative bg-indigo-50 p-4 rounded-md">
                      {/* Favorite indicator */}
                      {index === doctor.favoriteAddressIndex && (
                        <div className="absolute top-2 right-2">
                          <Star
                            className="text-yellow-500"
                            fill="currentColor"
                          />
                        </div>
                      )}

                      {/* Address Grid */}
                      <div className="grid grid-cols-2 gap-4 mt-2">
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Nom de l&apos;adresse
                          </label>
                          <div className="w-full px-2 py-1 border rounded-md bg-white">
                            {address.locationName || '-'}
                          </div>
                        </div>
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Type d&apos;établissement
                          </label>
                          <div className="w-full px-2 py-1 border rounded-md bg-white">
                            {address.locationType || '-'}
                          </div>
                        </div>
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Adresse ligne 1
                          </label>
                          <div className="w-full px-2 py-1 border rounded-md bg-white">
                            {address.line1}
                          </div>
                        </div>
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Adresse ligne 2
                          </label>
                          <div className="w-full px-2 py-1 border rounded-md bg-white">
                            {address.line2 || '-'}
                          </div>
                        </div>
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Code postal
                          </label>
                          <div className="w-full px-2 py-1 border rounded-md bg-white">
                            {address.postalCode}
                          </div>
                        </div>
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Ville
                          </label>
                          <div className="w-full px-2 py-1 border rounded-md bg-white">
                            {address.city}
                          </div>
                        </div>
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Pays
                          </label>
                          <div className="w-full px-2 py-1 border rounded-md bg-white">
                            {address.country}
                          </div>
                        </div>
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            FINESS
                          </label>
                          <div className="w-full px-2 py-1 border rounded-md bg-white">
                            {address.finessNumber || '-'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DoctorDetails
