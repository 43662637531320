import DialogTitle from '@components/dialogs/DialogTitle'
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Button,
  TableContainer,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  Typography,
  CircularProgress,
  DialogContent,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import theme from '@utils/theme'
import { useEffect, useState } from 'react'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import { PatientTable } from '@components/tables/PatientTable'
import { PatientDto, SexEnum } from '@services/api'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  doCancelMerge,
  doFetchMergeHistory,
  doMergePatients,
  fetchOnePatientById,
} from '@state/thunks/patientsThunk'
import ConfirmationDialog from '@components/dialogs/ConfirmationDialog'
import { PatientMergeHistoryDto } from '@services/PatientService'
import moment from 'moment'
import { findMedicalOrders } from '@state/thunks/medicalOrderThunk'
import { animated, useSpring } from 'react-spring'

const renderSelectedPatients = (patient: PatientDto[]) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>IPP</TableCell>
            <TableCell>Prénom</TableCell>
            <TableCell>Nom</TableCell>
            <TableCell>Date de naissance</TableCell>
            <TableCell>Sexe</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {patient.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                {row.external_id.map((eid) => eid.value).join(',')}
              </TableCell>
              <TableCell>{row.firstName}</TableCell>
              <TableCell>{row.lastName}</TableCell>
              <TableCell>{row.birthDate}</TableCell>
              <TableCell>
                {row.sex ? (row.sex === SexEnum.M ? 'Homme' : 'Femme') : ''}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export interface PatientDialogProps {
  patientId: number
  open: boolean
  onClose: () => void
}

export interface PatientDialogProps {
  patientId: number
  open: boolean
}

export interface PatientInit {
  firstName?: string
  lastName?: string
  birthDate?: string
  ipp?: string
  originIpp?: string
  sex?: string
}

export default function PatientFusionDialog({
  patientId,
  open,
  onClose,
}: PatientDialogProps) {
  const dispatch = useAppDispatch()
  const { patientDetails, mergeHistory } = useAppSelector(({ patients }) => ({
    patientDetails: patients.currentPatientInfo,
    mergeHistory: patients.mergeHistories,
  }))

  const [updatable, setUpdatable] = useState(false)
  const [selectedSourcePatients, setSelectedSourcePatients] = useState<
    PatientDto[]
  >([])
  const [targetPatient, setTargetPatient] = useState<PatientDto[]>([])
  const [isPatientSelectionOpen, setIsPatientSelectionOpen] = useState({
    patientToKeep: false,
    patientToMerge: false,
  })
  const [isMergeLoading, setisMergeLoading] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatable(event.target.checked)
  }

  useEffect(() => {
    dispatch(fetchOnePatientById({ id: patientId }))
  }, [patientId])

  useEffect(() => {
    dispatch(doFetchMergeHistory(patientId))
  }, [patientId])
  useEffect(() => {
    setIsMounted(true)
  }, [])

  const onConfirmMerge = () => {
    if (targetPatient.length === 0 || selectedSourcePatients.length === 0) {
      return
    }
    setisMergeLoading(true)
    for (const patient of selectedSourcePatients) {
      dispatch(
        doMergePatients({
          sourcePatientId: patient.id,
          targetPatientId: targetPatient[0].id,
        }),
      ).then(() => {
        dispatch(doFetchMergeHistory(patientId))
        dispatch(findMedicalOrders({}))
        setisMergeLoading(false)
      })
    }
  }

  const handleMergeCancel = (mergeId: number) => {
    setisMergeLoading(true)
    dispatch(doCancelMerge({ pId: patientId, mergeId })).finally(() => {
      dispatch(doFetchMergeHistory(patientId))
      dispatch(findMedicalOrders({}))
      setisMergeLoading(false)
    })
  }

  const renderMergeHistoryItem = (
    item: PatientMergeHistoryDto,
    onCancelClick: (id: number) => void,
  ) => {
    return (
      <TableRow key={item.id}>
        <TableCell>{item.sourcePatient.id}</TableCell>
        <TableCell>{item.sourcePatient.firstName}</TableCell>
        <TableCell>{item.sourcePatient.lastName}</TableCell>
        <TableCell>
          {moment(item.sourcePatient.birthDate).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell>
          {item.sourcePatient.sex === 'M' ? 'Homme' : 'Femme'}
        </TableCell>
        <TableCell
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            minHeight: '40px',
          }}
        >
          <i style={{ fontSize: '26px' }} className="fas fa-arrow-right"></i>
        </TableCell>

        <TableCell>{item.targetPatient.id}</TableCell>
        <TableCell>{item.targetPatient.firstName}</TableCell>
        <TableCell>{item.targetPatient.lastName}</TableCell>
        <TableCell>
          {moment(item.targetPatient.birthDate).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell>
          {item.targetPatient.sex === 'M' ? 'Homme' : 'Femme'}
        </TableCell>
        <TableCell>
          {item.mergedBy.firstName + ' ' + item.mergedBy.lastName}
        </TableCell>
        <TableCell>
          {moment(item.createdAt).format('DD/MM/YYYY HH:mm')}
        </TableCell>
        <TableCell>
          {item.reversedBy
            ? `Par: ${item.reversedBy.firstName[0]}. ${
                item.reversedBy.lastName
              } le ${moment(item.reversedAt).format('DD/MM/YYYY HH:mm')}`
            : '-'}
        </TableCell>
        <TableCell>
          {!item.reversedAt && (
            <ConfirmationDialog
              title="Annuler la fusion?"
              description={`Tous les examens et documents transférés au patient #${item.sourcePatient.id} seront restaurés au patient #${item.targetPatient.id}`}
              response={() => {
                onCancelClick(item.id)
              }}
            >
              {(showDialog) => (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    showDialog()
                  }}
                  style={{ color: 'white' }}
                >
                  <i className="fas fa-undo" style={{ marginRight: '4px' }}></i>
                  Annuler la fusion
                </Button>
              )}
            </ConfirmationDialog>
          )}
        </TableCell>
      </TableRow>
    )
  }

  // fade in animation
  const historyTableStyles = useSpring({
    from: { opacity: 0, marginTop: 150 },
    to: { opacity: 1, marginTop: 0 },
    delay: 200,
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          background: '#F5F8FA',
          width: '100%',
        },
      }}
      fullScreen
    >
      <DialogTitle
        onClose={onClose}
        title="Recherche de patients à fusionner"
        style={{
          background: '#fff',
          color: theme.palette.primary.main,
        }}
      />
      <DialogContent
        style={{ height: '100%', overflowY: 'hidden', paddingBottom: '20px' }}
      >
        <Box
          sx={{
            marginTop: '5px',
            height: '100%',
          }}
        >
          <Box
            sx={{
              marginTop: '5px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={updatable}
                  onChange={handleChangeSwitch}
                  color="secondary"
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                />
              }
              label={updatable ? 'Homonymes' : 'Selon critères'}
            />
            <DoubleArrowIcon
              color="secondary"
              fontSize="large"
              sx={{ marginLeft: '-5px' }}
            />
            <ConfirmationDialog
              title="Confirmer la fusion"
              description="Etes-vous sûr de vouloir fusionner ces patients ?"
              response={onConfirmMerge}
            >
              {(showDialog) => (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor:
                      isMergeLoading ||
                      (targetPatient.length === 0 &&
                        selectedSourcePatients.length === 0)
                        ? 'lightgray'
                        : '#1abc9c',
                  }}
                  sx={{ color: '#fff', fontWeight: 'bold' }}
                  onClick={() => {
                    showDialog()
                  }}
                  disabled={
                    isMergeLoading ||
                    (targetPatient.length === 0 &&
                      selectedSourcePatients.length === 0)
                  }
                >
                  {isMergeLoading ? (
                    <CircularProgress
                      style={{ color: 'gray', marginRight: 4 }}
                      size={18}
                    />
                  ) : (
                    <i
                      style={{ fontSize: 20, marginRight: 4 }}
                      className="fas fa-check-circle"
                    ></i>
                  )}{' '}
                  Confirmer la fusion
                </Button>
              )}
            </ConfirmationDialog>
          </Box>
          <Grid item xs={12}>
            <Divider>{updatable ? 'Homonymes' : 'Selon critères'}</Divider>
          </Grid>
          {!updatable ? (
            <div>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  spacing={2}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    marginTop: '15px',
                    paddingRight: '24px',
                  }}
                >
                  <Button
                    onClick={() =>
                      setIsPatientSelectionOpen({
                        patientToKeep: false,
                        patientToMerge: true,
                      })
                    }
                    variant="contained"
                    style={{ color: 'white' }}
                    disabled={isMergeLoading}
                  >
                    Selectionner les patients à fusionner
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    marginTop: '15px',
                    paddingLeft: '24px',
                  }}
                >
                  <Button
                    onClick={() => {
                      setIsPatientSelectionOpen({
                        patientToKeep: true,
                        patientToMerge: false,
                      })
                    }}
                    variant="contained"
                    style={{ color: 'white' }}
                    disabled={isMergeLoading}
                  >
                    Selectionner le patient à conserver
                  </Button>
                </Grid>

                <Dialog
                  open={
                    isPatientSelectionOpen.patientToKeep ||
                    isPatientSelectionOpen.patientToMerge
                  }
                  maxWidth="xl"
                >
                  <DialogTitle
                    onClose={() =>
                      setIsPatientSelectionOpen({
                        patientToMerge: false,
                        patientToKeep: false,
                      })
                    }
                    title="Selection du patient à conserver"
                  />
                  <DialogContent>
                    {(isPatientSelectionOpen.patientToKeep ||
                      isPatientSelectionOpen.patientToMerge) && (
                      <PatientTable
                        patientToSearch={patientDetails}
                        multipleSelection={
                          isPatientSelectionOpen.patientToMerge
                        }
                        onConfirm={(patient) => {
                          if (isPatientSelectionOpen.patientToMerge) {
                            setSelectedSourcePatients(patient as PatientDto[])
                          } else {
                            setTargetPatient(patient as PatientDto[])
                          }
                        }}
                        selectedRows={
                          isPatientSelectionOpen.patientToMerge
                            ? selectedSourcePatients
                            : targetPatient
                        }
                        onClose={() => {
                          setIsPatientSelectionOpen({
                            patientToMerge: false,
                            patientToKeep: false,
                          })
                        }}
                      />
                    )}
                  </DialogContent>
                </Dialog>
              </Grid>
              <Grid container columnSpacing={{ xs: 6 }} marginTop={2}>
                <Grid item xs={6}>
                  {selectedSourcePatients.length !== 0 &&
                    renderSelectedPatients(selectedSourcePatients)}
                </Grid>
                <Grid item xs={6}>
                  {targetPatient.length !== 0 &&
                    renderSelectedPatients(targetPatient)}
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                sx={{ marginTop: '15px', fontWeight: 'bold' }}
              >
                Historique de fusion
              </Typography>
              {isMounted && mergeHistory.length !== 0 && (
                <animated.div style={historyTableStyles}>
                  <TableContainer>
                    <Table sx={{ padding: '8px 6px' }}>
                      <TableHead sx={{ backgroundColor: '#ECEFF3' }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{ fontWeight: 'bold' }}
                            colSpan={5}
                          >
                            Patient source
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}
                          >
                            <Divider
                              orientation="vertical"
                              style={{ minHeight: '40px' }}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontWeight: 'bold' }}
                            colSpan={5}
                          >
                            Patient cible
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>IPP</TableCell>
                          <TableCell>Prénom</TableCell>
                          <TableCell>Nom</TableCell>
                          <TableCell>Date de naissance</TableCell>
                          <TableCell>Sexe</TableCell>
                          <TableCell></TableCell>
                          <TableCell>IPP</TableCell>
                          <TableCell>Prénom</TableCell>
                          <TableCell>Nom</TableCell>
                          <TableCell>Date de naissance</TableCell>
                          <TableCell>Sexe</TableCell>
                          <TableCell>Fusionné par</TableCell>
                          <TableCell>Fusionné le</TableCell>
                          <TableCell>Fusion annulée</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      {mergeHistory.map((item) =>
                        renderMergeHistoryItem(item, handleMergeCancel),
                      )}
                    </Table>
                  </TableContainer>
                </animated.div>
              )}
            </div>
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
