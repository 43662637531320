import React, { useState, useMemo } from 'react'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'

import { Lock, Unlock, History, Plus, ArrowUpDown } from 'lucide-react'
import {
  CashRegisterDTO,
  RegisterStatusEnum,
} from '../../services/dtos/AccountingDto'
import { Button } from '../buttons'
import { Dialog, DialogContent } from '@mui/material'
import CashRegisterForm from '../forms/CashResigsterForm'

// Mock data
const mockCashRegisters: CashRegisterDTO[] = [
  {
    id: 1,
    code: 'CR001',
    name: 'Main Reception',
    location: 'Ground Floor',
    active: true,
    status: RegisterStatusEnum.OPEN,
    currentBalance: 1500.5,
    lastClosingDate: '2024-11-28T18:00:00',
    lastOpeningDate: '2024-11-29T09:00:00',
    transactions: [],
    closingRecord: [],
    createdAt: '2024-01-01T00:00:00',
    updatedAt: '2024-11-29T09:00:00',
  },
  {
    id: 2,
    code: 'CR002',
    name: 'Radiology Desk',
    location: 'First Floor',
    active: true,
    status: RegisterStatusEnum.CLOSED,
    currentBalance: 0,
    lastClosingDate: '2024-11-28T18:00:00',
    lastOpeningDate: '2024-11-28T09:00:00',
    transactions: [],
    closingRecord: [],
    createdAt: '2024-01-01T00:00:00',
    updatedAt: '2024-11-28T18:00:00',
  },
]

type CashRegisterFormData = Pick<
  CashRegisterDTO,
  'code' | 'name' | 'location' | 'active'
>

const CashRegisterTable = () => {
  const [registers, setRegisters] =
    useState<CashRegisterDTO[]>(mockCashRegisters)
  const [selectedRegister, setSelectedRegister] =
    useState<CashRegisterDTO | null>(null)
  const [isMovementDialogOpen, setIsMovementDialogOpen] = useState(false)
  const [isClosingDialogOpen, setIsClosingDialogOpen] = useState(false)
  const [isAddFormOpen, setIsAddFormOpen] = useState(false)

  const statusColors = {
    [RegisterStatusEnum.OPEN]: 'bg-green-100 text-green-800',
    [RegisterStatusEnum.CLOSED]: 'bg-gray-100 text-gray-800',
    [RegisterStatusEnum.SUSPENDED]: 'bg-red-100 text-red-800',
  }

  const handleAddRegister = (formData: CashRegisterFormData) => {
    const newId = Math.max(...registers.map((r) => r.id)) + 1
    const currentDate = new Date().toISOString()

    const register: CashRegisterDTO = {
      ...formData,
      id: newId,
      status: RegisterStatusEnum.CLOSED,
      currentBalance: 0,
      transactions: [],
      closingRecord: [],
      createdAt: currentDate,
      updatedAt: currentDate,
    }

    setRegisters([...registers, register])
    setIsAddFormOpen(false)
  }

  const handleOpenRegister = (registerId: number) => {
    setRegisters(
      registers.map((register) =>
        register.id === registerId
          ? {
              ...register,
              status: RegisterStatusEnum.OPEN,
              lastOpeningDate: new Date().toISOString(),
            }
          : register,
      ),
    )
  }

  const handleCloseRegister = (registerId: number) => {
    setSelectedRegister(registers.find((r) => r.id === registerId) || null)
    setIsClosingDialogOpen(true)
  }

  const handleAddMovement = (registerId: number) => {
    setSelectedRegister(registers.find((r) => r.id === registerId) || null)
    setIsMovementDialogOpen(true)
  }

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'code',
        headerName: 'Code',
        width: 120,
      },
      {
        field: 'name',
        headerName: 'Nom',
        width: 200,
      },
      {
        field: 'location',
        headerName: 'Emplacement',
        width: 150,
      },
      {
        field: 'status',
        headerName: 'Statut',
        width: 130,
        renderCell: (params) => (
          <div
            className={`px-3 py-1 rounded-full ${statusColors[params.value]}`}
          >
            {params.value}
          </div>
        ),
      },
      {
        field: 'currentBalance',
        headerName: 'Solde actuel',
        width: 150,
        renderCell: (params) => (
          <div className="font-mono">
            {new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            }).format(params.value)}
          </div>
        ),
      },
      {
        field: 'lastOpeningDate',
        headerName: 'Dernière ouverture',
        width: 180,
        renderCell: (params) =>
          new Date(params.value).toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 400,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <div className="flex gap-2 justify-end w-full">
            {params.row.status === RegisterStatusEnum.CLOSED && (
              <Button
                variant="outlined"
                size="small"
                text="Ouvrir"
                onClick={() => handleOpenRegister(params.row.id)}
              >
                <Unlock className="w-4 h-4 mr-2" />
                Ouvrir
              </Button>
            )}
            {params.row.status === RegisterStatusEnum.OPEN && (
              <>
                <Button
                  variant="outlined"
                  size="small"
                  text="Fermer"
                  onClick={() => handleCloseRegister(params.row.id)}
                >
                  <Lock className="w-4 h-4 mr-2" />
                  Fermer
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  text="Mouvement"
                  onClick={() => handleAddMovement(params.row.id)}
                >
                  <ArrowUpDown className="w-4 h-4 mr-2" />
                  Mouvement
                </Button>
              </>
            )}
            <Button
              variant="outlined"
              size="small"
              text="Historique"
              onClick={() => console.log('View history:', params.row.id)}
            >
              <History className="w-4 h-4 mr-2" />
              Historique
            </Button>
          </div>
        ),
      },
    ],
    [],
  )

  return (
    <div className="flex flex-col px-4 h-[calc(100vh-140px)] w-full">
      <div className="flex justify-between items-center p-2">
        <h2 className="text-xl font-bold">Caisses</h2>
        <button
          className="bg-indigo-700 hover:bg-indigo-800 text-white px-4 py-2 rounded-md flex items-center"
          onClick={() => setIsAddFormOpen(true)}
        >
          <Plus className="w-4 h-4 mr-2" />
          Nouvelle caisse
        </button>
      </div>

      <div className="flex-1">
        <DataGridPro
          rows={registers}
          columns={columns}
          className="bg-white rounded-2xl"
          initialState={{
            pagination: {
              pageSize: 10,
            },
            pinnedColumns: { right: ['actions'] },
          }}
        />
      </div>

      <CashRegisterForm
        open={isAddFormOpen}
        onOpenChange={setIsAddFormOpen}
        onSubmit={handleAddRegister}
      />

      <Dialog
        open={isMovementDialogOpen}
        onClose={() => setIsMovementDialogOpen(false)}
      >
        <DialogContent className="sm:max-w-[425px]">
          <div className="p-6">
            <h3 className="text-xl font-semibold mb-6">
              Nouveau mouvement de caisse
            </h3>
            {/* Movement form would go here */}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isClosingDialogOpen}
        onClose={() => setIsClosingDialogOpen(false)}
      >
        <DialogContent className="sm:max-w-[600px]">
          <div className="p-6">
            <h3 className="text-xl font-semibold mb-6">Fermeture de caisse</h3>
            {/* Closing form would go here */}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CashRegisterTable
