import { forwardRef, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { patientFormSchema } from '../../utils/schemas'
import {
  FormHelperText,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  TextField,
} from '@mui/material'
import { CreatePatientDTO } from '../../services/dtos'
import AsyncAddressAutocomplete from '@components/AddressSearchAutocomplete'
import FullAddressComponent from './FullAddressComponent'
import InputField, { useStyles } from '../inputs/InputField'
import { DateInputField } from '../inputs/DateInputField'
import { RppsAutocompleteByNameInput } from '../RppsAutocompleteByNameInput'
import { FormattedMessage } from 'react-intl'
import { SexEnum } from '../../services/api'
import moment from 'moment'
import { SSNInput } from '../inputs/SSNInput'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PatientFormRef {
  setValues: (values: CreatePatientDTO) => void
  validate: () => Promise<boolean>
  getValues: () => Promise<CreatePatientDTO | null>
  reset: () => void
}

const PatientFormV2 = forwardRef<PatientFormRef>((_, ref) => {
  const inputClasses = useStyles()

  const {
    setValue,
    watch,
    control,
    register,
    getValues,
    trigger,
    formState: { errors },
    reset,
  } = useForm<CreatePatientDTO>({
    resolver: yupResolver(patientFormSchema),
    defaultValues: {
      sex: SexEnum.M,
    },
  })

  const [isManualAddressVisible, setIsManualAddressVisible] = useState(false)
  const [isManualBirthAddressVisible, setIsManualBirthAddressVisible] =
    useState(false)

  const address = watch('address')
  const placeOfBirth = watch('placeOfBirth')
  const familyDoctor = watch('familyDoctor')
  const phoneNumber = watch('phoneNumber')
  const birthdate = watch('birthDate')
  const sex = watch('sex')
  const ssn = watch('ssn')

  useImperativeHandle(ref, () => ({
    setValues: (values: CreatePatientDTO) => {
      const bday = moment(values.birthDate, 'YYYYMMDD')

      setValue('address', values.address)
      setValue('placeOfBirth', values.placeOfBirth)
      setValue('familyDoctor', values.familyDoctor)
      setValue('sex', values.sex)
      setValue('lastName', values.lastName)
      setValue('motherMaidenName', values.motherMaidenName)
      setValue('firstName', values.firstName)
      setValue('birthDate', bday.isValid() ? (bday as any) : null)
      setValue('phoneNumber', values.phoneNumber)
      setValue('email', values.email)
      setValue('weight', values.weight)
      setValue('height', values.height)
      setValue('ssn', values.ssn)
    },
    validate: () => {
      return trigger()
    },
    getValues: async () => {
      const isValid = await trigger()
      const values = getValues()
      return isValid ? getValues() : null
    },
    reset: () => {
      reset()
    },
  }))

  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return ''
    let cleaned = phoneNumber.replace(/[^\d\s+.-]/g, '')

    if (cleaned.match(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/)) {
      if (cleaned.startsWith('+33')) {
        cleaned = cleaned.replace(
          /^\+33\s*(\d)(\d{2})(\d{2})(\d{2})(\d{2}).*/,
          '$1 $2 $3 $4 $5',
        )
        cleaned = '0' + cleaned
      } else if (cleaned.startsWith('0033')) {
        cleaned = cleaned.replace(
          /^0033\s*(\d)(\d{2})(\d{2})(\d{2})(\d{2}).*/,
          '$1 $2 $3 $4 $5',
        )
        cleaned = '0' + cleaned
      } else {
        cleaned = cleaned.replace(
          /^0\s*(\d)(\d{2})(\d{2})(\d{2})(\d{2}).*/,
          '0$1 $2 $3 $4 $5',
        )
      }
      return cleaned
    }

    return cleaned
  }

  return (
    <Box sx={{ p: 3 }}>
      <form>
        <Box mb={4}>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              color: 'grey.800',
              fontWeight: 600,
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: 0,
                width: 40,
                height: 3,
                bgcolor: 'primary.main',
                borderRadius: 1,
              },
            }}
          >
            Informations personnelles
          </Typography>

          <Grid item xs={12} sm={3}>
            <RadioGroup
              row
              aria-labelledby="sexe"
              defaultValue={'M'}
              value={sex}
              onChange={(e) => setValue('sex', e.target.value as SexEnum)}
            >
              <FormControlLabel
                style={{ color: '#48bef9', fontWeight: 'bold' }}
                value="M"
                control={
                  <Radio
                    value="M"
                    sx={{
                      color: '#48bef9',
                      '&.Mui-checked': {
                        color: '#48bef9',
                      },
                    }}
                  />
                }
                label="Mr"
              />
              <FormControlLabel
                value="F"
                style={{ color: '#f48dde', fontWeight: 'bold' }}
                control={
                  <Radio
                    value="F"
                    sx={{
                      color: '#f48dde',
                      '&.Mui-checked': {
                        color: '#f48dde',
                      },
                    }}
                  />
                }
                label="Mme"
              />
            </RadioGroup>
            {errors.sex && (
              <FormHelperText error>
                {(errors.sex as any)?.message}
              </FormHelperText>
            )}
          </Grid>

          <Grid container spacing={2.5}>
            <Grid item xs={12} sm={3}>
              <InputField control={control} name="lastName" label="Nom" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputField
                control={control}
                name="motherMaidenName"
                label="Nom de jeune fille"
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputField control={control} name="firstName" label="Prénom" />
            </Grid>

            <Grid item xs={12} sm={3}>
              <DateInputField
                control={control}
                name="birthDate"
                label="Date de naissance"
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={phoneNumber}
                name="phoneNumber"
                className={inputClasses.textField}
                type="tel"
                onChange={(e) =>
                  setValue(
                    'phoneNumber',
                    formatPhoneNumber(e.target.value as string),
                  )
                }
                label="Téléphone"
                error={!!errors.phoneNumber}
              />
              {errors.phoneNumber && (
                <FormHelperText error>
                  {(errors.phoneNumber as any)?.message}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputField control={control} name="email" label="Email" />
            </Grid>
          </Grid>
          <Grid container spacing={2.5}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isManualAddressVisible}
                    onChange={(e) =>
                      setIsManualAddressVisible(e.target.checked)
                    }
                    color="primary"
                  />
                }
                label={
                  isManualAddressVisible
                    ? 'Retour à la recherche'
                    : 'Vous ne trouvez pas la bonne adresse ?'
                }
              />
              {isManualAddressVisible ? (
                <FullAddressComponent
                  onChange={(v) => setValue('address', v.address)}
                  value={{
                    address: address,
                    address2: '',
                    zipCode: '',
                    city: '',
                    country: '',
                  }}
                />
              ) : (
                <AsyncAddressAutocomplete
                  onChange={(v) => setValue('address', v)}
                  value={address}
                  defaultSearchText={address}
                />
              )}
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isManualBirthAddressVisible}
                    onChange={(e) =>
                      setIsManualBirthAddressVisible(e.target.checked)
                    }
                    color="primary"
                  />
                }
                label={
                  isManualBirthAddressVisible
                    ? 'Retour à la recherche'
                    : 'Vous ne trouvez pas la bonne adresse ?'
                }
              />
              {isManualBirthAddressVisible ? (
                <FullAddressComponent
                  label="Adresse de naissance"
                  onChange={(v) => setValue('placeOfBirth', v.address)}
                  value={{
                    address: placeOfBirth,
                    address2: '',
                    zipCode: '',
                    city: '',
                    country: '',
                  }}
                />
              ) : (
                <AsyncAddressAutocomplete
                  label="Adresse de naissance"
                  onChange={(v) => setValue('placeOfBirth', v)}
                  value={placeOfBirth}
                  defaultSearchText={placeOfBirth}
                />
              )}
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              color: 'grey.800',
              fontWeight: 600,
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: 0,
                width: 40,
                height: 3,
                bgcolor: 'primary.main',
                borderRadius: 1,
              },
            }}
          >
            Informations médicales
          </Typography>

          <Grid container spacing={2.5}>
            <Grid item sm={2}>
              <InputField
                control={control}
                name="weight"
                label="Poids (kg)"
                type="number"
                error={!!errors.weight}
                helperText={errors.weight?.message}
              />
            </Grid>

            <Grid item sm={2}>
              <InputField
                control={control}
                name="height"
                label="Taille (cm)"
                type="number"
                error={!!errors.height}
                helperText={errors.height?.message}
              />
            </Grid>
            <Grid item sm={4}>
              <SSNInput
                name="ssn"
                birthDate={birthdate ?? undefined}
                birthPlace={placeOfBirth ?? undefined}
                sex={sex as SexEnum}
                value={ssn}
                onChange={(value) => setValue('ssn', value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2.5} mt={2}>
            <Grid item xs={6}>
              <RppsAutocompleteByNameInput
                {...register('familyDoctor')}
                label={
                  <FormattedMessage
                    id="familyDoctor"
                    defaultMessage="Médecin traitant"
                  />
                }
                onChange={(value) => setValue('familyDoctor', value as any)}
                value={familyDoctor}
              />
              {errors?.familyDoctor && (
                <FormHelperText error>
                  {(errors.familyDoctor as any)?.message}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  )
})

PatientFormV2.displayName = 'PatientFormV2'

export default PatientFormV2
