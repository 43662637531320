import { UpTransition } from '@components/animations'
import VisitForm from '@components/forms/VisitForm'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import {
  doFindVisits,
  doRemoveVisit,
  setVisitFilters,
} from '@state/reducers/visitReducer'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import VisitTableToolbar from './Toolbars/VisitTableToolbar'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import { VisitDTO } from '@services/dtos'
import ConfirmDialog from '@components/dialogs/ConfirmDialog'

const VisitsTable = () => {
  const dispatch = useAppDispatch()
  const { filters, visits } = useAppSelector(({ visits }) => ({
    visits: visits.visits,
    filters: visits.filters,
  }))

  const [isVisitFormOpen, setisVisitFormOpen] = useState(false)
  const [editingVisit, seteditingVisit] = useState<VisitDTO | null>(null)
  const [deletingVisit, setdeletingVisit] = useState<VisitDTO | null>(null)

  useEffect(() => {
    dispatch(doFindVisits())
  }, [filters])

  const columns = useMemo(() => {
    const d: GridColumns = [
      {
        field: 'identifier',
        headerName: 'ID',
        width: 100,
      },
      {
        field: 'createdAt',
        headerName: 'Créé le',
        width: 250,
        renderCell: (v) => {
          return moment(v.value).format('DD/MM/YYYY HH:mm')
        },
      },
      {
        field: 'patient.firstName',
        headerName: 'Nom',
        width: 200,
        renderCell: (params) => params.row.patient.firstName,
      },
      {
        field: 'patient.lastName',
        headerName: 'Prénom',
        width: 200,
        renderCell: (params) => params.row.patient.lastName,
      },
      {
        field: 'site',
        headerName: 'Site',
        renderCell: (params) => (
          <span>
            {params.row.site?.label} ({params.row.site?.code})
          </span>
        ),
      },
      {
        field: 'hospitalService',
        headerName: 'Service',
        width: 100,
      },
      {
        field: 'admissionDate',
        headerName: 'Date d\'admission',
        width: 200,
        valueGetter: (params) => {
          return params.row.admissionDate
            ? moment(params.row.admissionDate).format('DD/MM/YYYY')
            : ''
        },
      },
      {
        field: 'dischargedDate',
        headerName: 'Date de sortie',
        width: 200,
        valueGetter: (params) => {
          return params.row.dischargedDate
            ? moment(params.row.dischargedDate).format('DD/MM/YYYY')
            : ''
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        pinnable: false,
        renderCell: (params) => {
          return (
            <div className="flex items-center justify-center">
              <IconButton
                className="btn btn-primary"
                onClick={() => {
                  seteditingVisit(params.row)
                  setisVisitFormOpen(true)
                }}
              >
                <EditIcon color="warning" />
              </IconButton>
              <IconButton
                className="btn btn-danger text-black"
                onClick={() => {
                  setdeletingVisit(params.row)
                }}
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            </div>
          )
        },
      },
    ]
    return d
  }, [])

  const closeVisitForm = () => {
    setisVisitFormOpen(false)
    seteditingVisit(null)
  }

  return (
    <div className="flex flex-1 h-[calc(100vh-100px)]">
      <DataGridPro
        sx={{
          display: 'flex',
          flex: 1,
          padding: '16px',
          backgroundColor: 'white',
          borderRadius: '20px',
        }}
        rows={visits.datas}
        columns={columns}
        paginationMode="server"
        pageSize={filters.limit}
        rowCount={visits.totalCount}
        initialState={{
          pinnedColumns: { right: ['actions'] },
        }}
        onPageChange={(page) => {
          dispatch(
            setVisitFilters({
              page,
            }),
          )
        }}
        components={{
          Toolbar: VisitTableToolbar,
        }}
        componentsProps={{
          toolbar: {
            onAddVisit: () => setisVisitFormOpen(true),
          },
        }}
        pagination
      />
      <Dialog
        fullScreen
        open={isVisitFormOpen}
        onClose={closeVisitForm}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
      >
        <DialogContent>
          {isVisitFormOpen && (
            <VisitForm
              onClose={() => {
                setisVisitFormOpen(false)
                seteditingVisit(null)
              }}
              editingVisit={editingVisit}
            />
          )}
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        title="Suppression"
        message="Voulez-vous vraiment supprimer cette visite ?"
        open={deletingVisit !== null}
        onConfirm={() => {
          if (deletingVisit) {
            dispatch(doRemoveVisit(deletingVisit.id))
            setdeletingVisit(null)
          }
        }}
        onCancel={() => {
          setdeletingVisit(null)
        }}
      />
    </div>
  )
}

export default VisitsTable
