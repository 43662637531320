import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '@state/store'
import { useSnackbar } from 'notistack'
import { IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { AlertActions } from '@state/actions'

interface Notification {
  key: number
  message: string
  options?: any
  dismissed?: boolean
}

let displayed: number[] = []

export default function Notifier() {
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const notifications = useSelector(
    ({ alert }) => alert.notifications,
  ) as Notification[]

  const storeDisplayed = (id: number) => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = (id: number) => {
    displayed = [...displayed.filter((key) => id !== key)]
  }

  const removeSnackbar = (key: number) => ({
    type: AlertActions.REMOVE_SNACKBAR,
    payload: key,
  })

  useEffect(() => {
    notifications.forEach((notification) => {
      if (!notification || typeof notification.message !== 'string') {
        return
      }

      const { key, message, options = {}, dismissed = false } = notification

      if (dismissed) {
        closeSnackbar(key)
        return
      }

      if (displayed.includes(key)) return

      enqueueSnackbar(message, {
        key,
        action: (key: number) => (
          <IconButton aria-label="delete" onClick={() => closeSnackbar(key)}>
            <Delete />
          </IconButton>
        ),
        ...options,
        onExited: (event, myKey) => {
          dispatch(removeSnackbar(myKey as number))
          removeDisplayed(myKey as number)
        },
      })

      storeDisplayed(key)
    })
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  return null
}
