import { TextField } from '@mui/material'
import { useState } from 'react'

interface AddressData {
  address: string
  address2: string
  zipCode: string
  city: string
  country: string
}

interface FullAddressComponentProps {
  onChange: (data: AddressData) => void
  value?: AddressData
  label?: string
}

const FullAddressComponent = ({
  onChange,
  value,
  label,
}: FullAddressComponentProps) => {
  const [addressData, setAddressData] = useState<AddressData>({
    address: value?.address || '',
    address2: value?.address2 || '',
    zipCode: value?.zipCode || '',
    city: value?.city || '',
    country: value?.country || '',
  })

  const handleChange =
    (field: keyof AddressData) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newData = {
        ...addressData,
        [field]: event.target.value,
      }
      setAddressData(newData)
      onChange(newData)
    }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <TextField
        fullWidth
        label={label || 'Adresse Ligne 1'}
        value={addressData.address}
        onChange={handleChange('address')}
      />
      <TextField
        fullWidth
        label="Adresse Ligne 2"
        value={addressData.address2}
        onChange={handleChange('address2')}
      />
      <div style={{ display: 'flex', gap: '16px' }}>
        <TextField
          label="Code Postal"
          value={addressData.zipCode}
          onChange={handleChange('zipCode')}
        />
        <TextField
          fullWidth
          label="Ville"
          value={addressData.city}
          onChange={handleChange('city')}
        />
      </div>
      <TextField
        fullWidth
        label="Pays"
        value={addressData.country}
        onChange={handleChange('country')}
      />
    </div>
  )
}

export default FullAddressComponent
