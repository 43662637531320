import { FeatureCollection } from 'geojson'
import axios from 'axios'

export class BanService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = 'https://address.ether.healthcare'
  }

  public async search(q: string): Promise<{ place: string; id: string }[]> {
    const result = await axios.get<FeatureCollection>(
      `${this.baseUrl}/search?type=municipality&q=${q.replace(/\s/g, '-')}&limit=4`
    )

    const values: { id: string; place: string }[] = []
    result.data.features.forEach((f) => {
      values.push({
        place: f.properties?.postcode.substring(0, 5) + ', ' + f.properties?.city,
        id: f.properties?.id
      })
    })

    return values
  }

  public async searchFullAddress(q: string): Promise<{ place: string; id: string; postCode: string }[]> {
    const result = await axios.get<FeatureCollection>(`${this.baseUrl}/search?&q=${q.replace(/\s/g, '-')}&limit=4`)

    const values: { id: string; place: string; postCode: string }[] = []
    result.data.features.forEach((f) => {
      values.push({
        place: f.properties?.label,
        id: f.properties?.id,
        postCode: f.properties?.postcode || '',
      })
    })

    return values
  }
}
