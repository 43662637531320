import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Suspense, lazy } from 'react'

import AppLayout from '../layouts/appLayout'
import AuthLayout from '../layouts/authLayout'
import NotFound from './auth/NotFound'

const Profile = lazy(() => import('./app/Profile'))
const Home = lazy(() => import('./app/Home'))
const Sites = lazy(() => import('./app/site/Sites'))
const Clients = lazy(() => import('./app/client/Clients'))
const Preferences = lazy(() => import('./app/preferences/Preferences'))
import Members from './app/member/Members'
import Equipments from './app/equipment/Equipments'
import Exams from './app/procedure/Exams'
import Login from './auth/Login'
import ForgottenPassword from './auth/ForgottenPassword'
import ResetPassword from './auth/ResetPassword'
import VerificationEmail from './auth/VerificationEmail'
import Ambulances from './app/ambulance/Ambulances'
import Rooms from './app/room/Rooms'
import Roles from './app/role-permission/Roles'
import Questions from './app/questions/Questions'
import Stats from './app/stats/Stats'
import Billing from './app/billing/Billing'
import InsiView from './app/billing/InsiView'
import Logs from './app/log/Logs'
import MedicalOrderTransport from './app/ambulance/MedicalOrderTransport'
import Worklists from './app/Worklists/Worklists'
import ResipLayout from '../layouts/ResipLayout'
import { useEffect } from 'react'
import ParamterContainer from './app/parameters/ParamterContainer'

export default function Router() {
  function AppointmentRedirect() {
    return (
      <div style={{ width: '100%', height: '100vh' }}>
        <h2>Let&apos;s open scheduling here using current auth token</h2>
        {/* <iframe
          src="http://172.18.162.21:3002/"
          style={{ width: '100%', height: '100%', border: 'none' }}
          title="Appointment App"
        /> */}
      </div>
    )
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/auth" element={<AuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="forgotten-password" element={<ForgottenPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="verification-mail" element={<VerificationEmail />} />
          </Route>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<Worklists />} />
            <Route path="profile" element={<Profile />} />
            <Route path="sa">
              <Route index element={<Home />} />

              <Route path="clients" element={<Clients />}>
                <Route path=":clientId" element={<Preferences />} />
              </Route>
            </Route>
            <Route path="/ambulances">
              <Route path="" element={<Ambulances />} />
              <Route
                path="assign-transport"
                element={<MedicalOrderTransport />}
              />
            </Route>
            <Route path="parameters" element={<ParamterContainer />} />
            <Route path="m">
              <Route index element={<Home />} />
              <Route path="worklist" element={<Worklists />} />
              <Route path="rooms" element={<Rooms />} />
              <Route path="roles" element={<Roles />} />
              <Route path="equipment" element={<Equipments />} />
              <Route path="equipements" element={<Equipments />} />
              <Route path="logs" element={<Logs />} />
              <Route path="members" element={<Members />} />
              <Route path="procedures" element={<Exams />} />
              <Route path="questions" element={<Questions />} />
              <Route path="preferences" element={<Preferences />} />
              <Route path="sites" element={<Sites />}></Route>
              <Route path="billing" element={<Billing />}></Route>
              <Route path="insi" element={<InsiView />}></Route>
              <Route path="appointment" element={<AppointmentRedirect />} />

              <Route path="preferences" element={<Preferences />} />
              <Route path="stats" element={<Stats />} />
              <Route path="sites" element={<Sites />}></Route>
              <Route path="fse/resip" element={<ResipLayout />}></Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
