import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'
import { MemberDto } from '../services/api'
import { TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { doFindMembers } from '../state/thunks/memberThunk'
import { Pencil } from 'lucide-react'
import { Box, IconButton } from '@mui/material'

type Props = {
  label: string
  value: MemberDto | null
  onChange: (value: MemberDto | null) => void
  defaultValue?: MemberDto | null
}

const MemberAutocomplete = ({
  label,
  value,
  onChange,
  defaultValue,
}: Props) => {
  const dispatch = useAppDispatch()
  const members = useAppSelector((state) => state.member.members)

  useEffect(() => {
    dispatch(doFindMembers({ limit: 1000 }))
  }, [])

  if (value) {
    return (
      <Box className="flex items-center gap-2 p-2 rounded-lg bg-gray-100 shadow-sm">
        <span className="font-bold text-gray-800">
          {`${value.lastName} ${value.firstName}`}
        </span>
        <IconButton
          size="small"
          onClick={() => onChange(null)}
          className="text-blue-600 hover:bg-blue-100"
        >
          <Pencil size={16} />
        </IconButton>
      </Box>
    )
  }

  return (
    <Autocomplete
      options={members.datas}
      getOptionLabel={(option) => `${option.lastName} ${option.firstName}`}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={label}
          className="rounded-lg"
          InputLabelProps={{
            className: 'text-blue-600',
          }}
        />
      )}
      value={value}
      onChange={(_, v) => onChange(v)}
      defaultValue={defaultValue}
    />
  )
}

export default MemberAutocomplete
