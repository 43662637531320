import React, { useEffect, useState } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import moment from 'moment'
import { formatSsnPrefix } from '../../utils/ssnUtil'
import { SexEnum } from '../../services/api'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#f8f9fa',
      border: 'none',
      '& fieldset': {
        border: '1px solid #e9ecef',
      },
      '&:hover': {
        backgroundColor: '#f1f3f5',
      },
      '&.Mui-focused': {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 2px #e9ecef',
      },
    },
    '& .MuiInputBase-input': {
      padding: 16,
    },
  },
}))

interface SSNInputProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  birthDate?: Date
  birthPlace?: string
  sex?: SexEnum
  value?: string
  onChange?: (value: string) => void
}

export const SSNInput: React.FC<SSNInputProps> = ({
  birthDate,
  birthPlace,
  sex,
  value = '',
  onChange,
  ...props
}) => {
  const classes = useStyles()

  const [suffixValue, setSuffixValue] = useState('')
  const [prefix, setPrefix] = useState('')

  useEffect(() => {
    if (value && !prefix) {
      const cleaned = value.replace(/\s/g, '')
      if (cleaned.length === 15) {
        const prefix = cleaned.substring(0, 10)
        setSuffixValue(cleaned.substring(10))
        setPrefix(formatSsnPrefix(prefix))
        return
      }
    }
    if (value && !suffixValue) {
      const removeSpaces = (v: string) => v.replace(/\s/g, '')
      const cleaned = removeSpaces(value)
      if (cleaned.length < 12) {
        return
      }
      const prefixLength = removeSpaces(prefix).length
      const suffix = cleaned.substring(prefixLength)
      setSuffixValue(suffix)
      return
    }
    if (!birthDate || !birthPlace || !sex) {
      setPrefix('')
      return
    }
    const zipCode = birthPlace.split(',')[0]
    try {
      const formattedDate = moment(birthDate).format('YYYYMM')
      const gender = sex === 'M' ? '1' : '2'
      const ssnPrefix = `${gender} ${formattedDate.substring(
        2,
        4,
      )} ${formattedDate.substring(4, 6)} ${zipCode}`
      setPrefix(formatSsnPrefix(ssnPrefix))
    } catch (error) {
      console.error('Error computing SSN prefix:', error)
    }
  }, [birthDate, birthPlace, sex, value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value
    setSuffixValue(val)
    onChange?.(`${prefix}${val}`)
  }

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <TextField
          {...props}
          disabled
          value={prefix}
          label="Numéro SS"
          InputLabelProps={{
            shrink: true,
            style: {
              color: 'black',
            },
          }}
          sx={{
            width: 'auto',
            '& .MuiInputBase-input': { color: 'text.primary' },
            '& .MuiOutlinedInput-notchedOutline': {
              borderRight: 1,
            },
            '& .MuiOutlinedInput-root': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
            backgroundColor:
              !birthDate || !birthPlace || !sex || props.disabled
                ? '#F5F5F5'
                : 'inherit',
          }}
          className={classes.textField}
        />
        <TextField
          {...props}
          disabled={!birthDate || !birthPlace || !sex || props.disabled}
          value={suffixValue}
          onChange={handleChange}
          className={classes.textField}
          style={{
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
          }}
          InputProps={{
            inputProps: {
              maxLength: 5,
            },
          }}
          sx={{
            ...props.sx,
            '& .MuiOutlinedInput-root': {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderLeft: 0,
            },
            backgroundColor:
              !birthDate || !birthPlace || !sex || props.disabled
                ? '#F5F5F5'
                : 'inherit',
          }}
        />
      </div>
    </div>
  )
}
