import DialogTitle from '@components/dialogs/DialogTitle'
import { DialogContent } from '@mui/material'
import { Box, Button, Icon, DialogActions, Grid } from '@mui/material'
import Text from '@components/Text'

import Dialog from '@mui/material/Dialog'
import theme from '@utils/theme'
import { useIntl } from 'react-intl'
import FormAddBank from './form/FormAddBank'
import { useDispatch } from 'react-redux'
import { Actions } from '@state/actions'
import { useSelector } from '@state/store'
import { deleteType } from '@state/thunks/procedureThunk'
import { useCallback, useState } from 'react'
import { DataTable } from '@components/tables'
import {
  GridColumns,
  GridRowId,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export interface PatientDialogProps {
  patientId: number
  open: boolean
  setOpen: (open: boolean) => void
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <Box>
      {value === index && <Box sx={{ paddingTop: '3px' }}>{children}</Box>}
    </Box>
  )
}

export default function MutualView() {
  const dispatch = useDispatch()
  const [value, setValue] = useState(0)
  const [updatable, setUpdatable] = useState(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openMultiDeleteModal, setOpenMultiDeleteModal] = useState(false)
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [openAddModal, setOpenAddModal] = useState(false)

  const intl = useIntl()

  const { loading } = useSelector(({ loading }) => ({
    loading:
      loading[Actions.PROCEDURE_TYPE_FIND_ALL] ||
      loading[Actions.PROCEDURE_TYPE_CREATE] ||
      loading[Actions.PROCEDURE_TYPE_UPDATE],
  }))

  const columns: GridColumns = [
    {
      field: 'col1',
      headerName: 'Action',
      width: 80,
      sortable: false,
      renderCell: (value) => (
        <div
          style={{
            width: '100%',
          }}
        >
          <Icon
            fontSize="small"
            style={{ marginRight: '10px' }}
            className="fas fa-marker"
            onClick={() => setOpenUpdateModal(true)}
            sx={{ color: '#3F3F3F' }}
          />
          <Icon
            fontSize="small"
            className="fas fa-trash-alt"
            onClick={() => setOpenDeleteModal(true)}
            sx={{ color: '#3F3F3F' }}
          />
        </div>
      ),
    },
    {
      field: 'col2',
      headerName: 'Code',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.code}`,
    },
    {
      field: 'col3',
      headerName: 'Libellé',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.label}`,
    },
    {
      field: 'col4',
      headerName: 'Date de mise à jour',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.updateDate}`,
    },
    {
      field: 'col5',
      headerName: 'Utilisateur',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.user}`,
    },
  ]

  const onRefresh = useCallback(() => {
    // TODO
    // dispatch(findAllMedicalOrder())
  }, [dispatch])

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }
  const _onDeleteConfirm = () => {
    dispatch(deleteType(selectedRows))
    setOpenDeleteModal(false)
  }

  const onCloseAdd = () => {
    setOpenAddModal(false)
  }

  const onCloseUpdate = () => {
    setOpenUpdateModal(false)
  }

  const onCloseDelete = () => {
    setOpenDeleteModal(false)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const medicalOrderMockup = [
    {
      id: 0,
      code: 'CARB',
      label: 'Caisse de retraite des barbiers',
      updateDate: '2021-12-12',
      user: 'UCLOT',
    },
    {
      id: 0,
      code: 'CARB',
      label: 'Caisse de retraite des barbiers',
      updateDate: '2021-12-12',
      user: 'UCLOT',
    },
    {
      id: 0,
      code: 'CARB',
      label: 'Caisse de retraite des barbiers',
      updateDate: '2021-12-12',
      user: 'UCLOT',
    },
    {
      id: 0,
      code: 'CARB',
      label: 'Caisse de retraite des barbiers',
      updateDate: '2021-12-12',
      user: 'UCLOT',
    },
  ]

  return (
    <Grid style={{ height: '85vh' }}>
      <DataTable
        rows={medicalOrderMockup}
        columns={columns}
        loading={loading}
        size={35}
        isDetails
        toolBarProps={{
          title: 'Banques',
          icon: 'fas fa-university',
          onAdd: () => setOpenAddModal(true),
          onRefresh,
          onDelete: () => {
            selectedRows.length
              ? setOpenMultiDeleteModal(true)
              : alert('please select at least one reception')
          },
        }}
        detailPanelHeight={400}
        form={(props) => <></>}
        checkboxSelection
        onSelectionModelChange={_onSelectionChange}
      />
      {/* modal add fund */}
      <Dialog
        open={openAddModal}
        onClose={onCloseAdd}
        PaperProps={{
          style: {
            borderRadius: 20,
            minWidth: '50vw',
            maxWidth: '80vw',
            background: '#E2E6F2',
            width: '65%',
          },
        }}
      >
        <DialogTitle
          onClose={onCloseAdd}
          title="Ajouter une banque"
          style={{
            background: '#fff',
            color: theme.palette.primary.main,
          }}
        />
        <DialogContent
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 30,
            paddingTop: 10,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              marginTop: '5px',
            }}
          >
            <FormAddBank updatable={true} />
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                alert('CRUD en cours')
              }}
            >
              Sauvegarder
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* modal update */}
      <Dialog
        open={openUpdateModal}
        onClose={onCloseUpdate}
        PaperProps={{
          style: {
            borderRadius: 20,
            minWidth: '50vw',
            maxWidth: '80vw',
            background: '#E2E6F2',
            width: '65%',
          },
        }}
      >
        <DialogTitle
          onClose={onCloseUpdate}
          title="Modifier une banque"
          style={{
            background: '#fff',
            color: theme.palette.primary.main,
          }}
        />
        <DialogContent
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 30,
            paddingTop: 10,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              marginTop: '5px',
            }}
          >
            <FormAddBank updatable={false} />
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                alert('CRUD en cours')
              }}
            >
              modifier
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {/* modal delete */}
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          onClose={() => setOpenDeleteModal(false)}
          title="Supression"
          style={{
            background: '#fff',
            color: theme.palette.primary.main,
          }}
        />
        <DialogContent
          style={{
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
            background: '#E2E6F2',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{
              fontWeight: 'bold',
              paddingTop: '20px',
              paddingBottom: '20px',
            }}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
            paddingBottom: '20px',
            paddingTop: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <Button
            sx={{
              ':hover': {
                backgroundColor: '#333333',
              },
              color: 'white',
              background: '#3F3F3F',
              borderRadius: 5,
            }}
            variant="contained"
            color="success"
            fullWidth
            onClick={() => setOpenDeleteModal(false)}
          >
            Annuler
          </Button>
          <Button
            style={{ color: 'white' }}
            variant="contained"
            color="secondary"
            fullWidth
            sx={{ borderRadius: 5 }}
            onClick={_onDeleteConfirm}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      {/* modal multi delete */}
      <Dialog
        open={openMultiDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          onClose={() => setOpenMultiDeleteModal(false)}
          title="Supression multiple"
          style={{
            background: '#fff',
            color: theme.palette.primary.main,
          }}
        />
        <DialogContent
          style={{
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
            background: '#E2E6F2',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{
              fontWeight: 'bold',
              paddingTop: '20px',
              paddingBottom: '20px',
            }}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
            paddingBottom: '20px',
            paddingTop: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <Button
            sx={{
              ':hover': {
                backgroundColor: '#333333',
              },
              color: 'white',
              background: '#3F3F3F',
              borderRadius: 5,
            }}
            variant="contained"
            color="success"
            fullWidth
            onClick={() => setOpenMultiDeleteModal(false)}
          >
            Annuler
          </Button>
          <Button
            style={{ color: 'white' }}
            variant="contained"
            color="secondary"
            fullWidth
            sx={{ borderRadius: 5 }}
            onClick={_onDeleteConfirm}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
