import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
  FormLabel,
  Checkbox,
  DialogActions,
} from '@mui/material'

interface CashRegisterFormData {
  code: string
  name: string
  location: string
  active: boolean
  initialBalance: number
}

interface CashRegisterFormProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  onSubmit: (data: CashRegisterFormData) => void
  initialData?: Partial<CashRegisterFormData>
}

const defaultValues: CashRegisterFormData = {
  code: '',
  name: '',
  location: '',
  active: true,
  initialBalance: 0,
}

const CashRegisterForm = ({
  open,
  onOpenChange,
  onSubmit,
  initialData = {},
}: CashRegisterFormProps) => {
  const [formData, setFormData] = React.useState<CashRegisterFormData>({
    ...defaultValues,
    ...initialData,
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(formData)
    setFormData(defaultValues)
  }

  const handleCancel = () => {
    setFormData(defaultValues)
    onOpenChange(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => onOpenChange(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogContent>
        <DialogTitle sx={{ px: 0, pt: 0 }}>
          {initialData.code ? 'Modifier la caisse' : 'Nouvelle caisse'}
        </DialogTitle>

        <form onSubmit={handleSubmit}>
          <div className="grid gap-6 py-4">
            <div className="grid gap-4">
              <FormLabel htmlFor="code">Code</FormLabel>
              <Input
                id="code"
                value={formData.code}
                onChange={(e) =>
                  setFormData({ ...formData, code: e.target.value })
                }
                placeholder="CR003"
                fullWidth
                required
              />
            </div>
            <div className="grid gap-4">
              <FormLabel htmlFor="name">Nom</FormLabel>
              <Input
                id="name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                placeholder="Accueil Principal"
                fullWidth
                required
              />
            </div>
            <div className="grid gap-4">
              <FormLabel htmlFor="location">Emplacement</FormLabel>
              <Input
                id="location"
                value={formData.location}
                onChange={(e) =>
                  setFormData({ ...formData, location: e.target.value })
                }
                placeholder="Rez-de-chaussée"
                fullWidth
                required
              />
            </div>
            <div className="grid gap-4">
              <FormLabel htmlFor="initialBalance">Solde initial (€)</FormLabel>
              <Input
                id="initialBalance"
                type="number"
                inputProps={{ step: '0.01' }}
                value={formData.initialBalance}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    initialBalance: parseFloat(e.target.value) || 0,
                  })
                }
                placeholder="0.00"
                fullWidth
                required
              />
            </div>
            <div className="flex items-center gap-4">
              <FormLabel htmlFor="active">Active</FormLabel>
              <Checkbox
                id="active"
                checked={formData.active}
                onChange={(e) =>
                  setFormData({ ...formData, active: e.target.checked })
                }
                sx={{
                  color: '#6366f1',
                  '&.Mui-checked': {
                    color: '#6366f1',
                  },
                }}
              />
            </div>
          </div>
          <DialogActions sx={{ px: 0, pb: 0 }}>
            <button
              onClick={handleCancel}
              className="bg-gray-700 hover:bg-gray-800 text-white px-4 py-2 rounded-md"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="bg-indigo-700 hover:bg-indigo-800 text-white px-4 py-2 rounded-md"
            >
              {initialData.code ? 'Modifier' : 'Créer'}
            </button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default CashRegisterForm
