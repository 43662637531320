import React, { useEffect, useRef, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  CreatePatientDTO,
  CreateVisitDTO,
  DocumentDTO,
  VisitDTO,
} from '@services/dtos'
import { visitSchema } from '@utils/schemas'
import {
  doCreateVisit,
  doGetVisitDocuments,
  doUpdateVisit,
  setVisitDocuments,
} from '@state/reducers/visitReducer'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Spinner } from '@components/loadings'
import { findAll as findSites } from '@state/thunks/siteThunk'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import moment from 'moment'
import {
  doCreateCoverege,
  doUpdateCouverture,
  setPatientDetails,
} from '@state/reducers/patientsReducer'
import { PatientDto, RppsDto, UpdatePatientDto } from '@services/api'
import DocumentsList from '@components/documents/DocumentsList'
import DocumentListViewer from '@components/dialogs/DocumentListViewer'
import { DocumentService } from '@services/StorageService'
import { PatientFormRef } from './PatientFormV2'
import { PatientSearchComponent } from '../patient/PatientSearchComponent'
import {
  doCreatePatient,
  doGetCouverture,
  fetchOnePatientById,
} from '../../state/thunks/patientsThunk'
import ManagePatientComponent, {
  ManagepatientComponentRef,
} from './ManagePatientComponent'
import { Grid } from '@mui/material'
import { updatePatient } from '../../state/thunks/authThunk'
import OrderRecipientList from '../order/OrderRecipientList'
import {
  doUpdateRecipient,
  setRecipients,
} from '../../state/reducers/recipientReducer'
import { RppsAutocompleteByNameInput } from '../RppsAutocompleteByNameInput'

type Props = {
  editingVisit?: VisitDTO | null
  onClose: () => void
}

const FormField = ({
  label,
  error,
  children,
}: {
  label: string
  error?: string
  children: React.ReactNode
}) => (
  <div className="flex flex-col space-y-1">
    <label className="text-sm font-medium text-gray-700">{label}</label>
    {children}
    <div className="h-5">
      {error && <p className="text-red-500 text-sm">{error}</p>}
    </div>
  </div>
)

const VisitForm: React.FC<Props> = ({ editingVisit, onClose }) => {
  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<CreateVisitDTO>({
    defaultValues: editingVisit
      ? {
          patientId: editingVisit.patient.id,
          siteId: editingVisit.site?.id,
          admissionDate: editingVisit.admissionDate,
          dischargedDate: editingVisit.dischargedDate,
          hospitalService: editingVisit.hospitalService,
          referringDoctor: editingVisit.referringDoctor
            ? {
                id: editingVisit.referringDoctor.id,
                rppsNumber: editingVisit.referringDoctor.rppsNumber,
                firstName: editingVisit.referringDoctor.firstName,
                lastName: editingVisit.referringDoctor.lastName,
              }
            : undefined,
        }
      : undefined,
    resolver: yupResolver(visitSchema),
  })

  const dispatch = useAppDispatch()

  const managePatientRef = useRef<ManagepatientComponentRef | null>(null)

  const [isSubmitting, setisSubmitting] = useState(false)
  const [isEditingDocuments, setIsEditingDocuments] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState<DocumentDTO | null>(
    null,
  )

  const { sites, documents, recipients } = useAppSelector(
    ({ site, visits, recipient }) => ({
      sites: site.sites,
      documents: visits.documents,
      recipients: recipient.recipients,
    }),
  )
  const patientId = watch('patientId')
  const admissionDate = watch('admissionDate')
  const dischargedDate = watch('dischargedDate')
  const referringDoctor = watch('referringDoctor')

  const patientRef = useRef<PatientFormRef | null>(null)

  useEffect(() => {
    dispatch(findSites())
    return () => {
      reset()
      dispatch(setPatientDetails(null))
      dispatch(setVisitDocuments([]))
      dispatch(setRecipients([]))
    }
  }, [dispatch])

  useEffect(() => {
    if (editingVisit) {
      dispatch(doGetVisitDocuments(editingVisit.id))
    }
  }, [editingVisit])

  useEffect(() => {
    if (!editingVisit && !patientId) {
      return
    }
    const q = {
      idVisit: editingVisit?.id,
      idPatient: patientId,
    }

    dispatch(doGetCouverture(q))
      .unwrap()
      .then((cv) => {
        const coverageRef = managePatientRef.current?.getCouvertureFormRef()
        if (coverageRef && cv) {
          coverageRef.setValues(cv)
          if (cv.idVisit === editingVisit?.id) {
            managePatientRef.current?.setIsCoverageSet(true)
          }
        }
      })
  }, [patientId, editingVisit])

  const onSubmitForm = async (data: CreateVisitDTO) => {
    setisSubmitting(true)
    const patientFormRef = managePatientRef.current?.getPatientFormRef()

    if (!patientFormRef) {
      setisSubmitting(false)
      return
    }
    let patient: PatientDto | null = null
    try {
      const dto = await patientFormRef.getValues()
      if (!dto) {
        setisSubmitting(false)
        return
      }
      if (patientId) {
        patient = await dispatch(
          updatePatient({ id: patientId, body: dto as UpdatePatientDto }),
        ).unwrap()
      } else {
        patient = await dispatch(
          doCreatePatient(dto as CreatePatientDTO),
        ).unwrap()

        const patientDocuments = managePatientRef.current?.getDocuments()
        if (patientDocuments && patientDocuments.length && patient) {
          const pId = patient.id
          const promises = patientDocuments.map((document) =>
            DocumentService.updateDocuments({
              id: document.id,
              dto: { entityId: pId, entityName: 'patient' },
            }),
          )
          await Promise.all(promises)
        }
      }
    } catch (error) {
      setisSubmitting(false)
      return
    }
    if (!patient) {
      setError('patientId', { message: 'Le patient est requis' })
      setisSubmitting(false)
      return
    }

    data.patientId = patient.id

    const cb = async (msg: string) => {
      dispatch(
        enqueueSnackbar({
          message: msg,
          options: { variant: 'success' },
        }),
      )
      onClose()
    }

    const coverage = await managePatientRef.current
      ?.getCouvertureFormRef()
      ?.getValues()

    if (editingVisit) {
      dispatch(
        doUpdateVisit({
          id: editingVisit.id,
          dto: {
            ...data,
            admissionDate: data.admissionDate
              ? new Date(data.admissionDate)
              : undefined,
            dischargedDate: data.dischargedDate
              ? new Date(data.dischargedDate)
              : undefined,
          },
        }),
      )
        .then(async () => {
          cb('Le séjour a été modifié avec succès')
          if (coverage) {
            coverage.idVisit = editingVisit.id
            coverage.idPatient = patientId
            if (!coverage.id) {
              await dispatch(
                doCreateCoverege({ patientId: patientId, dto: coverage }),
              )
            } else {
              await dispatch(
                doUpdateCouverture({
                  id: coverage.id,
                  dto: coverage,
                }),
              )
            }
          }
        })
        .finally(() => setisSubmitting(false))
    } else {
      dispatch(
        doCreateVisit({
          ...data,
          admissionDate: data.admissionDate
            ? new Date(data.admissionDate)
            : undefined,
          dischargedDate: data.dischargedDate
            ? new Date(data.dischargedDate)
            : undefined,
        }),
      )
        .unwrap()
        .then(async (visit) => {
          if (documents.length) {
            for (const document of documents) {
              await DocumentService.updateDocuments({
                id: document.id,
                dto: {
                  entityId: visit.id,
                  entityName: 'visit',
                },
              })
            }
          }
          if (coverage) {
            coverage.idVisit = visit.id
            coverage.idPatient = patientId
            await dispatch(
              doCreateCoverege({ patientId: patientId, dto: coverage }),
            )
          }
          if (recipients.length) {
            for (const recipient of recipients) {
              await dispatch(
                doUpdateRecipient({
                  id: recipient.id,
                  dto: { visitId: visit.id },
                }),
              )
            }
          }

          cb('Le séjour a été créé avec succès')
        })
        .finally(() => setisSubmitting(false))
    }
  }

  const handlePatientChange = (selectedPatientId?: number) => {
    setValue('patientId', selectedPatientId || (undefined as any))
    if (selectedPatientId) {
      dispatch(fetchOnePatientById({ id: selectedPatientId }))
        .unwrap()
        .then((res) => {
          patientRef.current?.setValues(res as any)
        })
    }
  }

  const handleDocumentDeleted = (document: DocumentDTO) => {
    dispatch(setVisitDocuments(documents.filter((d) => d.id !== document.id)))
  }

  const handleAddDocument = (document: DocumentDTO) => {
    dispatch(setVisitDocuments([...documents, document]))
  }

  return (
    <div className="flex w-full h-full p-4">
      <form onSubmit={handleSubmit(onSubmitForm)} className="w-full">
        <div className="flex justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-800">
            Créer un nouveau séjour
          </h2>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-500 hover:bg-indigo-700 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              {editingVisit ? 'Modifier' : 'Créer'}
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-400 hover:bg-gray-500 text-white rounded-lg flex items-center disabled:opacity-50"
              disabled={isSubmitting}
              onClick={onClose}
            >
              {isSubmitting && <Spinner className="mr-2" size={18} />}
              Fermer
            </button>
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid item sm={4}>
            <fieldset className="border border-gray-200 p-4 rounded-lg">
              <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
                Séjour
              </legend>

              <FormField label="Site" error={errors.siteId?.message}>
                <select
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  {...register('siteId')}
                  defaultValue={editingVisit?.siteId}
                >
                  {sites.datas.map((site) => (
                    <option key={site.id} value={site.id}>
                      {site.label}
                    </option>
                  ))}
                </select>
              </FormField>

              <div className="grid grid-cols-2 gap-4 mt-4">
                <FormField
                  label="Date de début"
                  error={errors.admissionDate?.message}
                >
                  <input
                    type="date"
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    {...register('admissionDate')}
                    onChange={(e) =>
                      setValue(
                        'admissionDate',
                        moment(e.target.value, 'YYYY-MM-DD').toDate(),
                      )
                    }
                    value={
                      admissionDate
                        ? moment(admissionDate).format('YYYY-MM-DD')
                        : ''
                    }
                  />
                </FormField>

                <FormField
                  label="Date de fin"
                  error={errors.dischargedDate?.message}
                >
                  <input
                    type="date"
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    {...register('dischargedDate')}
                    onChange={(e) =>
                      setValue(
                        'dischargedDate',
                        moment(e.target.value, 'YYYY-MM-DD').toDate(),
                      )
                    }
                    value={
                      dischargedDate
                        ? moment(dischargedDate).format('YYYY-MM-DD')
                        : ''
                    }
                  />
                </FormField>
              </div>
              <FormField
                label="Unite Fonctionnelle"
                error={errors.hospitalService?.message}
              >
                <input
                  type="text"
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Entrez le service"
                  {...register('hospitalService')}
                />
              </FormField>
              <FormField
                label="Médecin adresseur"
                error={errors.hospitalService?.message}
              >
                <RppsAutocompleteByNameInput
                  onChange={(v) => setValue('referringDoctor', v || undefined)}
                  value={referringDoctor || null}
                  label="Médecin adresseur"
                  size="small"
                />
              </FormField>
              <Grid item xs={12}>
                <OrderRecipientList visitId={editingVisit?.id} />
              </Grid>
            </fieldset>
            <fieldset className="border border-gray-200 p-4 rounded-lg">
              <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
                Documents
              </legend>
              <DocumentsList
                documents={documents}
                entityId={editingVisit?.id}
                entityName="visit"
                onDocumentDeleted={handleDocumentDeleted}
                handleOnFileUploaded={handleAddDocument}
                isEditingDocuments={isEditingDocuments}
                setisEditingDocuments={setIsEditingDocuments}
                setSelectedDocument={setSelectedDocument}
              />
            </fieldset>
          </Grid>
          <Grid item sm={7}>
            <fieldset className="border border-gray-200 p-4 rounded-lg">
              <legend className="text-lg font-bold mb-4 border border-gray-200 p-2 rounded-lg">
                Patient
              </legend>
              {!!errors.patientId && (
                <div className="h-5">
                  <p className="text-red-500 text-sm">
                    {errors.patientId.message}
                  </p>
                </div>
              )}
              <PatientSearchComponent
                onChange={handlePatientChange}
                defaultValue={patientId}
              />
              <ManagePatientComponent
                patientId={patientId}
                visitId={editingVisit?.id}
                ref={managePatientRef}
              />
            </fieldset>
          </Grid>
        </Grid>
      </form>
      <DocumentListViewer
        selectedDocument={selectedDocument}
        documents={documents}
        onClose={() => setSelectedDocument(null)}
        isOpen={!!selectedDocument}
        onSelectedDocumentChange={setSelectedDocument}
      />
    </div>
  )
}

export default VisitForm
