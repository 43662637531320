import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import PatientFormV2, { PatientFormRef } from './PatientFormV2'
import CouvertureForm, {
  CouvertureFormRef,
} from '../../containers/app/billing/form/CouvertureForm'
import DocumentsList from '../documents/DocumentsList'
import DocumentListViewer from '../dialogs/DocumentListViewer'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import { fetchOnePatientById } from '../../state/thunks/patientsThunk'
import { CreatePatientDTO, DocumentDTO } from '../../services/dtos'
import moment from 'moment'
import { Tooltip } from '@mui/material'
import cartevital from '@assets/img/cartevital.png'
import logoInsi from '@assets/img/logoINSi.png'
import logoADRi from '@assets/img/logoADRi.png'
import {
  FileTextIcon,
  InfoIcon,
  ShieldAlert,
  ShieldIcon,
  TruckIcon,
} from 'lucide-react'
import {
  doGetPatientDocuments,
  setPatientDocuments,
} from '../../state/reducers/patientsReducer'
import { CouvertureDto } from '../../common/interfaces'
import { PatientDto } from '../../services/api'

type ActiveFormType = 'PatientInfos' | 'Couverture' | 'Transport' | 'Documents'

export type ManagepatientComponentRef = {
  getCouvertureFormRef: () => CouvertureFormRef | null
  getPatientFormRef: () => PatientFormRef | null
  setIsCoverageSet: (isSet: boolean) => void
  getDocuments: () => DocumentDTO[]
  setActiveForm: (form: ActiveFormType) => void
  clear: () => void
}

type Props = {
  patientId?: number
  visitId?: number
}

const ManagePatientComponent = forwardRef<ManagepatientComponentRef, Props>(
  ({ patientId }, ref) => {
    useImperativeHandle(ref, () => ({
      getCouvertureFormRef: () => couvertureFormRef.current,
      getPatientFormRef: () => patientFormRef.current,
      setIsCoverageSet: (isSet: boolean) => {
        setIsCoverageSet(isSet)
      },
      getDocuments: () => documents,
      setActiveForm: (form: ActiveFormType) => {
        setActiveForm(form)
      },
      clear: () => {
        patientFormRef.current?.reset()
        dispatch(setPatientDocuments([]))
      },
    }))
    const dispatch = useAppDispatch()

    // ===================================== STATE =====================================

    const [activeForm, setActiveForm] = useState<ActiveFormType>('PatientInfos')
    const patientFormRef = useRef<PatientFormRef>(null)
    const couvertureFormRef = useRef<CouvertureFormRef>(null)
    const [isCoverageSet, setIsCoverageSet] = useState(false)

    const { documents } = useAppSelector((state) => ({
      documents: state.patients.documents,
    }))
    const [isDocumentViewerOpen, setIsDocumentViewerOpen] = useState(false)
    const [selectedDocument, setSelectedDocument] =
      useState<DocumentDTO | null>(null)

    // ===================================== EFFECTS =====================================

    useEffect(() => {
      if (patientId) {
        dispatch(
          fetchOnePatientById({
            id: patientId,
          }),
        )
          .unwrap()
          .then((patient) => {
            patientFormRef.current?.setValues({
              ...patient,
              birthDate: patient.birthDate ? moment(patient.birthDate) : null,
            } as any as CreatePatientDTO)
          })
        dispatch(doGetPatientDocuments(patientId))
      }
    }, [patientId])

    // ===================================== FUNCTIONS =====================================

    const getCoverageIcon = () => {
      if (isCoverageSet) {
        return <ShieldIcon style={{ marginRight: 4, fontSize: 30 }} />
      }
      return <ShieldAlert color="red" size={30} />
    }

    const handleDocumentDeleted = (document: DocumentDTO) => {
      const _documents = documents.filter((d) => d.id !== document.id)
      dispatch(setPatientDocuments(_documents))
    }

    const handleOnFileUploaded = (document: DocumentDTO) => {
      const _documents = [...documents, document]
      dispatch(setPatientDocuments(_documents))
    }

    const handleVitalCardReadFinished = (dto: CouvertureDto) => {
      const bday = moment(dto.beneficaireDateNaissance, 'YYYYMMDD')

      const patientData: Partial<PatientDto> = {
        firstName: dto.beneficiairePrenom,
        birthDate: bday.isValid() ? bday.toDate() : undefined,
        ssn: dto.assureNir,
      }
      patientData.lastName =
        dto.beneficiaireNom || dto.beneficaireNomUsuel || undefined

      patientFormRef.current?.setValues(patientData as any as CreatePatientDTO)
    }

    return (
      <div>
        <div className="flex space-between ">
          <ToggleButtonGroup
            value={activeForm}
            exclusive
            onChange={(_, v) => {
              if (v) {
                setActiveForm(v)
              }
            }}
            size="small"
            sx={{
              position: 'sticky',
              top: '0',
              zIndex: 1001,
              backgroundColor: 'white',
              marginTop: 2,
            }}
          >
            <ToggleButton sx={{ width: '100px' }} value="PatientInfos">
              <InfoIcon size={14} style={{ marginRight: 4 }} />
              Infos
            </ToggleButton>
            <ToggleButton sx={{ width: '100px' }} value="Couverture">
              {getCoverageIcon()}
              Couverture
            </ToggleButton>
            <ToggleButton sx={{ width: '100px' }} value="Transport">
              <TruckIcon size={14} style={{ marginRight: 4 }} />
              Transport
            </ToggleButton>
            <ToggleButton sx={{ width: 'auto' }} value="Documents">
              <FileTextIcon size={14} style={{ marginRight: 4 }} />
              Documents
            </ToggleButton>
          </ToggleButtonGroup>
          {/* actions   */}
          <div className="fixed right-4 z-50 top-[100px]">
            <div className="flex flex-col gap-3 bg-white/80 backdrop-blur-sm p-3 rounded-2xl shadow-lg border border-gray-100 hover:shadow-xl transition-all duration-300">
              <Tooltip title="Lire la carte vitale" placement="left">
                <button
                  type="button"
                  onClick={() =>
                    couvertureFormRef.current?.openVitalCardReader()
                  }
                  className="relative group rounded-xl p-2 bg-gradient-to-br from-blue-50 to-blue-100 hover:from-blue-100 hover:to-blue-200 transform hover:-translate-x-1 hover:scale-105 transition-all duration-300 flex items-center border border-blue-200 overflow-hidden"
                >
                  <div className="relative flex-shrink-0">
                    <img
                      src={cartevital}
                      alt="carte vitale"
                      className="w-[50px] transition-all duration-300 group-hover:scale-110 group-hover:rotate-6"
                    />
                    <div className="absolute inset-0 bg-blue-400 opacity-0 group-hover:opacity-10 transition-opacity duration-300 rounded-lg" />
                  </div>
                </button>
              </Tooltip>
              <Tooltip title="Recherche sans carte vitale" placement="left">
                <button
                  type="button"
                  className="relative group rounded-xl p-2 bg-gradient-to-br from-purple-50 to-purple-100 hover:from-purple-100 hover:to-purple-200 transform hover:-translate-x-1 hover:scale-105 transition-all duration-300 flex items-center border border-purple-200 overflow-hidden"
                >
                  <div className="relative flex-shrink-0">
                    <img
                      src={logoInsi}
                      alt="logoInsi"
                      className="w-[50px] transition-all duration-300 group-hover:scale-110 group-hover:rotate-6"
                    />
                    <div className="absolute inset-0 bg-purple-400 opacity-0 group-hover:opacity-10 transition-opacity duration-300 rounded-lg" />
                  </div>
                </button>
              </Tooltip>
              <Tooltip title="Vérifier" placement="left">
                <button
                  type="button"
                  className="relative group rounded-xl p-2 bg-gradient-to-br from-green-50 to-green-100 hover:from-green-100 hover:to-green-200 transform hover:-translate-x-1 hover:scale-105 transition-all duration-300 flex items-center border border-green-200 overflow-hidden"
                >
                  <div className="relative flex-shrink-0">
                    <img
                      src={logoInsi}
                      alt="logoInsi"
                      className="w-[50px] transition-all duration-300 group-hover:scale-110 group-hover:rotate-6"
                    />
                    <div className="absolute inset-0 bg-green-400 opacity-0 group-hover:opacity-10 transition-opacity duration-300 rounded-lg" />
                  </div>
                </button>
              </Tooltip>
              <Tooltip title="Appeler ADRi" placement="left">
                <button
                  type="button"
                  className="relative group rounded-xl p-2 bg-gradient-to-br from-red-50 to-red-100 hover:from-red-100 hover:to-red-200 transform hover:-translate-x-1 hover:scale-105 transition-all duration-300 flex items-center border border-red-200 overflow-hidden"
                >
                  <img src={logoADRi} alt="logoADRi" className="w-[50px]" />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>

        <div
          style={{ display: activeForm === 'PatientInfos' ? 'block' : 'none' }}
        >
          <PatientFormV2 ref={patientFormRef} />
        </div>
        <div
          style={{ display: activeForm === 'Couverture' ? 'block' : 'none' }}
        >
          <CouvertureForm
            ref={couvertureFormRef}
            onVitalCardReadFinished={handleVitalCardReadFinished}
          />
        </div>
        {activeForm === 'Transport' && <span>WIP</span>}
        {activeForm === 'Documents' && (
          <>
            <DocumentsList
              entityId={patientId}
              entityName="patient"
              documents={documents}
              isEditingDocuments={isDocumentViewerOpen}
              setisEditingDocuments={setIsDocumentViewerOpen}
              onDocumentDeleted={handleDocumentDeleted}
              handleOnFileUploaded={handleOnFileUploaded}
              setSelectedDocument={setSelectedDocument}
            />
            <DocumentListViewer
              documents={documents}
              selectedDocument={selectedDocument}
              isOpen={selectedDocument !== null}
              onClose={() => {
                setSelectedDocument(null)
              }}
              onSelectedDocumentChange={setSelectedDocument}
            />
          </>
        )}
      </div>
    )
  },
)

export default ManagePatientComponent
