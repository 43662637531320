import { useAppDispatch } from '@hooks/reduxHooks'
import {
  TextField,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { useState } from 'react'
import { setOrganismFilters } from '@state/reducers/organismReducer'
import {
  getTypeNumber,
  OrganismViewType,
} from '../../../services/dtos/OrganismDto'

interface OrganismTableToolbarProps {
  onAddOrganism: () => void
  view: OrganismViewType
  onViewChange: (view: OrganismViewType) => void
}

const OrganismTableToolbar = ({
  onAddOrganism,
  view,
  onViewChange,
}: OrganismTableToolbarProps) => {
  const dispatch = useAppDispatch()
  const [searchCode, setSearchCode] = useState('')
  const [searchRegime, setSearchRegime] = useState('')

  const handleSearch = () => {
    dispatch(
      setOrganismFilters({
        code: searchCode || undefined,
        regime: searchRegime || undefined,
        Type: getTypeNumber(view),
        page: 0,
      }),
    )
  }

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: OrganismViewType,
  ) => {
    if (newView !== null) {
      onViewChange(newView)
      setSearchCode('')
      setSearchRegime('')
      dispatch(
        setOrganismFilters({
          code: undefined,
          regime: undefined,
          Type: getTypeNumber(newView),
          page: 0,
        }),
      )
    }
  }

  return (
    <div className="flex flex-wrap items-center gap-4 p-4">
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleViewChange}
        size="small"
      >
        <ToggleButton value="amo">AMO</ToggleButton>
        <ToggleButton value="caisse">Caisse</ToggleButton>
        <ToggleButton value="mutuelle">Mutuelle</ToggleButton>
      </ToggleButtonGroup>

      <TextField
        size="small"
        label="Code"
        value={searchCode}
        onChange={(e) => setSearchCode(e.target.value)}
      />
      <TextField
        size="small"
        label="Régime"
        value={searchRegime}
        onChange={(e) => setSearchRegime(e.target.value)}
      />
      <IconButton onClick={handleSearch} color="primary">
        <SearchIcon />
      </IconButton>
      <div className="flex-grow" />
      <button
        className="bg-indigo-500 hover:bg-indigo-600 flex items-center gap-2 px-4 py-2 rounded-md text-white"
        onClick={onAddOrganism}
      >
        <AddIcon /> Ajouter{' '}
        {view === 'amo'
          ? 'un AMO'
          : view === 'caisse'
          ? 'une caisse'
          : 'une mutuelle'}
      </button>
    </div>
  )
}

export default OrganismTableToolbar
