import { Exam, Member } from '../api'

export interface BankAccountDTO {
  id: number
  code: string
  name: string
  bankName: string
  countryCode: string //eg FR76
  bankCode: string //eg 30002
  branchCode: string //eg 001
  accountNumber: string //eg 112233445566778899
  key: string //eg 11
  active: boolean
  createdAt: string
  updatedAt: string
}

export interface CashRegisterDTO {
  id: number
  code: string
  name: string
  location: string
  active: boolean
  status: RegisterStatusEnum
  currentBalance?: number
  lastClosingDate?: string
  lastOpeningDate?: string
  transactions?: RegisterMovementDTO[]
  closingRecord?: RegisterClosingRecordDTO[]
  createdAt: string
  updatedAt: string
}

export enum RegisterStatusEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  SUSPENDED = 'SUSPENDED',
}

export interface RegisterMovementDTO {
  id: number
  date: string
  amount: number
  type: MovementTypeEnum
  description: string
  createdAt: string
  updatedAt: string
  movementType: MovementTypeEnum
  paymentMethod?: PaymentMethodEnum
}

export enum MovementTypeEnum {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  TRANSFER = 'TRANSFER',
  BANK_DEPOSIT = 'BANK_DEPOSIT',
  CORRECTION = 'CORRECTION',
  OPENING_BALANCE = 'OPENING_BALANCE',
  CLOSING_BALANCE = 'CLOSING_BALANCE',
}

export interface RegisterClosingRecordDTO {
  id: number
  date: string
  startingBalance: number
  endingBalance: number
  theoreticalBalance: number
  difference: number
  cashAmount: number
  cardAmount: number
  checkAmount: number
  voucherAmount: number
  transferAmount: number
  comment: string

  closedBy: Member
  createdAt: string
  updatedAt: string
}

export interface PaymentDTO {
  id: number
  invoiceNumber: string
  registerId: number
  paymentMethod: PaymentMethodEnum
  amount: number
  status: PaymentStatusEnum
  transactionDate: Date
  checkNumber?: string

  createdAt: string
  updatedAt: string
  createdBy: Member
}

export interface PaymentRefundDTO {
  id: number
  paymentId: number
  amount: number
  status: PaymentStatusEnum
  reason: string
  refundMethod: PaymentMethodEnum
  createdAt: string
  updatedAt: string
  createdBy: Member
}

export enum PaymentMethodEnum {
  CASH = 'CASH',
  CHECK = 'CHECK',
  CARD = 'CARD',
  TRANSFER = 'TRANSFER',
  VOUCHER = 'VOUCHER',
  FREE = 'FREE',
  LOSS_PROFIT = 'LOSS_PROFIT',
  NOEMIE_RETURN = 'NOEMIE_RETURN',
  THIRD_PARTY = 'THIRD_PARTY',
}

export enum PaymentStatusEnum {
  PENDING = 'PENDING',
  PAID = 'PAID',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  CANCELLED = 'CANCELLED',
}

export interface InvoiceDTO {
  id: number
  number: string
  amount: number
  status: InvoiceStatusEnum
  date: Date
  dueDate: Date
  paidDate: Date
  paidAmount: number
  remainingAmount: number
  items: Exam[]

  createdAt: string
  updatedAt: string
}

export enum InvoiceStatusEnum {
  OPEN = 'OPEN',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
}

export interface InvoiceCreditDTO {
  id: number
  invoiceId: number
  amount: number
}
