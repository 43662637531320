import React, { useState, useEffect, useMemo } from 'react'
import QuotationTable from './QuotationTable'
import { ExamDto, MedicalOrder } from '@services/api'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import AsyncAutoComplete from '@components/inputs/AsyncAutoComplete'
import {
  findAll as findExams,
  findCcam,
  findNgap,
} from '@state/thunks/procedureThunk'
import {
  doCreateBill,
  doFindPrecotations,
  doSavePrecotations,
  doValidatePrecotations,
  setPrecotation,
  setValidation,
} from '@state/reducers/billingReducer'
import {
  CCAMDto,
  CreateBillDTO,
  OrderDto,
  PrecotationDto,
} from '@services/dtos'
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'

import * as uid from 'uid'
import { updateMedicalOrder } from '@state/thunks/medicalOrderThunk'
import { doRemoveMedicalOrder } from '@state/reducers/orderReducer'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { UpTransition } from '../../../../components/animations'
import BillSummaryComponent from '../../../../components/billing/BillingSummaryComponent'
import { getCartVitalUrl } from '@state/thunks/cardReadThunk'
import { doGetCouverture } from '../../../../state/thunks/patientsThunk'
import { BillingDialog } from '../../billing/BillingDialog'

type Props = {
  order: OrderDto
  onMedicalOrderRemoved: (medicalOrder: MedicalOrder) => void
}
const Quotation: React.FC<Props> = ({ order, onMedicalOrderRemoved }) => {
  // --------------------------------------- Other Hooks ---------------------------------------
  const dispatch = useAppDispatch()

  // --------------------------------------- State ---------------------------------------
  const {
    exams,
    precotations,
    ccams,
    ngaps,
    validation,
    readerId,
    couverture,
  } = useAppSelector(({ procedure, billing, patients }) => ({
    exams: procedure.exams,
    precotations: billing.precotation,
    validation: billing.validation,
    ccams: procedure.ccam,
    ngaps: procedure.ngap,
    readerId: patients.patientCardReaderId,
    couverture: patients.patientCouverture,
  }))
  const [cotationAnchor, setCotationAnchor] =
    React.useState<HTMLButtonElement | null>(null)
  const [isEditingExam, setIsEditingExam] = useState(false)
  const [selectedExam, setselectedExam] = useState<ExamDto>(
    order.medicalOrders[0].exam,
  )
  const [selectedMedicalOrder, setSelectedMedicalOrder] = useState(
    order.medicalOrders[0],
  )
  const [isValidating, setIsValidating] = useState(false)
  const [isFetching, setisFetching] = useState(true)
  const [billUrl, setBillUrl] = useState<string>('')
  const [isCreatingBill, setIsCreatingBill] = useState(false)

  // ---------------------------------------  Hooks ---------------------------------------

  const autocompleteRef = React.useRef<HTMLDivElement>(null)
  const examOptions = useMemo(() => {
    return exams.datas.map((exam) => ({
      label: exam.label,
      value: exam,
    }))
  }, [exams])

  const cotationType = useMemo(() => {
    if (selectedMedicalOrder.exam.cotation_ccam.length !== 0) {
      return 'CCAM'
    } else if (selectedMedicalOrder.exam.cotation_ngap.length !== 0) {
      return 'NGAP'
    }
    return ''
  }, [selectedMedicalOrder.exam])

  useEffect(() => {
    setisFetching(true)
    dispatch(doFindPrecotations(selectedMedicalOrder.id))
      .unwrap()
      .then(async (res) => {
        let ccamCodes = res.reduce((acc, pr) => {
          if (pr.cotation_type === 'CCAM') {
            acc.push(pr.cotation)
          }
          return acc
        }, [] as string[])
        if (selectedMedicalOrder.exam.cotation_ccam.length !== 0) {
          ccamCodes.push(...selectedMedicalOrder.exam.cotation_ccam)
        }
        ccamCodes = Array.from(new Set(ccamCodes))
        let ngapCodes = res.reduce((acc, pr) => {
          if (pr.cotation_type === 'NGAP') {
            acc.push(pr.cotation)
          }
          return acc
        }, [] as string[])

        if (selectedMedicalOrder.exam.cotation_ngap.length !== 0) {
          ngapCodes.push(...selectedMedicalOrder.exam.cotation_ngap)
        }
        ngapCodes = Array.from(new Set(ngapCodes))
        const _precotations: PrecotationDto[] = []
        const ccams = await dispatch(
          findCcam({ codes: ccamCodes, examDate: new Date() }),
        ).unwrap()
        const ngaps = await dispatch(
          findNgap({ codes: ngapCodes, examDate: new Date() }),
        ).unwrap()
        if (res.length === 0) {
          const rows: PrecotationDto[] = ccams.map((ccam) => ({
            cotation: ccam.code,
            cotation_type: 'CCAM',
            prix_unitaire: ccam.prixNonOptam,
            id_medical_order: selectedMedicalOrder.id,
            identifier: uid.uid(10).toString(),
            modificateur: ccam.modificateurs,
          }))

          const ngapRows: PrecotationDto[] = ngaps.map((ngap) => ({
            cotation: ngap.code,
            cotation_type: 'NGAP',
            prix_unitaire: ngap.prix,
            id_medical_order: selectedMedicalOrder.id,
            identifier: uid.uid(10).toString(),
            modificateur: '',
          }))

          _precotations.push(...rows)
          _precotations.push(...ngapRows)
        } else {
          for (const pr of res) {
            if (pr.cotation_type === 'CCAM') {
              const ccam = ccams.find((c) => c.code === pr.cotation)
              if (ccam) {
                const modifiers = pr.modificateur?.split('')
                modifiers?.forEach((m, i) => {
                  pr[`m${i + 1}`] = m
                })
                _precotations.push({
                  ...pr,
                  prix_unitaire: ccam.prixNonOptam,
                  modificateur: ccam.modificateurs,
                })
              }
            } else {
              _precotations.push(pr)
            }
          }
        }
        dispatch(setPrecotation(_precotations))
      })
      .finally(() => {
        setisFetching(false)
      })
  }, [selectedMedicalOrder.exam, cotationType])

  useEffect(() => {
    if (!cotationType) {
      return
    }
    if (cotationType === 'CCAM' && ccams.length === 0) {
      dispatch(
        findCcam({
          codes: selectedMedicalOrder.exam.cotation_ccam,
          examDate: new Date(selectedMedicalOrder.plannedDate),
        }),
      )
    } else if (cotationType === 'NGAP' && ngaps.length === 0) {
      dispatch(
        findNgap({
          codes: selectedMedicalOrder.exam.cotation_ngap,
          examDate: new Date(selectedMedicalOrder.plannedDate),
        }),
      )
    }
  }, [selectedMedicalOrder, cotationType])

  useEffect(() => {
    return () => {
      dispatch(setPrecotation([]))
    }
  }, [])

  useEffect(() => {
    if (order.patient.id) {
      dispatch(
        doGetCouverture({
          idPatient: order.patient.id,
          idVisit: order.visitId,
        }),
      )
    }
  }, [order.patient.id])

  // --------------------------------------- Handlers ---------------------------------------

  const handleAddExam = (exam: ExamDto | null) => {
    if (exam) {
      setselectedExam(exam)
      if (autocompleteRef.current) {
        (autocompleteRef.current as any).clearValue()
      }
    }
  }

  const handleSaveExam = () => {
    if (selectedExam) {
      dispatch(
        updateMedicalOrder({
          id: selectedMedicalOrder.id,
          dto: {
            examId: selectedExam.id,
          },
        }),
      )
      setIsEditingExam(false)
    }
  }

  const handleRemoveExam = (medicalOrder: MedicalOrder) => {
    const isConfirmed = window.confirm(
      'Voulez-vous vraiment supprimer cet examen ?',
    )
    if (!isConfirmed) {
      return
    }
    if (order.medicalOrders.length > 1) {
      let nextExamIndex = 0
      while (order.medicalOrders[nextExamIndex].id === medicalOrder.id) {
        nextExamIndex++
      }
      setSelectedMedicalOrder(order.medicalOrders[nextExamIndex])
      setselectedExam(order.medicalOrders[nextExamIndex].exam)
    }
    dispatch(
      doRemoveMedicalOrder({
        orderId: order.id,
        medicalOrderId: medicalOrder.id,
      }),
    ).then(() => {
      onMedicalOrderRemoved(medicalOrder)
      dispatch(
        enqueueSnackbar({
          message: `Examen "${medicalOrder.exam.label}" supprimé`,
          options: { variant: 'success' },
        }),
      )
    })
  }

  const handleAddQuotationRow = (ccam?: CCAMDto) => {
    const { exam } = selectedMedicalOrder
    const newRow: PrecotationDto = {
      cotation: ccam?.code || '',
      cotation_type: exam.cotation_ccam?.length !== 0 ? 'CCAM' : 'NGAP',
      prix_unitaire: ccam?.prixOptam || 0,
      id_medical_order: selectedMedicalOrder.id,
      identifier: uid.uid(10).toString(),
      modificateur: ccam?.modificateurs || '',
    }
    dispatch(setPrecotation([...precotations, newRow]))
  }

  const onExamSearch = (v: string) => {
    return dispatch(findExams({ search: v })).unwrap()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      (cotationType === 'CCAM' && ccams.length !== 0) ||
      (cotationType === 'NGAP' && ngaps.length !== 0)
    ) {
      setCotationAnchor(event.currentTarget)
    } else {
      const newRow: PrecotationDto = {
        cotation: '',
        cotation_type: cotationType,
        prix_unitaire: 0,
        id_medical_order: selectedMedicalOrder.id,
        identifier: uid.uid(10).toString(),
      }
      dispatch(setPrecotation([...precotations, newRow]))
    }
  }

  const handleClose = () => {
    setCotationAnchor(null)
  }

  const handleValidate = async () => {
    setIsValidating(true)
    dispatch(doValidatePrecotations(precotations)).finally(() => {
      setIsValidating(false)
    })
  }

  const handleSave = async () => {
    setIsValidating(true)
    dispatch(
      doSavePrecotations({
        data: precotations.map((pr) => {
          const r = { ...pr, modificateur: '' }
          const modifiersFields = ['m1', 'm2', 'm3', 'm4']
          for (const field of modifiersFields) {
            if (r[field]) {
              r.modificateur += r[field]
            }
          }
          return r
        }),
        medicalOrderId: selectedMedicalOrder.id,
      }),
    )
      .then(() => {
        dispatch(
          enqueueSnackbar({
            message: 'Cotations enregistrées',
            options: { variant: 'success' },
          }),
        )
      })
      .finally(() => {
        setIsValidating(false)
      })
  }

  const getAllPrecots = async (): Promise<PrecotationDto[]> => {
    const allPrecots: PrecotationDto[] = []
    for (const mo of order.medicalOrders) {
      const precots = await dispatch(doFindPrecotations(mo.id)).unwrap()
      precots.forEach((p) => {
        allPrecots.push(p)
      })
    }
    return allPrecots
  }

  const validatePrecot = async (): Promise<boolean> => {
    const precots = await getAllPrecots()
    setIsValidating(true)
    try {
      const res = await dispatch(doValidatePrecotations(precots)).unwrap()
      if (res.erreurs.length !== 0) {
        return false
      }
      return true
    } catch (e) {
      console.error(e)
      return false
    } finally {
      setIsValidating(false)
    }
  }

  const handleCloseSummary = () => {
    dispatch(setValidation(null))
  }

  const handleCreateBill = async () => {
    if (!order.visitId) {
      return
    }
    setIsCreatingBill(true)
    let _readerId = readerId || ''
    if (!readerId) {
      try {
        const res = await dispatch(getCartVitalUrl()).unwrap()
        _readerId = res.id
      } catch (e) {
        console.error(e)
        setIsCreatingBill(false)
        dispatch(
          enqueueSnackbar({
            message: 'Erreur lors de la récupération de l\'id du lecteur',
            options: { variant: 'error' },
          }),
        )
        return
      }
    }
    const dto: CreateBillDTO = {
      idLecteur: _readerId,
      // TODO : get idResip from selected member
      idResip: 1,
      medicalOrderIds: order.medicalOrders.map((mo) => mo.id),
      idVisit: order.visitId,
    }

    dispatch(doCreateBill(dto))
      .unwrap()
      .then((res) => {
        setBillUrl(res.url)
        handleCloseSummary()
      })
      .finally(() => {
        setIsCreatingBill(false)
      })
  }

  const open = Boolean(cotationAnchor)
  const id = open ? 'simple-popover' : undefined

  return (
    <div className="w-full flex-1 flex flex-col">
      <h3 className="font-bold mb-3">Examen</h3>
      <List className="border border-1 mt-4" style={{ padding: 0 }}>
        {order.medicalOrders.map((mo) => {
          return (
            <ListItem
              key={mo.id}
              button
              onClick={() => setSelectedMedicalOrder(mo)}
              className={'hover:bg-gray-100 cursor-pointer'}
              style={{
                backgroundColor:
                  selectedMedicalOrder.id === mo.id
                    ? 'rgba(26, 188, 156,0.4)'
                    : '',
              }}
              secondaryAction={
                <>
                  <IconButton
                    onClick={() => handleRemoveExam(mo)}
                    edge="end"
                    aria-label="delete"
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              }
            >
              <ListItemText
                style={{ fontSize: '10px' }}
                primary={`(${mo.exam.code}) ${mo.exam.label}`}
              />
            </ListItem>
          )
        })}
      </List>
      {isEditingExam && (
        <AsyncAutoComplete
          label="Ajouter un examen"
          options={examOptions}
          getValue={(el) => el.id}
          onChange={handleAddExam}
          handleSearch={onExamSearch}
          ref={autocompleteRef}
          value={null}
        />
      )}
      <div className="flex flex-row justify-between align-center mt-2">
        <h2 className="text-lg font-bold mt-2">Cotation</h2>
        {validation && validation?.erreurs?.length !== 0 && (
          <div className="text-red-500">
            {validation.erreurs.map((err, index) => (
              <Alert key={index} severity="error">
                {err.messageErreur}
              </Alert>
            ))}
          </div>
        )}
        {!couverture && (
          <Alert severity="warning" className="mb-2">
            Il est nécessaire de mettre à jour la couverture pour ce patient
            pour procéder à la facturation
          </Alert>
        )}
        <div className="flex flex-row space-x-2 pb-2">
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={isValidating}
            style={{
              transition: 'all 0.3s',
            }}
          >
            {isValidating && (
              <CircularProgress
                size={18}
                style={{ color: 'gray', marginRight: '8px' }}
              />
            )}
            Sauvegarder
          </Button>
          <Button
            variant="contained"
            onClick={handleValidate}
            disabled={isValidating}
            style={{
              transition: 'all 0.3s',
            }}
          >
            {isValidating && (
              <CircularProgress
                size={18}
                style={{ color: 'gray', marginRight: '8px' }}
              />
            )}
            Facturer
          </Button>
          <IconButton
            aria-describedby={id}
            onClick={handleClick}
            className="float-right"
          >
            <AddCircle />
          </IconButton>
        </div>
      </div>
      <Popover open={open} anchorEl={cotationAnchor} onClose={handleClose}>
        <List
          sx={{
            bgcolor: 'background.paper',
          }}
          component="nav"
        >
          <ListItem>
            <Button variant="text" onClick={() => handleAddQuotationRow()}>
              <ListItemText
                primary="Ajouter une cotation vide"
                sx={{ textAlign: 'start' }}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{ color: 'text.primary', display: 'inline' }}
                    >
                      Cliquez pour ajouter une cotation
                    </Typography>
                  </React.Fragment>
                }
              />
            </Button>
          </ListItem>
          {cotationType === 'CCAM'
            ? ccams.map((ccam) => (
                <ListItem key={ccam.code}>
                  <Button
                    variant="text"
                    onClick={() => handleAddQuotationRow(ccam)}
                  >
                    <ListItemText
                      sx={{ textAlign: 'start' }}
                      primary={ccam.code}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ color: 'text.primary', display: 'inline' }}
                          >
                            {ccam.libelle}
                          </Typography>
                          {ccam.prixNonOptam} €
                        </React.Fragment>
                      }
                    />
                  </Button>
                </ListItem>
              ))
            : ngaps.map((ngap) => (
                <div key={ngap.code}>
                  <div>{ngap.code}</div>
                  <div>{ngap.libelle}</div>
                  <div>{ngap.prix}</div>
                </div>
              ))}
        </List>
      </Popover>
      <QuotationTable
        isLoading={isFetching}
        rows={precotations}
        onAddRow={() => {
          handleAddQuotationRow()
        }}
        medicalOrderId={selectedMedicalOrder.id}
      />
      <Dialog
        fullScreen
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '80vh',
          },
        }}
        open={!!validation}
        TransitionComponent={UpTransition}
      >
        <DialogContent>
          {validation && validation.visite && (
            <BillSummaryComponent data={validation} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSummary}>Fermer</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateBill}
            disabled={isCreatingBill}
          >
            {isCreatingBill && (
              <CircularProgress
                size={18}
                style={{ color: 'gray', marginRight: '8px' }}
              />
            )}
            Créer la facture
          </Button>
        </DialogActions>
      </Dialog>
      <BillingDialog
        isOpen={!!billUrl}
        onClose={() => setBillUrl('')}
        billUrl={billUrl}
      />
    </div>
  )
}

export default Quotation
