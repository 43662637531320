import { Dialog, DialogContent, IconButton } from '@mui/material'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import { useState, useMemo, useEffect } from 'react'
import { UpTransition } from '@components/animations'
import ConfirmDialog from '@components/dialogs/ConfirmDialog'
import OrganismForm from '../forms/OrganismForm'
import {
  doFindOrganisms,
  setOrganismFilters,
  doRemoveOrganism,
} from '../../state/reducers/organismReducer'
import OrganismTableToolbar from './Toolbars/OrganismTableToolbar'
import {
  getTypeNumber,
  OrganismViewType,
} from '../../services/dtos/OrganismDto'

interface OrganismDTO {
  id: number
  type: number
  code: string
  regime: string
  caisse: string
  centre: string
  libelle: string
  codeDestinataire: string
  centreInfo: string
  teletransmissible: boolean
  active: boolean
  phoneNumber: string
  adress: string
  taux_remboursement: number
  num_organisme: string
}

const OrganismTable = () => {
  const dispatch = useAppDispatch()
  const { filters, organisms } = useAppSelector(({ organisms }) => ({
    organisms: organisms.organisms,
    filters: organisms.filters,
  }))

  const [view, setView] = useState<OrganismViewType>('caisse')
  const [isOrganismFormOpen, setIsOrganismFormOpen] = useState(false)
  const [editingOrganism, setEditingOrganism] = useState<OrganismDTO | null>(
    null,
  )
  const [deletingOrganism, setDeletingOrganism] = useState<OrganismDTO | null>(
    null,
  )

  useEffect(() => {
    dispatch(
      setOrganismFilters({
        ...filters,
        Type: getTypeNumber(view),
      }),
    )
  }, [view])

  useEffect(() => {
    dispatch(doFindOrganisms())
  }, [filters])

  const columns = useMemo(() => {
    const cols: GridColumns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
      },
      {
        field: 'code',
        headerName: 'Code',
        width: 120,
      },
      {
        field: 'regime',
        headerName: 'Régime',
        width: 150,
      },
    ]

    if (view === 'amo') {
      cols.push(
        {
          field: 'caisse',
          headerName: 'Caisse',
          width: 150,
        },
        {
          field: 'centre',
          headerName: 'Centre',
          width: 150,
        },
      )
    }

    cols.push(
      {
        field: 'libelle',
        headerName: 'Libellé',
        width: 200,
      },
      {
        field: 'teletransmissible',
        headerName: 'Télétransmissible',
        width: 130,
        type: 'boolean',
      },
      {
        field: 'active',
        headerName: 'Actif',
        width: 100,
        type: 'boolean',
      },
      {
        field: 'taux_remboursement',
        headerName: 'Taux remboursement',
        width: 150,
        valueFormatter: (params) => `${params.value}%`,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 120,
        pinnable: false,
        renderCell: (params) => (
          <div className="flex items-center justify-center">
            <IconButton
              className="btn btn-primary"
              onClick={() => {
                setEditingOrganism(params.row)
                setIsOrganismFormOpen(true)
              }}
            >
              <EditIcon color="warning" />
            </IconButton>
            <IconButton
              className="btn btn-danger text-black"
              onClick={() => {
                setDeletingOrganism(params.row)
              }}
            >
              <DeleteIcon color="secondary" />
            </IconButton>
          </div>
        ),
      },
    )

    return cols
  }, [view])

  const closeOrganismForm = () => {
    setIsOrganismFormOpen(false)
    setEditingOrganism(null)
  }

  return (
    <div className="flex flex-1 h-[calc(100vh-100px)]">
      <DataGridPro
        sx={{
          display: 'flex',
          flex: 1,
          padding: '16px',
          backgroundColor: 'white',
          borderRadius: '20px',
        }}
        rows={organisms.items}
        columns={columns}
        paginationMode="server"
        pageSize={filters.pageSize}
        rowCount={organisms.totalCount}
        initialState={{
          pinnedColumns: { right: ['actions'] },
        }}
        onPageChange={(page) => {
          dispatch(
            setOrganismFilters({
              page,
            }),
          )
        }}
        components={{
          Toolbar: OrganismTableToolbar,
        }}
        componentsProps={{
          toolbar: {
            onAddOrganism: () => setIsOrganismFormOpen(true),
            view,
            onViewChange: setView,
          },
        }}
        pagination
      />
      <Dialog
        fullScreen
        open={isOrganismFormOpen}
        onClose={closeOrganismForm}
        TransitionComponent={UpTransition}
        slotProps={{
          backdrop: {},
        }}
      >
        <DialogContent>
          {isOrganismFormOpen && (
            <OrganismForm
              formType={view}
              onClose={() => {
                setIsOrganismFormOpen(false)
                setEditingOrganism(null)
              }}
              editingOrganism={editingOrganism}
            />
          )}
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        title="Suppression"
        message="Voulez-vous vraiment supprimer cet organisme ?"
        open={deletingOrganism !== null}
        onConfirm={() => {
          if (deletingOrganism) {
            dispatch(doRemoveOrganism(deletingOrganism.id))
            setDeletingOrganism(null)
          }
        }}
        onCancel={() => {
          setDeletingOrganism(null)
        }}
      />
    </div>
  )
}

export default OrganismTable
