import React, { useState, useEffect, useMemo } from 'react'
import { Autocomplete, TextField, CircularProgress } from '@mui/material'
import { Search } from 'lucide-react'
import { debounce } from 'lodash'

type Option = {
  label: string
  value: string | number
}

type ServerAutocompleteProps<valueT> = {
  value?: valueT | null
  onChange: (value: valueT | null) => void
  defaultValue?: valueT | null
  defaultSearchText?: string
  initialOptions?: valueT[]
  fetchService: (search: string) => Promise<valueT[]>
  placeholder?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
  label?: string
  minSearchLength?: number
  getValue: (value?: valueT | null) => string | null
  debounceMs?: number
  getOptionLabel: (value: string | valueT) => string
}

export const ServerAutocomplete = <valueT,>({
  value,
  onChange,
  defaultValue = null,
  defaultSearchText = '',
  initialOptions = [],
  fetchService,
  getValue,
  placeholder = 'Search...',
  disabled = false,
  error = false,
  helperText = '',
  label = 'Search',
  minSearchLength = 3,
  debounceMs = 300,
  getOptionLabel,
}: ServerAutocompleteProps<valueT>) => {
  const [inputValue, setInputValue] = useState(defaultSearchText)
  const [options, setOptions] = useState<valueT[]>(initialOptions || [])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (defaultValue) {
      const obj = options.find(
        (opt) => getValue(opt) === getValue(defaultValue),
      )
      if (obj) {
        onChange(obj)
      }
    }
    if (defaultSearchText) {
      setInputValue(defaultSearchText)
      handleSearch(defaultSearchText)
    }
  }, [defaultValue, defaultSearchText])

  const handleSearch = useMemo(
    () =>
      debounce(async (searchTerm: string) => {
        if (searchTerm.length < minSearchLength) {
          setOptions(initialOptions || [])
          setLoading(false)
          return
        }

        setLoading(true)
        try {
          const results = await fetchService(searchTerm)
          setOptions([...(initialOptions || []), ...results])
        } catch (error) {
          console.error('Error fetching options:', error)
          setOptions(initialOptions || [])
        } finally {
          setLoading(false)
        }
      }, debounceMs),
    [fetchService],
  )

  return (
    <Autocomplete
      value={getValue(value)}
      onChange={(_, newValue) => {
        if (newValue) {
          onChange(newValue as valueT)
        } else {
          onChange(null)
        }
      }}
      options={options}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
        handleSearch(newInputValue)
      }}
      getOptionLabel={getOptionLabel}
      filterOptions={(x) => x}
      disabled={disabled}
      loading={loading}
      fullWidth
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Search
                    className={error ? 'text-red-500' : 'text-gray-500'}
                    size={20}
                  />
                )}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          className="rounded-xl bg-gray-50 transition-all duration-200 hover:bg-gray-100 focus:bg-white"
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgb(229, 231, 235)',
              },
              '&:hover fieldset': {
                borderColor: error ? 'error.main' : 'primary.main',
              },
              '&.Mui-focused fieldset': {
                borderColor: error ? 'error.main' : 'primary.main',
                boxShadow: '0 0 0 2px rgb(229 231 235)',
              },
            },
          }}
        />
      )}
      ListboxProps={{
        className: 'max-h-60',
        sx: {
          '& li': {
            padding: '10px 16px',
            '&:hover': {
              backgroundColor: 'action.hover',
            },
            '&[aria-selected="true"]': {
              backgroundColor: 'primary.lighter',
            },
          },
        },
      }}
    />
  )
}

export default ServerAutocomplete
