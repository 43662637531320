import { useAppDispatch } from '@hooks/reduxHooks'
import { Input, InputAdornment } from '@mui/material'
import { setDoctorFilters } from '@state/reducers/doctorReducer'
import { useIntl } from 'react-intl'
import { debounce } from 'lodash'
import { useMemo } from 'react'
import PlusIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
type Props = {
  onAddClick: () => void
  hideActions: boolean
}
const DoctorToolbar: React.FC<Props> = ({ onAddClick, hideActions }) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const onSearchChange = useMemo(
    () =>
      debounce((searchTerm: string) => {
        dispatch(setDoctorFilters({ search: searchTerm }))
      }, 300),
    [],
  )

  return (
    <div className="flex flex-row items-center gap-x-3">
      <Input
        onChange={(ev) => {
          onSearchChange(ev.target.value)
        }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        placeholder={intl.formatMessage({
          id: 'labels.searchDoctor',
        })}
        style={{
          height: '40px',
          width: '250px',
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '0 10px',
        }}
        disableUnderline
      />
      {!hideActions && (
        <button
          className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-1 rounded-md shadow-md transition duration-300 ease-in-out transform hover:scale-1"
          onClick={onAddClick}
        >
          <PlusIcon /> Ajouter
        </button>
      )}
    </div>
  )
}

export default DoctorToolbar
