import { axios, getConfigs } from './api'

import { IRequestConfig } from './api'
import { PatientAldDTO } from './dtos'

export class AldService {
  private static readonly endpoint = 'ald'

  static getALD(patientId: number): Promise<PatientAldDTO[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}?patientId=${patientId}`,
        {},
      )

      axios(configs, resolve, reject)
    })
  }

  static createALD(ald: PatientAldDTO): Promise<PatientAldDTO> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        this.endpoint,
        {},
      )
      configs.data = ald

      axios(configs, resolve, reject)
    })
  }

  static updateALD(ald: PatientAldDTO): Promise<PatientAldDTO> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/${ald.id}`,
        {},
      )
      configs.data = ald

      axios(configs, resolve, reject)
    })
  }

  static deleteALD(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        '',
        `${this.endpoint}/${id}`,
        {},
      )

      axios(configs, resolve, reject)
    })
  }
}
