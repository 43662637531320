import React, { useEffect, useState } from 'react'
import { TextField, Autocomplete, CircularProgress } from '@mui/material'
import { Search } from '@mui/icons-material'
import { debounce } from 'lodash'
import { BanService } from '@services/BanServices'
import { makeStyles } from '@mui/styles'

interface CustomAddressAutocompleteProps {
  value: string
  onChange: (value: string) => void
  defaultValue?: string
  defaultSearchText?: string
  placeholder?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
  className?: string
  label?: string
}

const useStyles = makeStyles(() => ({
  formControl: {
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, -9px) scale(0.75)',
      backgroundColor: '#fff',
      padding: '0 8px',
    },
  },
  select: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 12,
      backgroundColor: '#f8f9fa',
      border: 'none',
      transition: 'all 0.2s ease-in-out',
      '& fieldset': {
        border: '1px solid #e9ecef',
      },
      '&:hover': {
        backgroundColor: '#f1f3f5',
      },
      '&.Mui-focused': {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 2px #e9ecef',
      },
    },
    '& .MuiSelect-select': {
      backgroundColor: '#f8f9fa',
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      padding: '12px 16px',
      '&:hover': {
        backgroundColor: '#f8f9fa',
      },
      '&.Mui-selected': {
        backgroundColor: '#e9ecef',
        '&:hover': {
          backgroundColor: '#dee2e6',
        },
      },
    },
  },
}))

export const CustomAddressAutocomplete: React.FC<CustomAddressAutocompleteProps> =
  ({
    onChange,
    defaultValue = '',
    defaultSearchText = '',
    placeholder = 'Search for an address...',
    disabled = false,
    error = false,
    helperText = '',
    label = 'Address',
  }) => {
    const [inputValue, setInputValue] = useState(defaultSearchText)
    const [options, setOptions] = useState<string[]>([])
    const [loading, setLoading] = useState(false)

    const classes = useStyles()

    useEffect(() => {
      if (defaultValue) onChange(defaultValue)
      if (defaultSearchText) {
        setInputValue(defaultSearchText)
        handleSearch(defaultSearchText)
      }
    }, [defaultValue, defaultSearchText])

    const handleSearch = debounce(async (searchTerm: string) => {
      if (searchTerm.length < 3) {
        setOptions([])
        setLoading(false)
        return
      }

      setLoading(true)
      try {
        const banService = new BanService()
        const results = await banService.searchFullAddress(searchTerm)
        setOptions(
          results.map((result) => result.postCode + ', ' + result.place),
        )
      } catch (error) {
        console.error('Error fetching addresses:', error)
        setOptions([])
      } finally {
        setLoading(false)
      }
    }, 300)

    return (
      <Autocomplete
        className={classes.select}
        disabled={disabled}
        freeSolo
        fullWidth
        options={options}
        filterOptions={(x) => x}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
          handleSearch(newInputValue)
        }}
        onChange={(_, newValue) => {
          if (newValue) {
            onChange(newValue)
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.select}
            label={label}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <Search
                      sx={{ color: error ? 'error.main' : 'text.secondary' }}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: error ? 'error.main' : 'primary.main',
                },
                '&.Mui-focused fieldset': {
                  borderColor: error ? 'error.main' : 'primary.main',
                },
              },
            }}
          />
        )}
        ListboxProps={{
          sx: {
            maxHeight: '240px',
            '& li': {
              padding: '10px 16px',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              '&[aria-selected="true"]': {
                backgroundColor: 'primary.lighter',
              },
            },
          },
        }}
      />
    )
  }

export default CustomAddressAutocomplete
