/* eslint-disable indent */
import { useForm } from 'react-hook-form'
import { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import {
  PatientTransportDto,
  CreatePatientTransportDto,
  UpdateAmbulanceDriverDto,
  MedicalOrder,
  PatientTransportStatus,
} from '../../../services/api'
import { useSelector } from '../../../state/store'
import { useAppDispatch } from '../../../hooks/reduxHooks'
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'

import {
  createPatientTransport,
  findAmbulanceCompanies,
  updatePatientTransport,
} from '../../../state/thunks/ambulanceThunk'
import { AmbulanceForm } from './AmbulanceForm'
import theme from '@utils/theme'
import { DialogTitle } from '../../../components/titles'
import moment from 'moment'
import { capitalize } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { TextInput } from '../../../components/inputs'

type AddDriverCompanyProps = {
  row: MedicalOrder
  onClose?: () => void
  onCancelButtonPress?: (
    ev: MouseEventHandler<HTMLButtonElement> | undefined,
  ) => void
}

export function TransportForm({ row, onClose }: AddDriverCompanyProps) {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreatePatientTransportDto>({
    // resolver: yupResolver(patientTransportSchema),
    defaultValues: {
      ...row?.patientTransport,
      companyId: row?.patientTransport?.ambulanceCompanyId,
    },
  })
  console.log({ row })
  const [transportLinkType, setTransportLinkType] = useState('ambulance/taxi')
  const [isEditing, setIsEditing] = useState(!row?.patientTransport?.id)

  const dispatch = useAppDispatch()

  const companies = useSelector((state) => state.ambulance.data)
  const [isAmbulanceFormOpen, setIsAmbulanceFormOpen] = useState(false)

  const handleOpenAmbulanceForm = () => {
    setIsAmbulanceFormOpen(true)
  }
  const handleCloseAmbulanceForm = () => {
    setIsAmbulanceFormOpen(false)
    // Optionally refresh company list here if needed
  }
  const companyOptions: {
    value: string | number
    label: string
  }[] = useMemo(() => {
    return companies.map((company) => ({
      value: company.id,
      label: company.name,
    }))
  }, [companies])

  const onSubmit = (values: PatientTransportDto) => {
    if (row.patientTransport?.id) {
      const updateAmbulanceCompanyDto: UpdateAmbulanceDriverDto = {
        ...values,
      }
      dispatch(
        updatePatientTransport({
          id: row.patientTransport.id,
          dto: updateAmbulanceCompanyDto,
        }),
      ).then(() => {
        setIsEditing(false)
      })
    } else {
      const createAmbulanceCompanyDto: CreatePatientTransportDto = {
        ...values,
        medicalOrderId: row.id,
      }
      dispatch(createPatientTransport(createAmbulanceCompanyDto))
        .unwrap()
        .then(() => {
          setIsEditing(false)
        })
    }
    if (onClose) {
      onClose()
    }
  }

  const updateTransportStatus = (status: PatientTransportStatus) => {
    if (!row.patientTransportId) {
      return
    }

    dispatch(
      updatePatientTransport({ id: row.patientTransportId, dto: { status } }),
    )
  }

  const ambulanceTaxiOptions = [
    { value: 'ambulance', label: 'Ambulance' },
    { value: 'vsl', label: 'VSL' },
  ]

  const privateOptions = [
    { value: 'friend', label: 'Proche' },
    { value: 'relative', label: 'Famille' },
    { value: 'other', label: 'Autre' },
  ]
  useEffect(() => {
    if (!row) {
      return
    }

    const r = privateOptions.find(
      ({ value }) => value === row.patientTransport?.transportType,
    )
      ? 'private'
      : 'ambulance/taxi'

    setTransportLinkType(r)
  }, [row])
  useEffect(() => {
    dispatch(findAmbulanceCompanies())
  }, [])

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: 5,
      }}
    >
      {isEditing ? (
        <Paper sx={{ padding: 4 }}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
              <RadioGroup
                row
                value={transportLinkType}
                onChange={(e) => setTransportLinkType(e.target.value)}
              >
                <FormControlLabel
                  value="ambulance/taxi"
                  control={<Radio />}
                  label="Ambulance/Taxi"
                />
                <FormControlLabel
                  value="private"
                  control={<Radio />}
                  label="Personnel"
                />
              </RadioGroup>
            </Grid>

            {transportLinkType === 'ambulance/taxi' && (
              <>
                <Grid item xs={12}>
                  <InputLabel id="company-label">
                    Choisir une société
                  </InputLabel>
                  <Select
                    variant="outlined"
                    {...register('companyId')}
                    fullWidth
                  >
                    {companyOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={handleOpenAmbulanceForm}
                    color="primary"
                    variant="outlined"
                  >
                    + Ajouter une société
                  </Button>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <InputLabel id="transportType">Type de transport</InputLabel>
              <Select
                {...register('transportType')}
                variant="outlined"
                fullWidth
              >
                {transportLinkType === 'ambulance/taxi' &&
                  ambulanceTaxiOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                {transportLinkType === 'private' &&
                  privateOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextInput
                variant="outlined"
                name="name"
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                variant="outlined"
                name="phoneNumber"
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                variant="outlined"
                name="plateNumber"
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                variant="outlined"
                name="comments"
                label="Comments"
                control={control}
                errors={errors}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  onClick={() => {
                    setIsEditing(false)
                  }}
                  sx={{
                    width: '300px',
                    marginRight: 4,
                    color: 'white',
                    backgroundColor: theme.palette.secondary.main,
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.main,
                    },
                  }}
                >
                  Fermer
                </Button>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  sx={{
                    width: '300px',
                    marginRight: 4,
                    backgroundColor: '#3f3f3f',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#3f3f3f',
                    },
                  }}
                >
                  Sauvegarder
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Patient
                  </TableCell>
                  <TableCell>
                    {capitalize(row.patient.firstName)}{' '}
                    {row.patient.lastName.toUpperCase()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Date de naissance
                  </TableCell>
                  <TableCell>
                    {moment(row.patient.birthDate).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Examen
                  </TableCell>
                  <TableCell>
                    {row.exam.label} ({row.exam.code})
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h1 style={{ marginTop: 10, marginRight: 4 }}>Transport</h1>
            {row.patientTransport?.status === PatientTransportStatus.Called ? (
              <Chip
                color="success"
                label="Appelé"
                sx={{
                  color: 'white',
                  fontWeight: 'bold',
                }}
              />
            ) : (
              <Chip
                color="secondary"
                label="Non appelé"
                sx={{
                  color: 'white',
                  fontWeight: 'bold',
                }}
              />
            )}
          </Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Nom:
                  </TableCell>
                  <TableCell>{row.patientTransport?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Téléphone:
                  </TableCell>
                  <TableCell>{row.patientTransport?.phoneNumber}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Numéro de plaque:
                  </TableCell>
                  <TableCell>{row.patientTransport?.plateNumber}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Commentaires:
                  </TableCell>
                  <TableCell>{row.patientTransport?.comments}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: 'bold' }}
                  >
                    Type de contact:
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id={row.patientTransport?.transportType || 'Autre'}
                    />
                  </TableCell>
                </TableRow>

                {row.patientTransport?.ambulanceCompanyId && (
                  <>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ fontWeight: 'bold' }}
                      >
                        Société:
                      </TableCell>
                      <TableCell>
                        {row.patientTransport.ambulanceCompany.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ fontWeight: 'bold' }}
                      >
                        Adresse:
                      </TableCell>
                      <TableCell>
                        {row.patientTransport.ambulanceCompany.address}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ fontWeight: 'bold' }}
                      >
                        Téléphone:
                      </TableCell>
                      <TableCell>
                        {row.patientTransport.ambulanceCompany.contact}
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 10,
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                setIsEditing(true)
              }}
              style={{
                marginRight: 4,
                width: '200px',
                backgroundColor: theme.palette.primary.main,
                color: 'white',
              }}
            >
              Modifier
            </Button>
            {row.patientTransport?.status === PatientTransportStatus.Called ? (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
                fullWidth
                onClick={() => {
                  updateTransportStatus(PatientTransportStatus.NotCalled)
                }}
              >
                Marquer comme &ldquo;Non appelé&rdquo;
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: 'aquamarine',
                  '&:hover': {
                    backgroundColor: 'aquamarine',
                  },
                }}
                onClick={() => {
                  updateTransportStatus(PatientTransportStatus.Called)
                }}
              >
                Marquer comme &ldquo;Appelé&rdquo;
              </Button>
            )}
          </div>
        </>
      )}

      <Dialog
        open={isAmbulanceFormOpen}
        onClose={handleCloseAmbulanceForm}
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="addAmbulance"
          format
          onClose={handleCloseAmbulanceForm}
        />
        <DialogContent sx={{ background: '#fff', padding: '20px' }}>
          <AmbulanceForm onClose={handleCloseAmbulanceForm} />
        </DialogContent>
      </Dialog>
    </Grid>
  )
}
