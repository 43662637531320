import { useForm } from 'react-hook-form'
import {
  forwardRef,
  useImperativeHandle,
  Fragment,
  useState,
  useEffect,
} from 'react'
import PersonIcon from '@mui/icons-material/Person'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import BusinessIcon from '@mui/icons-material/Business'
import { CouvertureDto } from '../../../../common/interfaces'
import moment from 'moment'
import InputField from '@components/inputs/InputField'
import VitalCardReaderComponent from '@components/patient/VitalCardReaderComponent'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { yupResolver } from '@hookform/resolvers/yup'
import { couvertureFormSchema } from '../../../../utils/schemas'
import OrganismSelectorComponent from '@components/OrganismSelectorComponent'
import { Button, FormControl, FormLabel, Switch } from '@mui/material'
import { FileEdit, Pen, Search, Waypoints } from 'lucide-react'
import { OrganismService } from '../../../../services/organismService'
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks'
import {
  doGetExonerations,
  doGetNaturesAssurance,
} from '../../../../state/reducers/billingReducer'
import SelectField from '@components/inputs/SelectField'
import { DateInputField } from '@components/inputs/DateInputField'
import { BillingService } from '@services/BillingService'
import { DoctorV2Service } from '../../../../services/DoctorService'
import { DoctorDto, RppsDto } from '@services/api'
import { RppsAutocompleteByNameInput } from '../../../../components/RppsAutocompleteByNameInput'

export interface CouvertureFormRef {
  setValues: (values: CouvertureDto) => void
  getValues: () => Promise<CouvertureDto | null>
  openVitalCardReader: () => void
}

type Props = {
  onVitalCardReadFinished: (dto: CouvertureDto) => void
}

const CouvertureForm = forwardRef<CouvertureFormRef, Props>(
  ({ onVitalCardReadFinished }, ref) => {
    const {
      control,
      setValue,
      watch,
      trigger,
      getValues,
      setError,
      formState: { errors },
    } = useForm<CouvertureDto>({
      resolver: yupResolver(couvertureFormSchema),
    })

    const dispatch = useAppDispatch()

    const [isVitalCardReadOpen, setIsVitalCardReadOpen] = useState(false)
    const [isOrganismSelectorOpen, setIsOrganismSelectorOpen] = useState<{
      isOpen: boolean
      type: string
    }>({
      isOpen: false,
      type: '',
    })

    const [indicateursParcours, setIndicateursParcours] = useState<
      {
        item1: string
        item2: string
      }[]
    >([])

    const [medecinTraitant, setMedecinTraitant] = useState<DoctorDto | null>(
      null,
    )

    const beneficaireDateNaissance = watch('beneficaireDateNaissance')
    const dateDebutAmo = watch('dateDebutAmo')
    const dateFinAmo = watch('dateFinAmo')
    const dateDebutAmc = watch('dateDebutAmc')
    const dateFinAmc = watch('dateFinAmc')
    const idExoneration = watch('idExoneration') || 0
    const idNature = watch('idNature') || 0
    const dateAccidentTravail = watch('date_accident_travail')
    const dateGrossesse = watch('date_grossesse')
    const indicateur_parcours_soins = watch('indicateur_parcours_soins') || ''
    const idMedecinTraitant = watch('medecin_traitant_id')
    const medecinDeclare = watch('medecin_declare')

    const { exonerations, naturesAssurance } = useAppSelector(
      (state) => state.billing,
    )

    useImperativeHandle(ref, () => ({
      getValues: async () => {
        const isValid = await trigger()
        if (!isValid) {
          return null
        }
        const values = await getValues()
        if (values.idNature === 3) {
          if (!values.date_accident_travail) {
            setError('date_accident_travail', {
              message: 'La date de l\'accident est obligatoire',
            })
            return null
          }
        } else if (values.idNature === 2) {
          if (!values.date_grossesse) {
            setError('date_grossesse', {
              message: 'La date de grossesse est obligatoire',
            })
            return null
          }
        }
        return values
      },
      openVitalCardReader: () => {
        setIsVitalCardReadOpen(true)
      },
      setValues: async (values: CouvertureDto) => {
        const {
          idOrganismeAmo,
          idOrganismeAmc,
          idExoneration,
          idNature,
          id,
          ...otherValues
        } = values
        if (id) {
          setValue('id', id)
        }
        Object.keys(otherValues).forEach((key) => {
          setValue(
            key as keyof CouvertureDto,
            values[key as keyof CouvertureDto],
          )
        })
        if (idOrganismeAmo) {
          const organisme = await OrganismService.getById(idOrganismeAmo)
          setValue('organismeAmo', organisme)
          setValue('idOrganismeAmo', idOrganismeAmo)
        }
        if (idOrganismeAmc) {
          const organisme = await OrganismService.getById(idOrganismeAmc)
          setValue('organismeAmc', organisme)
          setValue('idOrganismeAmc', idOrganismeAmc)
        }
        if (idExoneration) {
          setValue('idExoneration', idExoneration)
        }
        setValue('idNature', idNature)
      },
    }))

    useEffect(() => {
      if (exonerations.length === 0) {
        dispatch(doGetExonerations())
      }
      if (naturesAssurance.length === 0) {
        dispatch(doGetNaturesAssurance())
      }
      if (indicateursParcours.length === 0) {
        BillingService.getIndicateursParcours().then((res) => {
          setIndicateursParcours(res)
        })
      }
      if (idMedecinTraitant) {
        DoctorV2Service.getById(idMedecinTraitant).then((res) => {
          setValue('medecin_declare', true)
          setMedecinTraitant(res)
        })
      }
    }, [
      dispatch,
      exonerations.length,
      naturesAssurance.length,
      idMedecinTraitant,
    ])

    const onVitalCardReadFinish = (dto: CouvertureDto) => {
      setIsVitalCardReadOpen(false)
      const {
        id,
        idVisit,
        beneficaireNomUsuel,
        beneficiaireNom,
        ...otherValues
      } = dto
      if (id) {
        setValue('id', id)
      }
      if (idVisit) {
        setValue('idVisit', idVisit)
      }
      if (beneficaireNomUsuel) {
        if (!beneficiaireNom) {
          setValue('beneficiaireNom', beneficaireNomUsuel)
          setValue('beneficaireNomUsuel', beneficaireNomUsuel)
        } else {
          setValue('beneficaireNomUsuel', beneficaireNomUsuel)
          setValue('beneficiaireNom', beneficiaireNom)
        }
      }
      Object.keys(otherValues).forEach((key) => {
        setValue(key as keyof CouvertureDto, dto[key as keyof CouvertureDto])
      })
      onVitalCardReadFinished(dto)
    }
    const convertDate = (date: string) => {
      if (!date) {
        return null
      }
      const m = moment(date, 'YYYYMMDD')
      return m.isValid() ? m : null
    }

    const handleMedecinTraitantChange = (rppsDto: RppsDto | null) => {
      if (rppsDto) {
        DoctorV2Service.getByIdOrCreate(rppsDto.rppsNumber).then((res) => {
          setMedecinTraitant(res)
          setValue('medecin_traitant_id', res.id)
        })
      }
    }

    console.log(indicateur_parcours_soins)

    return (
      <Fragment>
        <form className="space-y-1">
          <div className="grid grid-cols-2 gap-4">
            <section className="p-2 rounded-md">
              <div className="flex items-center mb-4">
                <PersonIcon className="text-gray-700 mr-2" />
                <h2 className="text-lg font-semibold">
                  Informations Générales
                </h2>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <InputField
                  control={control}
                  name="beneficaireNomUsuel"
                  label="Nom du bénéficiaire"
                />
                <InputField
                  control={control}
                  name="beneficiairePrenom"
                  label="Prénom du bénéficiaire"
                />
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <InputField
                  control={control}
                  name="beneficiaireNir"
                  label="NIR"
                />
                <DatePicker
                  label="Date de naissance"
                  value={convertDate(beneficaireDateNaissance)}
                  onChange={(date) => {
                    setValue(
                      'beneficaireDateNaissance',
                      date ? date.format('YYYYMMDD') : '',
                    )
                  }}
                />
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <InputField
                  control={control}
                  name="beneficaireNomUsuel"
                  label="Nom Usuel"
                />
                <InputField
                  control={control}
                  name="rangNaissance"
                  label="Rang de Naissance"
                  type="number"
                />
              </div>
            </section>

            <section className="p-2 bg-white rounded-md">
              <div className="flex items-center mb-4">
                <AssignmentIndIcon className="text-gray-700 mr-2" />
                <h2 className="text-lg font-semibold">
                  Détails de l&apos;Assuré
                </h2>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <InputField
                  control={control}
                  name="assureNom"
                  label="Nom de l'assuré"
                />
                <InputField
                  control={control}
                  name="assurePrenom"
                  label="Prénom de l'assuré"
                />
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <InputField
                  control={control}
                  name="assureNir"
                  label="NIR de l'assuré"
                />
                <InputField
                  control={control}
                  name="assureNomUsuel"
                  label="Nom Usuel de l'assuré"
                />
              </div>
            </section>

            <section className="p-2 bg-white rounded-md">
              <div className="flex items-center mb-4">
                <Waypoints className="text-gray-700 mr-2" />
                <h2 className="text-lg font-semibold">Parcours de soins</h2>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <FormControl fullWidth>
                  <SelectField
                    control={control}
                    name="idExoneration"
                    options={exonerations.map((e) => ({
                      label: e.libelle,
                      value: e.id,
                    }))}
                    value={idExoneration} // unfortunately, the value is not updated when the select is changed
                    label="Exonération"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <SelectField
                    control={control}
                    name="idNature"
                    options={naturesAssurance.map((e) => ({
                      label: e.libelle,
                      value: e.id,
                    }))}
                    value={idNature}
                    label="Nature"
                  />
                </FormControl>
                {idNature === 3 && (
                  <>
                    <FormControl
                      fullWidth
                      error={!!errors.date_accident_travail}
                    >
                      <DateInputField
                        label="Date de l'accident"
                        name="date_accident_travail"
                        value={
                          dateAccidentTravail
                            ? convertDate(dateAccidentTravail)
                            : null
                        }
                        onChange={(date) => {
                          setValue(
                            'date_accident_travail',
                            date ? date.format('YYYY-MM-DD') : '',
                          )
                        }}
                      />
                    </FormControl>
                    <InputField
                      control={control}
                      name="numero_at"
                      label="Numéro AT"
                    />
                  </>
                )}
                {idNature === 2 && (
                  <FormControl fullWidth error={!!errors.date_grossesse}>
                    <DateInputField
                      label="Date de grossesse"
                      name="date_grossesse"
                      value={dateGrossesse ? convertDate(dateGrossesse) : null}
                      onChange={(date) => {
                        setValue(
                          'date_grossesse',
                          date ? date.format('YYYY-MM-DD') : '',
                        )
                      }}
                    />
                  </FormControl>
                )}
                <FormControl fullWidth>
                  <SelectField
                    control={control}
                    name="indicateur_parcours_soins"
                    options={indicateursParcours.map((e) => ({
                      value: e.item1,
                      label: e.item2,
                    }))}
                    label="Indicateur de parcours"
                    value={indicateur_parcours_soins}
                    defaultValue={indicateur_parcours_soins}
                  />
                </FormControl>
                <div className="flex flex-row flex-wrap gap-4">
                  <FormControl>
                    <FormLabel>Médecin traitant déclaré</FormLabel>
                    <Switch
                      checked={medecinDeclare}
                      onChange={(e) => {
                        setValue('medecin_declare', e.target.checked)
                        if (!e.target.checked) {
                          setMedecinTraitant(null)
                          setValue('medecin_traitant_id', null)
                        }
                      }}
                    />
                  </FormControl>
                  {medecinDeclare && (
                    <div className="min-w-[150px]">
                      <RppsAutocompleteByNameInput
                        size="small"
                        label="Médecin traitant"
                        value={
                          medecinTraitant
                            ? ({
                                rppsNumber: medecinTraitant?.rppsNumber || '',
                                lastName: medecinTraitant?.lastName || '',
                                firstName: medecinTraitant?.firstName || '',
                              } as RppsDto)
                            : null
                        }
                        onChange={(rppsDto) => {
                          if (rppsDto === null) {
                            setMedecinTraitant(null)
                          } else {
                            handleMedecinTraitantChange(rppsDto)
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </section>

            <div>
              <section className="p-2 rounded-md">
                <div className="flex items-center mb-4">
                  <BusinessIcon className="text-gray-700 mr-2" />
                  <h2 className="text-lg font-semibold">Organisme AMO</h2>
                  <Button
                    variant="outlined"
                    sx={{ ml: 2 }}
                    startIcon={<Search className="text-gray-700" />}
                    size="small"
                    onClick={() =>
                      setIsOrganismSelectorOpen({ isOpen: true, type: 'amo' })
                    }
                  >
                    Sélectionner
                  </Button>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <InputField
                    control={control}
                    name="organismeAmo.libelle"
                    label="Organisme AMO"
                    disabled
                  />
                  <DateInputField
                    label="Date de début AMO"
                    name="dateDebutAmo"
                    value={convertDate(dateDebutAmo)}
                    onChange={(date) => {
                      setValue(
                        'dateDebutAmo',
                        date ? date.format('YYYY-MM-DD') : '',
                      )
                    }}
                  />
                  <DateInputField
                    label="Date de fin AMO"
                    name="dateFinAmo"
                    value={convertDate(dateFinAmo)}
                    onChange={(date) => {
                      setValue(
                        'dateFinAmo',
                        date ? date.format('YYYY-MM-DD') : '',
                      )
                    }}
                  />
                </div>
              </section>
              <section className="p-2 bg-white rounded-md">
                <div className="flex items-center mb-4">
                  <BusinessIcon className="text-gray-700 mr-2" />
                  <h2 className="text-lg font-semibold">Organisme AMC</h2>
                  <Button
                    variant="outlined"
                    startIcon={<Search className="text-gray-700" />}
                    size="small"
                    onClick={() =>
                      setIsOrganismSelectorOpen({ isOpen: true, type: 'amc' })
                    }
                    sx={{ ml: 2 }}
                  >
                    Sélectionner
                  </Button>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <InputField
                    control={control}
                    name="organismeAmc.libelle"
                    label="Organisme AMC"
                    disabled
                  />
                  <DatePicker
                    label="Date de début AMC"
                    value={convertDate(dateDebutAmc)}
                    onChange={(date) => {
                      setValue(
                        'dateDebutAmc',
                        date ? date.format('YYYYMMDD') : '',
                      )
                    }}
                  />
                  <DatePicker
                    label="Date de fin AMC"
                    value={convertDate(dateFinAmc)}
                    onChange={(date) => {
                      setValue(
                        'dateFinAmc',
                        date ? date.format('YYYYMMDD') : '',
                      )
                    }}
                  />
                </div>
              </section>
            </div>
          </div>
        </form>
        <VitalCardReaderComponent
          visitId={0}
          readDate={moment().format('YYYY-MM-DD')}
          isOpen={isVitalCardReadOpen}
          onFinish={onVitalCardReadFinish}
          onClose={() => setIsVitalCardReadOpen(false)}
        />
        <OrganismSelectorComponent
          onSelect={(org) => {
            if (isOrganismSelectorOpen.type === 'amo') {
              setValue('organismeAmo', org)
              setValue('idOrganismeAmo', org?.id || 0)
            } else {
              setValue('idOrganismeAmc', org?.id || 0)
              setValue('organismeAmc', org)
            }
            setIsOrganismSelectorOpen({ isOpen: false, type: '' })
          }}
          type={isOrganismSelectorOpen.type}
          isOpen={isOrganismSelectorOpen.isOpen}
          onClose={() => setIsOrganismSelectorOpen({ isOpen: false, type: '' })}
        />
      </Fragment>
    )
  },
)

export default CouvertureForm
