import React, { FC, useEffect, useState } from 'react'
import { PatientAldDTO } from '@services/dtos'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { patientAldSchema } from '@utils/schemas'
import {
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Autocomplete,
  TextField,
  DialogTitle,
} from '@mui/material'
import InputField from '@components/inputs/InputField'
import { DateInputField } from '@components/inputs/DateInputField'
import SelectField from '@components/inputs/SelectField'
import { useAppDispatch } from '@hooks/reduxHooks'
import { doCreateALD, doUpdateALD } from '@state/reducers/aldReducer'
import moment from 'moment'
import { enqueueSnackbar } from 'notistack'
import { doFindOrders } from '@state/reducers/orderReducer'
import { BillingService } from '@services/BillingService'
import { UpTransition } from '@components/animations'
import { Search } from 'lucide-react'

type Props = {
  patientId: number
  editingAld?: PatientAldDTO | null
  onClose?: (dto: PatientAldDTO | null) => void
}

const ALDForm: FC<Props> = ({ patientId, editingAld, onClose }) => {
  const dispatch = useAppDispatch()

  const { control, handleSubmit, setValue } = useForm<PatientAldDTO>({
    defaultValues: editingAld
      ? {
          ...editingAld,
          dateFin: editingAld.dateFin
            ? (moment(editingAld.dateFin) as any)
            : null,
        }
      : { patientId },
    resolver: yupResolver(patientAldSchema),
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [alds, setAlds] = useState<{ label: string; code: string }[]>([])
  const [isSelectingAld, setIsSelectingAld] = useState(false)

  useEffect(() => {
    BillingService.getAlds().then((alds) => setAlds(alds))
  }, [])

  const onSubmit = (data: PatientAldDTO) => {
    setIsSubmitting(true)
    const dto = { ...data, source: 'User' } as PatientAldDTO
    const cb = (msg: string, dto: PatientAldDTO) => {
      enqueueSnackbar(msg, { variant: 'success' })
      onClose?.(dto)
      dispatch(doFindOrders())
    }
    if (editingAld) {
      dispatch(doUpdateALD(dto))
        .unwrap()
        .then((dto) => cb('ALD modifié avec succès', dto))
        .finally(() => setIsSubmitting(false))
    } else {
      dispatch(doCreateALD(dto))
        .unwrap()
        .then((dto) => cb('ALD créé avec succès', dto))
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button
          variant="outlined"
          onClick={() => setIsSelectingAld(true)}
          sx={{ mb: 2 }}
        >
          <Search />
          Sélectionner un ALD
        </Button>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputField control={control} name="libelle" label="Libellé" />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField control={control} name="code" label="Code" />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInputField
              control={control}
              name="dateFin"
              label="Date de fin"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectField
              control={control}
              name="etat"
              label="État"
              options={[
                { label: 'Active', value: 'actif' },
                { label: 'Terminée', value: 'inactif' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField control={control} name="cimCode" label="Cim Code" />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              multiline
              rows={2}
              control={control}
              name="comment"
              placeholder="Commentaire"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <CircularProgress style={{ color: 'gray' }} size={20} />
              )}
              Enregistrer
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => onClose?.(null)}
            >
              Annuler
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={isSelectingAld}
        onClose={() => setIsSelectingAld(false)}
        TransitionComponent={UpTransition}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Autocomplete
            options={alds}
            getOptionLabel={(option) => option.label}
            onChange={(_, value) => {
              setValue('code', value?.code || '')
              setValue('libelle', value?.label || '')
              setIsSelectingAld(false)
            }}
            renderInput={(params) => <TextField {...params} label="ALD" />}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default ALDForm
