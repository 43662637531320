import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
} from '@mui/material'
import { DoctorDto } from '@services/api'
import { AddressDto, RecipientDTO } from '@services/dtos'
import {} from '@state/reducers/orderReducer'
import { FC, useEffect, useState } from 'react'
import DoctorList from '../../containers/app/parameters/doctors/DoctorList'
import EditIcon from '@mui/icons-material/Edit'
import ConfirmDialog from '../dialogs/ConfirmDialog'
import {
  doAddRecipient,
  doGetRecipients,
  doRemoveRecipient,
  doUpdateRecipient,
} from '../../state/reducers/recipientReducer'
import DialogTitle from '../dialogs/DialogTitle'
import DoctorForm from '../forms/DoctorForm'

const DoctorRecipient: FC<{ doctor: DoctorDto }> = ({ doctor }) => {
  return (
    <div className="p-3">
      <div className="font-medium text-sm">
        {doctor.firstName} {doctor.lastName}{' '}
        {!!doctor.skill && <span> - {doctor.skill}</span>}
      </div>
      <div className="text-gray-600 text-sm">{doctor.email}</div>
    </div>
  )
}

const RenderList: FC<{
  recipients: RecipientDTO[]
  onDelete: (id: number) => void
  onSelectAddress: (recipient: RecipientDTO) => void
}> = ({ recipients, onDelete, onSelectAddress }) => {
  return (
    <div className="space-y-2">
      {recipients.map((rc) => (
        <div key={rc.id} className="border rounded-lg bg-gray-50">
          <div className="flex flex-row items-center relative ">
            {rc.doctor && <DoctorRecipient key={rc.id} doctor={rc.doctor} />}
            <button
              type="button"
              className="absolute right-0 top-0 p-2 text-gray-500 hover:text-red-500"
              onClick={() => onDelete(rc.id)}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
          {rc.doctor && !rc.selectedAddressId && (
            <div>
              <Alert
                severity="warning"
                action={
                  <Button
                    type="button"
                    onClick={() => onSelectAddress(rc)}
                    variant="outlined"
                  >
                    Sélectionner
                  </Button>
                }
              >
                Aucune adresse sélectionnée pour le destinataire
              </Alert>
            </div>
          )}
          {rc.selectedAddress && (
            <div className="flex items-center justify-between gap-2 px-3 py-2 mt-1 text-sm border-t">
              <div className="flex items-center gap-2">
                <i className="fas fa-map-marker-alt text-gray-500"></i>
                <div>
                  <div className="font-medium text-gray-900">
                    {rc.selectedAddress.locationName}
                  </div>
                  <div className="text-xs text-gray-600">
                    {rc.selectedAddress.line1}, {rc.selectedAddress.postalCode}{' '}
                    {rc.selectedAddress.city}
                  </div>
                </div>
              </div>
              <Tooltip title="Modifier l'adresse">
                <IconButton size="small" onClick={() => onSelectAddress(rc)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

type Props = { orderId?: number; visitId?: number }

const OrderRecipientList: FC<Props> = ({ orderId, visitId }) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [selectingRecipientAddress, setSelectingRecipientAddress] =
    useState<RecipientDTO | null>(null)
  const [deletingRecipient, setDeletingRecipient] = useState<number | null>(
    null,
  )
  const [editingDoctor, setEditingDoctor] = useState<DoctorDto | null>(null)

  const { recipients } = useAppSelector(({ recipient }) => ({
    recipients: recipient.recipients,
  }))

  useEffect(() => {
    setIsLoading(true)
    if (!orderId && !visitId) {
      return
    }
    dispatch(doGetRecipients({ orderId, visitId })).finally(() =>
      setIsLoading(false),
    )
  }, [])

  useEffect(() => {
    if (selectingRecipientAddress) {
      const selectedRecipient = recipients.find(
        (r) => r.id === selectingRecipientAddress?.id,
      )
      if (selectedRecipient) {
        setSelectingRecipientAddress(selectedRecipient)
      }
    }
  }, [recipients])

  const handleDoctorSelected = (doctor: DoctorDto[]) => {
    const newRecipients: DoctorDto[] = []
    for (const d of doctor) {
      if (!recipients.find((rp) => rp.doctor?.id === d.id)) {
        newRecipients.push(d)
      }
    }
    for (const d of newRecipients) {
      dispatch(
        doAddRecipient({
          doctorId: d.id,
          orderId,
          visitId,
          ...(d.addresses?.length === 1 && {
            selectedAddressId: d.addresses[0].id,
          }),
        }),
      )
    }
  }

  const onDeleteRecipient = async () => {
    if (deletingRecipient) {
      await dispatch(doRemoveRecipient(deletingRecipient)).unwrap()

      setDeletingRecipient(null)
    }
  }

  const handleSelectAddress = async (address: AddressDto) => {
    if (selectingRecipientAddress) {
      await dispatch(
        doUpdateRecipient({
          id: selectingRecipientAddress.id,
          dto: { selectedAddressId: address.id },
        }),
      ).unwrap()

      setSelectingRecipientAddress(null)
    }
  }

  const onEditDoctor = (doctor: DoctorDto) => {
    setEditingDoctor(doctor)
  }

  return (
    <>
      <div className="p-4">
        <button
          className="w-full mb-3 bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded-md text-sm transition-colors duration-200 flex items-center justify-center gap-2"
          onClick={() => setIsAddOpen(true)}
          type="button"
        >
          <i className="fas fa-plus"></i>
          Ajouter un destinataire
        </button>
        <div className="space-y-2">
          <RenderList
            recipients={recipients}
            onDelete={setDeletingRecipient}
            onSelectAddress={setSelectingRecipientAddress}
          />
        </div>
      </div>

      <Dialog
        fullWidth
        maxWidth="xl"
        open={isAddOpen}
        onClose={() => setIsAddOpen(false)}
      >
        <DialogTitle
          title="Sélectionner un ou plusieurs médecins"
          onClose={() => setIsAddOpen(false)}
        />
        <DialogContent className="p-0 overflow-hidden h-[calc(100vh-200px)]">
          <div className="flex-1 flex flex-row overflow-auto h-full">
            <div className="w-3/4 flex-1">
              <DoctorList
                onSelectionChange={handleDoctorSelected}
                selectedRows={recipients.reduce((acc, r) => {
                  if (r.doctor) {
                    acc.push(r.doctor)
                  }
                  return acc
                }, new Array<DoctorDto>())}
              />
            </div>
            <div className="w-1/4 bg-gray-50 p-4">
              <h3 className="text-lg font-semibold mb-4">
                Destinataires selectionnés
              </h3>
              <div className="space-y-2">
                <RenderList
                  recipients={recipients}
                  onDelete={setDeletingRecipient}
                  onSelectAddress={setSelectingRecipientAddress}
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!selectingRecipientAddress}
        onClose={() => setSelectingRecipientAddress(null)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          title="Sélectionner une adresse"
          onClose={() => setSelectingRecipientAddress(null)}
        />
        <DialogContent>
          <div>
            <div className="space-y-4">
              {selectingRecipientAddress?.doctor?.addresses?.map((a) => (
                <div
                  key={a.id}
                  className={`p-4 bg-white rounded-lg shadow-sm border ${
                    selectingRecipientAddress.selectedAddressId === a.id
                      ? 'border-gray-500 ring-2 ring-gray-200'
                      : 'border-gray-200 hover:border-gray-500'
                  } cursor-pointer transition-all`}
                  onClick={() => handleSelectAddress(a)}
                >
                  <div className="font-medium text-gray-900">
                    {a.locationName}
                  </div>
                  <div className="text-sm text-gray-600 mt-1">
                    <div>{a.line1}</div>
                    {a.line2 && <div>{a.line2}</div>}
                    <div>
                      {a.postalCode} {a.city}
                    </div>
                    <div>{a.country}</div>
                  </div>
                  {a.finessNumber && (
                    <div className="text-xs text-gray-500 mt-2">
                      N° FINESS: {a.finessNumber}
                    </div>
                  )}
                </div>
              ))}
              {selectingRecipientAddress?.doctor?.addresses?.length === 0 && (
                <div className="p-4 bg-white rounded-lg shadow-sm border border-gray-200">
                  <div className="text-sm text-gray-600">
                    Aucune adresse disponible pour ce médecin, veuillez en
                    ajouter une.
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        if (selectingRecipientAddress.doctor) {
                          onEditDoctor(selectingRecipientAddress.doctor)
                        }
                      }}
                    >
                      Ajouter une adresse
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!editingDoctor}
        onClose={() => setEditingDoctor(null)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          title="Modifier le médecin"
          onClose={() => setEditingDoctor(null)}
        />
        <DialogContent>
          <DoctorForm
            editingDoctor={editingDoctor}
            onClose={() => setEditingDoctor(null)}
          />
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        open={!!deletingRecipient}
        title="Suppression d'un destinataire"
        message="Voulez-vous vraiment supprimer ce destinataire ?"
        onCancel={() => setDeletingRecipient(null)}
        onConfirm={onDeleteRecipient}
      />
    </>
  )
}

export default OrderRecipientList
