import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  Button,
  Grid,
  TextField,
  Switch,
  FormControlLabel,
} from '@mui/material'
import * as yup from 'yup'
import { useAppDispatch } from '@hooks/reduxHooks'

import { OrganismDTO } from '@/services/dtos/OrganismDto'
import {
  doCreateOrganism,
  doUpdateOrganism,
} from '../../state/reducers/organismReducer'

interface OrganismFormProps {
  onClose: () => void
  editingOrganism?: OrganismDTO | null
  formType: 'caisse' | 'mutuelle' | 'amo' | 'amc'
}

const schema = yup.object().shape({
  code: yup.string().required('Le code est requis'),
  regime: yup.string().required('Le régime est requis'),
  caisse: yup.string().required('La caisse est requise'),
  centre: yup.string().required('Le centre est requis'),
  libelle: yup.string().required('Le libellé est requis'),
  codeDestinataire: yup.string().required('Le code destinataire est requis'),
  taux_remboursement: yup
    .number()
    .min(0, 'Le taux doit être positif')
    .max(100, 'Le taux ne peut pas dépasser 100%')
    .required('Le taux de remboursement est requis'),
  num_organisme: yup.string().required('Le numéro d\'organisme est requis'),
  phoneNumber: yup.string(),
  adress: yup.string(),
  type: yup.number().required('Le type est requis'),
  teletransmissible: yup.boolean(),
  active: yup.boolean(),
})

const OrganismForm = ({
  onClose,
  editingOrganism,
  formType,
}: OrganismFormProps) => {
  const dispatch = useAppDispatch()

  const schema = yup.object().shape({
    code: yup.string().required('Le code est requis'),
    regime: yup.string().required('Le régime est requis'),
    ...(formType === 'caisse'
      ? {
          caisse: yup.string().required('La caisse est requise'),
          centre: yup.string().required('Le centre est requis'),
        }
      : {
          libelle: yup.string().required('Le libellé est requis'),
        }),
    codeDestinataire: yup.string().required('Le code destinataire est requis'),
    taux_remboursement: yup
      .number()
      .min(0, 'Le taux doit être positif')
      .max(100, 'Le taux ne peut pas dépasser 100%')
      .required('Le taux de remboursement est requis'),
    num_organisme: yup.string().required('Le numéro d\'organisme est requis'),
    phoneNumber: yup.string(),
    adress: yup.string(),
    type: yup.number().required('Le type est requis'),
    teletransmissible: yup.boolean(),
    active: yup.boolean(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...editingOrganism,
      type: formType === 'mutuelle' ? 2 : 1, // Not sure about those types need to check with Fabien
      active: editingOrganism?.active ?? true,
      teletransmissible: editingOrganism?.teletransmissible ?? false,
    },
  })

  const onSubmit = (data: any) => {
    if (editingOrganism) {
      dispatch(doUpdateOrganism({ ...data, id: editingOrganism.id }))
    } else {
      dispatch(doCreateOrganism(data))
    }
    onClose()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-4">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 className="text-2xl font-bold mb-4">
            {editingOrganism ? 'Modifier' : 'Ajouter'}{' '}
            {formType === 'caisse' ? 'une caisse' : 'une mutuelle'}
          </h2>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Code"
            {...register('code')}
            error={!!errors.code}
            helperText={errors.code?.message}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Régime"
            {...register('regime')}
            error={!!errors.regime}
            helperText={errors.regime?.message}
          />
        </Grid>

        {formType === 'caisse' ? (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Caisse"
                {...register('caisse')}
                error={!!errors.caisse}
                helperText={errors.caisse?.message}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Centre"
                {...register('centre')}
                error={!!errors.centre}
                helperText={errors.centre?.message}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Libellé"
              {...register('libelle')}
              error={!!errors.libelle}
              helperText={errors.libelle?.message}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Code Destinataire"
            {...register('codeDestinataire')}
            error={!!errors.codeDestinataire}
            helperText={errors.codeDestinataire?.message}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            type="number"
            label="Taux de remboursement (%)"
            {...register('taux_remboursement')}
            error={!!errors.taux_remboursement}
            helperText={errors.taux_remboursement?.message}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Numéro d'organisme"
            {...register('num_organisme')}
            error={!!errors.num_organisme}
            helperText={errors.num_organisme?.message}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Type"
            type="number"
            {...register('type')}
            error={!!errors.type}
            helperText={errors.type?.message}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Téléphone"
            {...register('phoneNumber')}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber?.message}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Adresse"
            {...register('adress')}
            error={!!errors.adress}
            helperText={errors.adress?.message}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                {...register('teletransmissible')}
                defaultChecked={editingOrganism?.teletransmissible}
              />
            }
            label="Télétransmissible"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                {...register('active')}
                defaultChecked={editingOrganism?.active ?? true}
              />
            }
            label="Actif"
          />
        </Grid>

        <Grid item xs={12} className="flex justify-end gap-4">
          <button
            className="bg-gray-600 hover:bg-gray-800 flex items-center gap-2 px-4 py-2 rounded-md text-white"
            onClick={onClose}
          >
            Annuler
          </button>
          <button
            className="bg-indigo-500 hover:bg-indigo-600 flex items-center gap-2 px-4 py-2 rounded-md text-white"
            type="submit"
          >
            {editingOrganism ? 'Modifier' : 'Ajouter'}
          </button>
        </Grid>
      </Grid>
    </form>
  )
}

export default OrganismForm
