import { RppsDto } from '@services/api'
import { DirectoryService } from '@services/api'
import { Close } from '@mui/icons-material'
import { Box, debounce, Grid, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useMemo, useRef, useState } from 'react'
import Text from '@components/Text'

type RppsAutocompleteByNameInputProps = {
  onChange: (rpps: RppsDto | null) => void
  label: string | React.ReactNode
  value: RppsDto | null
  disabled?: boolean
  size?: 'small' | 'medium'
  error?: string
}

const useStyles = makeStyles({
  capitalize: {
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  searchResult: {
    minHeight: 300,
  },
  backgroundOverlay: {
    backgroundColor: 'transparent',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'block',
    zIndex: 2,
  },
  resultBackground: {
    padding: '5px 15px',
    '&:nth-of-type(odd)': {
      backgroundColor: '#f5f5f5',
    },
  },
}) as any

export function RppsAutocompleteByNameInput({
  onChange: setValue,
  disabled,
  label,
  value,
  size,
  error,
}: RppsAutocompleteByNameInputProps) {
  const [options, setOptions] = useState<RppsDto[]>([])
  const [inputValue, setInputValue] = useState('')
  const [inputDisplay, setInputDisplay] = useState('')
  const [page, setPage] = useState(0)
  const limit = useRef(20).current
  const scrollPos = useRef(0)
  const listInnerRef = useRef<any>()
  const [isFetching, setIsFetching] = useState(false)

  const classes = useStyles()

  const autocompleteByName = async (name: string) => {
    try {
      setIsFetching(true)
      const doctors = await DirectoryService.autocompleteByName({
        name,
        page,
        limit,
      })
      const rppsMap: { [key: string]: RppsDto } = {}
      doctors.forEach((_doctor) => {
        if (rppsMap[_doctor.rppsNumber]) {
          if (!rppsMap[_doctor.rppsNumber].zipCode.includes(_doctor.zipCode)) {
            rppsMap[_doctor.rppsNumber].zipCode += ', ' + _doctor.zipCode
          }
        } else {
          rppsMap[_doctor.rppsNumber] = _doctor
        }
      })
      if (page !== 0) {
        setOptions([...options, ...Object.values(rppsMap)])
      } else {
        setOptions(Object.values(rppsMap))
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    } finally {
      setIsFetching(false)
    }
  }

  const fetch = useMemo(
    () =>
      debounce((v: string) => {
        autocompleteByName(v.toLowerCase())
      }, 500),
    [page, limit],
  )
  useEffect(() => {
    if (!listInnerRef.current) {
      return
    }
    setTimeout(() => {
      listInnerRef.current?.scrollTo(0, scrollPos.current)
    }, 10)
  }, [scrollPos.current, options.length])

  useEffect(() => {
    if (inputValue === '') {
      setOptions([])
      return undefined
    }
    if (!isFetching) {
      fetch(inputValue)
    }
  }, [inputValue, fetch])

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight >= scrollHeight - 0.6) {
        setPage(page + 1)
        scrollPos.current = scrollTop
      }
    }
  }

  const handleRppsClick = (rpps: RppsDto) => {
    setInputDisplay(
      rpps.firstName.charAt(0).toUpperCase() +
        rpps.firstName.slice(1) +
        ' ' +
        rpps.lastName.charAt(0).toUpperCase() +
        rpps.lastName.slice(1),
    )

    if (setValue) {
      setValue(rpps)
    }
    setOptions([])
    setPage(0)

    // Prevent fetch again because of scroll size bug
    setIsFetching(true)
    setTimeout(() => {
      setIsFetching(false)
    }, 600)
  }

  const renderRpps = (rpps: RppsDto) => {
    return (
      <Box
        key={rpps.id}
        justifyContent="center"
        onClick={() => handleRppsClick(rpps)}
        className={classes.resultBackground}
      >
        <Text
          text={
            rpps.firstName.charAt(0).toUpperCase() +
            rpps.firstName.slice(1) +
            ' ' +
            rpps.lastName.charAt(0).toUpperCase() +
            rpps.lastName.slice(1) +
            ' '
          }
          variant="h5"
          style={{
            color: '#E91E63',
            fontSize: '1.1em',
            fontWeight: 600,
          }}
        />
        <Text
          style={{
            textTransform: 'capitalize',
            fontWeight: 500,
            fontStyle: 'italic',
          }}
          text={
            rpps.profession !== 'Médecin'
              ? rpps.profession
              : rpps.skillType.trim()
              ? rpps.skillType
              : rpps.category
          }
        />
        <Text
          text={`N°RPPS: ${rpps.rppsNumber}`}
          variant="body1"
          style={{ fontSize: '14px', color: '#5e5e5e' }}
        />
        <Text
          text={
            rpps.zipCode.trim() !== ''
              ? `Lieux d'activité: (${rpps.zipCode})`
              : ''
          }
          variant="body1"
          style={{ fontSize: '14px', color: '#5e5e5e' }}
        />
      </Box>
    )
  }
  const _handleInputChange = (value: string) => {
    setInputValue(value)
    setInputDisplay(value)
    if (setValue) {
      setValue(null)
    }
    setOptions([])
    if (page !== 0) {
      setPage(0)
    }
  }
  const _handleOverlayClick = () => {
    setOptions([])
    if (page !== 0) {
      setPage(0)
    }
  }
  const _isAutocompleteVisible = options.length !== 0
  const _handleRppsUnselect = () => {
    setValue(null)
    setInputValue('')
    setInputDisplay('')
  }
  return (
    <Grid container style={{ position: 'relative' }}>
      {_isAutocompleteVisible && (
        <div
          id="backgroundOverlay"
          onClick={_handleOverlayClick}
          className={classes.backgroundOverlay}
        ></div>
      )}

      {!value && (
        <TextField
          variant="outlined"
          label={label}
          fullWidth
          size={size === 'small' ? 'small' : 'medium'}
          disabled={disabled}
          value={inputDisplay}
          onChange={(ev) => _handleInputChange(ev.target.value)}
          placeholder="Veuillez rechercher comme ceci: Prénom Nom"
          error={!!error}
          helperText={error}
        />
      )}
      {value && (
        <Grid container justifyContent={'center'} alignItems={'center'}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginBottom: '2px',
              alignItems: 'center',
            }}
          >
            {/* <FaUserMd style={{ fontSize: '1.5em', color: '#4ea5d9' }} /> */}
            <Typography
              variant="body1"
              color="primary"
              sx={{ marginLeft: '5px', color: '#4ea5d9', fontWeight: 'bold' }}
            >
              {label}
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            style={{
              backgroundColor: '#4ea5d9',
              borderRadius: 25,
              fontSize: 12,
              fontWeight: 'bold',
              padding: '12px',
              width: '100%',
            }}
          >
            <span
              style={{
                fontSize: '1.0em',
                color: '#fff',
                textShadow: '1px 1px 2px #3f3f3f90',
              }}
            >
              {value.firstName.charAt(0).toUpperCase() +
                value.firstName.slice(1) +
                ' ' +
                value.lastName.charAt(0).toUpperCase() +
                value.lastName.slice(1) +
                ' (RPPS: ' +
                value.rppsNumber +
                ')'}
            </span>
            <Close
              onClick={_handleRppsUnselect}
              style={{
                borderRadius: '50%',
                backgroundColor: '#fff',
                color: '#e44b8d',
                fontSize: '24px',
                fontWeight: 'bold',
                cursor: 'pointer',
                padding: 1,
                textAlign: 'right',
              }}
            />
          </Grid>
        </Grid>
      )}
      <div
        onScroll={() => handleScroll()}
        ref={listInnerRef}
        style={{
          top: 60,
          height: 210,
          display: options.length === 0 ? 'none' : 'block',
          width: '98%',
          overflowY: 'scroll',
          position: 'absolute',
          zIndex: 30,
          backgroundColor: '#e2e6f2',
          cursor: 'pointer',
          border: '1px solid #3f3f3f',
          borderRadius: 5,
          padding: 4,
        }}
      >
        {options.map((op) => renderRpps(op))}
      </div>
    </Grid>
  )
}
