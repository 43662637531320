import { getConfigs, IRequestConfig } from './api'
import { CreateRecipientsDTO } from './dtos'
import { axios } from './api'
import { RecipientDTO } from './dtos'

export class RecipientService {
  private static endpoint = `${process.env.REACT_APP_API_URL}/recipients`

  static getRecipients(
    query: { orderId?: number; visitId?: number },
  ): Promise<RecipientDTO[]> {

    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint,
        {},
      )
      configs.params = query

      axios(configs, resolve, reject)
    })
  }

  static addRecipient(
    dto: CreateRecipientsDTO,
  ): Promise<RecipientDTO> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        this.endpoint,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static updateRecipient(
    id: number,
    dto: Partial<CreateRecipientsDTO>,
  ): Promise<RecipientDTO> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        this.endpoint + `/${id}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static removeRecipient(
    id: number,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        this.endpoint + `/${id}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }
}
