import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PatientAldDTO } from '../../services/dtos'
import { AldService } from '../../services/AldService'

export interface AldState {
  list: PatientAldDTO[]
}

const initialState: AldState = {
  list: [],
}

export const doFetchALD = createAsyncThunk<PatientAldDTO[], number>(
  'ald/fetchALD',
  async (patientId) => {
    const response = await AldService.getALD(patientId)
    return response
  },
)

export const doCreateALD = createAsyncThunk<PatientAldDTO, PatientAldDTO>(
  'ald/createALD',
  async (ald) => {
    const response = await AldService.createALD(ald)
    return response
  },
)

export const doUpdateALD = createAsyncThunk<PatientAldDTO, PatientAldDTO>(
  'ald/updateALD',
  async (ald) => {
    const response = await AldService.updateALD(ald)
    return response
  },
)

export const doDeleteALD = createAsyncThunk<void, string>(
  'ald/deleteALD',
  async (id) => {
    await AldService.deleteALD(id)
  },
)

export const aldReducer = createSlice({
  name: 'ald',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doFetchALD.fulfilled, (state, action) => {
      state.list = action.payload
    })
    builder.addCase(doCreateALD.fulfilled, (state, action) => {
      state.list.push(action.payload)
    })
    builder.addCase(doUpdateALD.fulfilled, (state, action) => {
      state.list = state.list.map((ald) =>
        ald.id === action.payload.id ? action.payload : ald,
      )
    })
    builder.addCase(doDeleteALD.fulfilled, (state, action) => {
      state.list = state.list.filter((ald) => ald.id !== action.meta.arg)
    })
  },
})

export default aldReducer.reducer