import axios from 'axios'
import { OrganismDTO } from './dtos/OrganismDto'
import { PaginatedDto } from './extendedType'
import { BillingPaginatedResponse, FindOrganismQuery } from '../common/interfaces'



const BASE_URL = process.env.REACT_APP_API_URL + '/billing'

const handleApiResponse = async <T>(
  apiCall: () => Promise<any>,
): Promise<PaginatedDto<T>> => {
  try {
    const response = await apiCall()
    return {
      datas: response.data,
      totalCount: response.data.length,
    }
  } catch (error) {
    throw error instanceof Error
      ? error
      : new Error('An unknown error occurred')
  }
}

export const OrganismService = {
  get: async (query: FindOrganismQuery): Promise<PaginatedDto<OrganismDTO>> => {
    const hasActiveFilters =
      query.code || query.regime || query.caisse || query.centre

    if (!hasActiveFilters && query.Type) {
      switch (query.Type) {
        case 1:
          return await OrganismService.getAMO()
        case 2:
          return await OrganismService.getAMC()
        case 3:
          return await OrganismService.getMutuelles()
      }
    }
    return handleApiResponse(() =>
      axios.get(`${BASE_URL}/Organisme`, { params: query }),
    )
  },
  findAll: async (
    query: FindOrganismQuery,
  ): Promise<BillingPaginatedResponse<OrganismDTO>> => {
    const response = await axios.get(`${BASE_URL}/Organisme`, {
      params: query,
    })
    return response.data
  },
  getAMO: async (): Promise<PaginatedDto<OrganismDTO>> => {
    return handleApiResponse(() => axios.get(`${BASE_URL}/Organisme/amo`))
  },

  getAMC: async (): Promise<PaginatedDto<OrganismDTO>> => {
    return handleApiResponse(() => axios.get(`${BASE_URL}/Organisme/amc`))
  },

  getMutuelles: async (): Promise<PaginatedDto<OrganismDTO>> => {
    return handleApiResponse(() => axios.get(`${BASE_URL}/Organisme/mutuelle`))
  },

  create: async (organism: Partial<OrganismDTO>): Promise<OrganismDTO> => {
    const response = await axios.post(`${BASE_URL}/Organisme`, organism)
    return response.data
  },

  update: async (
    id: number,
    organism: Partial<OrganismDTO>,
  ): Promise<OrganismDTO> => {
    const response = await axios.put(`${BASE_URL}/Organisme/${id}`, organism)
    return response.data
  },

  remove: async (id: number): Promise<void> => {
    await axios.delete(`${BASE_URL}/Organisme/${id}`)
  },

  getById: async (id: number): Promise<OrganismDTO> => {
    const response = await axios.get(`${BASE_URL}/Organisme/${id}`)
    return response.data
  },
}
