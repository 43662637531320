import { useAppDispatch } from '@hooks/reduxHooks'
import { Link, Tab, Tabs } from '@mui/material'
import { ResipService } from '@services/ResipService'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { getCartVitalUrl } from '@state/thunks/cardReadThunk'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { MemberDto } from '../services/api'
import MemberAutocomplete from '../components/MemberAutocomplete'

const urlsMap = {
  getDashboardUrl: ResipService.getDashboardUrl,
  getScorUrl: ResipService.getScorUrl,
  getTeletransmissionUrl: ResipService.getTeletransmissionUrl,
  getReturnManagementUrl: ResipService.getReturnManagementUrl,
  getUserListUrl: ResipService.getUserListUrl,
  getUserConfigurationUrl: ResipService.getUserConfigurationUrl,
  getGeneralParametersUrl: ResipService.getGeneralParametersUrl,
  getConfigurationDisplayUrl: ResipService.getConfigurationDisplayUrl,
  getVersionsUrl: ResipService.getVersionUrl,
}

const ResipLayout = () => {
  const dispatch = useAppDispatch()
  const [cardReaderId, setcardReaderId] = useState('')
  const [currentUrl, setCurrentUrl] = useState('')
  const [activeTab, setactiveTab] = useState('getDashboardUrl')
  const [selectedMember, setSelectedMember] = useState<MemberDto | null>(null)

  const intl = useIntl()

  useEffect(() => {
    dispatch(getCartVitalUrl())
      .unwrap()
      .then((dto) => {
        setcardReaderId(dto.id)
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: err.message,
            options: { variant: 'error' },
          }),
        )
      })

    if (selectedMember) {
      urlsMap
        .getDashboardUrl(selectedMember.id.toString(), cardReaderId)
        .then(setCurrentUrl)
    }
  }, [dispatch, cardReaderId, selectedMember])

  const handleLinkClick = (key) => {
    const fn = urlsMap[key]
    if (fn) {
      fn('1', cardReaderId).then(setCurrentUrl)
    }
    setactiveTab(key)
  }

  const links = useMemo(() => {
    const keys = Object.keys(urlsMap)
    return keys.map((key) => (
      <Tab
        key={key}
        label={intl.formatMessage({ id: `resip.${key}` })}
        value={key}
        onClick={() => handleLinkClick(key)}
        component={Link}
        style={{
          backgroundColor: activeTab === key ? '#7e57c2' : '#fff',
          color: activeTab === key ? '#ffffff' : '#555',
          fontWeight: 'bold',
          borderRadius: '4px',
          padding: '2px 6px',
          margin: '0 4px',
          minWidth: '100px',
          textAlign: 'center',
        }}
      />
    ))
  }, [urlsMap, activeTab, intl])

  return (
    <div>
      <MemberAutocomplete
        label="Médecin"
        value={selectedMember}
        onChange={(v) => setSelectedMember(v)}
      />
      <Tabs
        value={activeTab}
        onChange={(_, value) => setactiveTab(value)}
        TabIndicatorProps={{ style: { display: 'none' } }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '6px 0',
        }}
      >
        {links}
      </Tabs>
      {!!currentUrl && (
        <iframe
          src={currentUrl}
          style={{
            width: '100%',
            height: 'calc(100vh - 156px)',
            border: 'none',
            borderRadius: '15px',
          }}
        />
      )}
    </div>
  )
}

export default ResipLayout
