import DialogTitle from '@components/dialogs/DialogTitle'
import { DialogContent } from '@mui/material'
import {
  Tooltip,
  Button,
  Icon,
  Container,
  Paper,
  Grid,
  DialogActions,
  TextField,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import theme from '@utils/theme'
import { useDispatch } from 'react-redux'
import { Actions } from '@state/actions'
import { useSelector } from '@state/store'
import { useCallback, useState } from 'react'
import { DataTable } from '@components/tables'
import {
  GridColumns,
  GridRowId,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'

export interface DialogProps {
  openPatientDetails: boolean
}

export default function TabCashRegisterManagement(props) {
  const dispatch = useDispatch()
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [openDetailsModal, setOpenDetailsModal] = useState(false)
  const [openMouvementModal, setOpenMouvementModal] = useState(false)
  const [moveSelectCash, setMoveSelectCash] = useState(false)

  const { loading } = useSelector(({ loading }) => ({
    loading:
      loading[Actions.PROCEDURE_TYPE_FIND_ALL] ||
      loading[Actions.PROCEDURE_TYPE_CREATE] ||
      loading[Actions.PROCEDURE_TYPE_UPDATE],
  }))

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }

  const onRefresh = useCallback(() => {
    // TODO
    // dispatch(findAllMedicalOrder())
  }, [dispatch])

  const columns: GridColumns = [
    {
      field: 'col1',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      renderCell: (value) => (
        <div
          style={{
            width: '100%',
          }}
        >
          {/* Fermé la caisse, consulter les mvt de la caisse, saisir une entré d'especes, saisir une sortie d'especes */}
          <Tooltip
            title={value.row.status ? 'Fermé la caisse' : 'Ouvir la caisse'}
            placement="top"
          >
            <Icon
              style={{ paddingRight: '15px' }}
              className={'fas fa-store' + (value.row.status ? '-slash' : '')}
              fontSize="small"
              // onClick={}
              sx={{ color: '#3F3F3F' }}
            />
          </Tooltip>
          <Tooltip title="Consulté les mouvements de caisse" placement="top">
            <Icon
              style={{ paddingRight: '15px' }}
              className="fas fa-file-signature"
              fontSize="small"
              onClick={() => {
                setOpenDetailsModal(true)
              }}
              sx={{ color: '#3F3F3F' }}
            />
          </Tooltip>
          <Tooltip title="Saisir une entré d'espèces" placement="top">
            <Icon
              style={{ paddingRight: '15px' }}
              className="fas fa-user-plus"
              fontSize="small"
              onClick={() => {
                setMoveSelectCash(true)
                setOpenMouvementModal(true)
              }}
              sx={{ color: '#3F3F3F' }}
            />
          </Tooltip>
          <Tooltip title="Saisir une sortis d'espèces" placement="top">
            <Icon
              style={{ paddingRight: '15px' }}
              className="fas fa-user-minus"
              fontSize="small"
              onClick={() => {
                setMoveSelectCash(false)
                setOpenMouvementModal(true)
              }}
              sx={{ color: '#3F3F3F' }}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'col2',
      headerName: 'Code',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.code}`,
    },
    {
      field: 'col3',
      headerName: 'Libellé',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.label}`,
    },
    {
      field: 'col4',
      headerName: 'Sites',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.sites}`,
    },
    {
      field: 'col5',
      headerName: 'Statut',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.status ? 'Ouvert' : 'Fermé'}`,
    },
    {
      field: 'col6',
      headerName: 'Date',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.date}`,
    },
    {
      field: 'col7',
      headerName: 'Heure',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.hour}`,
    },
    {
      field: 'col8',
      headerName: 'Utilisateur',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.user}`,
    },
    {
      field: 'col9',
      headerName: 'Solde',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.balance}`,
    },
  ]

  const medicalOrderMockup = [
    {
      id: 0,
      code: '11111',
      label: 'Caisse 1',
      sites: 'Site 1',
      status: true,
      date: '01/01/2021',
      hour: '12:30',
      user: 'Admin',
      balance: 243.42,
    },
    {
      id: 1,
      code: '33333',
      label: 'Caisse 2',
      sites: 'Site 2',
      status: false,
      date: '21/04/2000',
      hour: '12:05',
      user: 'Admin',
      balance: 603.42,
    },
    {
      id: 2,
      code: '44444',
      label: 'Caisse 3',
      sites: 'Site 3',
      status: true,
      date: '31/08/2011',
      hour: '12:40',
      user: 'Admin',
      balance: 143.42,
    },
  ]

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 0px' }}>
      <Grid container spacing={4} style={{ height: '96vh' }}>
        <Grid item container>
          <Paper
            elevation={2}
            style={{
              width: '100%',
              borderRadius: '0px 25px 25px 25px',
            }}
          >
            <DataTable
              rows={medicalOrderMockup}
              columns={columns}
              loading={loading}
              size={60}
              isDetails
              toolBarProps={{
                title: 'Gestion des caisses enregistreuses',
                icon: 'fas fa-cash-register',
                onRefresh,
                // onAdd: () => (true),
                // onDelete: () => {
                //   selectedRows.length
                //     ? (true)
                //     : alert('please select at least one reception')
                // },
              }}
              detailPanelHeight={400}
              form={(props) => <></>}
              checkboxSelection
              onSelectionModelChange={_onSelectionChange}
            />
          </Paper>
        </Grid>
        {/* Détails mvt caisses */}
        <Dialog
          open={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          maxWidth="lg"
          aria-labelledby="form-dialog-title"
          PaperProps={{
            sx: {
              borderRadius: 5,
              background: theme.palette.primary.main,
            },
          }}
        >
          <DialogTitle
            onClose={() => setOpenDetailsModal(false)}
            title="Détails mouvements de caisse"
            style={{
              background: '#fff',
              color: theme.palette.primary.main,
            }}
          />
          <DialogContent
            style={{
              minWidth: '250px',
              display: 'flex',
              justifyContent: 'center',
              background: '#E2E6F2',
            }}
          ></DialogContent>
          <DialogActions
            sx={{
              background: '#fff',
              paddingBottom: '20px',
              paddingTop: '20px',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
          >
            <Button
              sx={{
                ':hover': {
                  backgroundColor: '#333333',
                },
                color: 'white',
                background: '#3F3F3F',
                borderRadius: 5,
              }}
              variant="contained"
              color="success"
              fullWidth
              onClick={() => setOpenDetailsModal(false)}
            >
              Annuler
            </Button>
            <Button
              style={{ color: 'white' }}
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ borderRadius: 5 }}
              // onClick={_onDeleteConfirm}
            >
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
        {/* Entre/sorti */}
        <Dialog
          open={openMouvementModal}
          onClose={() => setOpenMouvementModal(false)}
          maxWidth="lg"
          aria-labelledby="form-dialog-title"
          PaperProps={{
            sx: {
              borderRadius: 5,
              background: theme.palette.primary.main,
            },
          }}
        >
          <DialogTitle
            onClose={() => setOpenMouvementModal(false)}
            title={
              moveSelectCash
                ? 'Saisir une entré d\'espèces'
                : 'Saisir une sortis d\'espèces'
            }
            style={{
              background: '#fff',
              color: theme.palette.primary.main,
            }}
          />
          <DialogContent
            style={{
              minWidth: '400px',
              display: 'flex',
              justifyContent: 'center',
              background: '#E2E6F2',
            }}
          >
            <TextField
              sx={{ marginTop: 5, marginBottom: 5 }}
              label={moveSelectCash ? 'Montant entré' : 'Montant sorti'}
              name=""
              size="medium"
              variant="outlined"
              InputProps={{
                endAdornment: '€',
              }}
              // helperText={errors}
              fullWidth
            />
          </DialogContent>
          <DialogActions
            sx={{
              background: '#fff',
              paddingBottom: '20px',
              paddingTop: '20px',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
          >
            <Button
              sx={{
                ':hover': {
                  backgroundColor: '#333333',
                },
                color: 'white',
                background: '#3F3F3F',
                borderRadius: 5,
              }}
              variant="contained"
              color="success"
              fullWidth
              onClick={() => setOpenMouvementModal(false)}
            >
              Annuler
            </Button>
            <Button
              style={{ color: 'white' }}
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ borderRadius: 5 }}
              onClick={() => setOpenMouvementModal(false)}
            >
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Container>
  )
}
