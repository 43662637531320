import React, { useState } from 'react'
import { Box, Tabs, Tab } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'
import { Receipt, Payments, CreditCard, ReceiptLong } from '@mui/icons-material'
import CashRegisterTable from '../../../../components/tables/CashRegisterTable'
import { Banknote } from 'lucide-react'
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'

const AccountingLayout = () => {
  const [value, setValue] = useState('0')

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <TabContext value={value}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: '#fafafa',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="accounting tabs"
          sx={{
            minHeight: '48px',
            '& .MuiTab-root': {
              minHeight: '48px',
              padding: '6px 12px',
              fontSize: '0.875rem',
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#4f46e5',
            },
            '& .Mui-selected': {
              color: '#4f46e5 !important',
            },
            '& .MuiTab-iconWrapper': {
              marginRight: '8px',
            },
          }}
        >
          <Tab
            icon={<PointOfSaleIcon />}
            iconPosition="start"
            label="Caisses"
            value="0"
          />
          <Tab
            icon={<Receipt />}
            iconPosition="start"
            label="Factures"
            value="1"
          />
          <Tab
            icon={<Payments />}
            iconPosition="start"
            label="Paiements"
            value="2"
          />
          <Tab
            icon={<ReceiptLong />}
            iconPosition="start"
            label="Journal"
            value="4"
          />
          <Tab
            icon={<AssuredWorkloadIcon />}
            iconPosition="start"
            label="Banque"
            value="5"
          />
          <Tab
            icon={<CreditCard />}
            iconPosition="start"
            label="Moyens de paiement"
            value="6"
          />
        </Tabs>
      </Box>
      <TabPanel value="0" sx={{ p: 0 }}>
        <CashRegisterTable />
      </TabPanel>
      <TabPanel value="1" sx={{ p: 0 }}>
        <div className="h-full p-4">Factures table coming soon</div>
      </TabPanel>
      <TabPanel value="2" sx={{ p: 0 }}>
        <div className="h-full p-4">Paiements table coming soon</div>
      </TabPanel>
      <TabPanel value="3" sx={{ p: 0 }}>
        <div className="h-full p-4">Banque table coming soon</div>
      </TabPanel>
      <TabPanel value="4" sx={{ p: 0 }}>
        <div className="h-full p-4">Journal table coming soon</div>
      </TabPanel>
      <TabPanel value="5" sx={{ p: 0 }}>
        <div className="h-full p-4">Comptes bancaires table coming soon</div>
      </TabPanel>
      <TabPanel value="6" sx={{ p: 0 }}>
        <div className="h-full p-4">Moyens de paiement table coming soon</div>
      </TabPanel>
    </TabContext>
  )
}

export default AccountingLayout
