import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { CreateRecipientsDTO, RecipientDTO } from '../../services/dtos'
import { RecipientService } from '../../services/RecipientService'
import { doUpdateDoctor } from './doctorReducer'

export interface RecipientState {
  recipients: RecipientDTO[]
}

const initialState: RecipientState = {
  recipients: [],
}


export const doGetRecipients = createAsyncThunk<
  RecipientDTO[],
  { orderId?: number; visitId?: number }
>('order/getRecipients', async (query) => {
  return RecipientService.getRecipients(query)
},
)

export const doAddRecipient = createAsyncThunk<
  RecipientDTO,
  CreateRecipientsDTO
>('order/addRecipient', async (dto) => {
  return RecipientService.addRecipient(dto)
})

export const doUpdateRecipient = createAsyncThunk<
  RecipientDTO,
  { id: number, dto: Partial<CreateRecipientsDTO> }
>('order/updateRecipient', async ({ id, dto }) => {
  return RecipientService.updateRecipient(id, dto)
})

export const doRemoveRecipient = createAsyncThunk<
  void,
  number
>('order/removeRecipient', async (id) => {
  await RecipientService.removeRecipient(id)
})

export const recipientSlice = createSlice({
  name: 'recipient',
  initialState,
  reducers: {
    setRecipients: (state, { payload }) => {
      state.recipients = payload
    },
  },
  extraReducers(builder) {
    builder.addCase(doGetRecipients.fulfilled, (state, { payload }) => {
      state.recipients = payload
    })
    builder.addCase(doAddRecipient.fulfilled, (state, { payload }) => {
      state.recipients.push(payload)
    })
    builder.addCase(doUpdateRecipient.fulfilled, (state, { payload }) => {
      const index = state.recipients.findIndex(
        (recipient) => recipient.id === payload.id,
      )
      console.log(index)
      state.recipients[index] = payload
    })
    builder.addCase(doRemoveRecipient.fulfilled, (state, { meta: { arg: id } }) => {
      state.recipients = state.recipients.filter(
        (recipient) => recipient.id !== id,
      )
    })
    builder.addCase(doUpdateDoctor.fulfilled, (state, { payload }) => {
      const index = state.recipients.findIndex(
        (recipient) => recipient.doctor?.id === payload.id,
      )
      if (index !== -1) {
        state.recipients[index].doctor = payload
      }
    })
  },
})

export const { setRecipients } = recipientSlice.actions

export default recipientSlice.reducer