import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { TextField, TextFieldProps } from '@mui/material'

type PhoneInputProps = TextFieldProps & {
  name: string
  control: Control<any>
  errors?: DeepMap<any, FieldError>
  readOnly?: boolean
}

export function PhoneInput({
  name,
  control,
  errors,
  readOnly,
}: PhoneInputProps) {
  const intl = useIntl()
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        // <PhoneInput
        //     country={'fr'}
        //     value={value}
        //     onChange={onChange}
        // />
        <TextField
          name={name}
          onChange={onChange}
          value={value}
          size="small"
          variant="outlined"
          fullWidth={true}
          InputLabelProps={{
            style: {
              // color: '#464855',
              color: readOnly ? 'rgba(0, 0, 0, 0.38)' : undefined,
            },
          }}
          InputProps={{
            style: {
              backgroundColor: '#fff',
              color: readOnly ? 'rgba(0, 0, 0, 0.38)' : undefined,
            },
            readOnly,
          }}
          placeholder={name && intl.formatMessage({ id: name })}
          label={name && intl.formatMessage({ id: name })}
          error={errors && !!errors[name]}
          helperText={
            errors &&
            errors[name] &&
            intl.formatMessage({ id: errors[name].message })
          }
        />
      )}
    />
  )
}
