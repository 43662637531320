import { Button, Divider } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import React, { useEffect, useState } from 'react'
import CouvertureDisplay from '../../containers/app/billing/form/PatientCouvertureData'
import { PatientDisplay } from './PatientInfos'
import { doGetCouverture } from '@state/thunks/patientsThunk'
import RegisterPatientForm from '../../containers/app/billing/form/RegisterPatientForm'

type Props = {
  patientId: number
  visitId?: number
  containerClassName?: string
}

const PatientDataContainer: React.FC<Props> = ({ patientId, visitId }) => {
  const dispatch = useAppDispatch()
  const [isEditMode, setisEditMode] = useState(false)

  const { patient, coverage } = useAppSelector(({ patients }) => ({
    patient: patients.currentPatientInfo,
    coverage: patients.patientCouverture,
  }))

  useEffect(() => {
    dispatch(
      doGetCouverture({
        idVisit: visitId,
        idPatient: patientId,
      }),
    )
      .unwrap()
      .then((res) => {
        if (!res) {
          dispatch(
            doGetCouverture({
              idPatient: patientId,
            }),
          )
        }
      })
  }, [visitId, patientId])

  return (
    <div>
      <div className="flex justify-start translate-y-[-4px] sticky top-0">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setisEditMode(!isEditMode)}
        >
          {isEditMode ? 'Terminer' : <>Modifier</>}
        </Button>
      </div>
      <Divider sx={{ my: 2 }} />
      {isEditMode ? (
        <RegisterPatientForm
          isOpen={isEditMode}
          onClose={() => setisEditMode(false)}
          mode="div"
        />
      ) : (
        <>
          <PatientDisplay patientId={patientId} />
          {patient && (
            <CouvertureDisplay
              couverture={
                coverage ||
                ({
                  id: 1,
                  beneficiaireNom: '',
                  beneficiairePrenom: '',
                  beneficiaireNir: '',
                  beneficiaireDateNaissance: '',
                  beneficaireNomUsuel: '',
                  assureNom: '',
                  assureNomUsuel: '',
                  assurePrenom: '',
                  assureNir: '',
                  rangNaissance: 1,
                  qualite: 1,
                  idOrganismeAmo: 1,
                  nomDomaineAmc: '',
                  dateFinAmc: '',
                  dateDebutAmc: '',
                  dateFinAmo: '',
                  dateDebutAmo: '',
                  codeSituationAmo: '',
                  idOrganismeAmc: 1,
                  idPatient: 1,
                  idMedicalOrder: 1,
                  identifiantHoteAmc: '',
                } as any)
              }
            />
          )}
        </>
      )}
    </div>
  )
}

export default PatientDataContainer
