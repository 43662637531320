import { createSlice } from '@reduxjs/toolkit'
import {
  CashRegisterDTO,
  CashRegisterLogDTO,
  CashRegisterTransactionDTO,
  ListCashRegisterDTO,
  ListCashRegisterLogDTO,
} from '../../services/dtos/AccountingV2Dto'

export interface CashRegisterState {
  list: CashRegisterDTO[]
  filter: ListCashRegisterDTO
  transactions: CashRegisterTransactionDTO[]
  logs: CashRegisterLogDTO[]
  logFilter: ListCashRegisterLogDTO
}

export const initialCashRegisterState: CashRegisterState = {
  list: [],
  filter: {},
  transactions: [],
  logs: [],
  logFilter: {},
}

export const cashRegisterReducer = createSlice({
  name: 'cashRegister',
  initialState: initialCashRegisterState,
  reducers: {},
})

export default cashRegisterReducer.reducer
