import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress, Tooltip } from '@mui/material'
import { CreateDoctorDto, DoctorDto, RppsDto } from '@services/api'
import { RppsAutocompleteByNameInput } from '@components/RppsAutocompleteByNameInput'
import { doctorSchema } from '@utils/schemas'
import { doCreateDoctor, doUpdateDoctor } from '@state/reducers/doctorReducer'
import { useAppDispatch } from '@hooks/reduxHooks'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { Star } from '@mui/icons-material'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'

type Props = {
  editingDoctor?: DoctorDto | null
  onClose: () => void
}

const DoctorForm: React.FC<Props> = ({ editingDoctor, onClose }) => {
  const {
    register,
    setValue,
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<CreateDoctorDto>({
    resolver: yupResolver(doctorSchema),
    defaultValues: editingDoctor || {
      addresses: [{}],
    },
  })

  const dispatch = useAppDispatch()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addresses',
  })

  const [rppsValue, setrppsValue] = useState<RppsDto | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [favoriteAddressIndex, setFavoriteAddressIndex] = useState(0)

  const handleRppsChange = (rpps: RppsDto | null) => {
    setrppsValue(rpps)

    if (!rpps) {
      return
    }

    setValue('rppsNumber', rpps.rppsNumber)
    setValue('firstName', rpps.firstName)
    setValue('lastName', rpps.lastName)
    setValue('email', rpps.email)
    setValue('skill', rpps.skillLabel)
    setValue('profession', rpps.professionLabel)
  }

  useEffect(() => {
    if (editingDoctor) {
      const rppsDto: RppsDto = {
        rppsNumber: editingDoctor.rppsNumber,
        firstName: editingDoctor.firstName,
        lastName: editingDoctor.lastName,
        email: editingDoctor.email,
        skill: editingDoctor.skill,
        profession: editingDoctor.profession,
        title: '',
        id: -1,
        fax: '',
        zipCode: '',
      } as RppsDto
      setrppsValue(rppsDto)
    }
  }, [editingDoctor])

  const onSubmit = (data: CreateDoctorDto) => {
    setIsSubmitting(true)
    const cb = (msg: string) => {
      dispatch(
        enqueueSnackbar({
          message: msg,
          options: { variant: 'success' },
          type: 'success',
        }),
      )
      onClose()
    }
    if (editingDoctor) {
      dispatch(doUpdateDoctor({ id: editingDoctor.id, dto: data }))
        .unwrap()
        .then(() => cb('Medecin modifié avec succès'))
        .finally(() => {
          setIsSubmitting(false)
        })
    } else {
      dispatch(doCreateDoctor(data))
        .unwrap()
        .then(() => cb('Medecin créé avec succès'))
        .finally(() => {
          setIsSubmitting(false)
        })
    }
  }

  const handleSetFavorite = (index: number) => {
    setFavoriteAddressIndex(index)
    // setValue('favoriteAddressIndex', index)
    // implement logic for favorite address
  }

  const handleRemoveAddress = (index: number) => {
    if (fields.length > 1) {
      if (index === activeTab) {
        setActiveTab(index > 0 ? index - 1 : 0)
      } else if (index < activeTab) {
        setActiveTab(activeTab - 1)
      }
      if (index === favoriteAddressIndex) {
        handleSetFavorite(0)
      } else if (index < favoriteAddressIndex) {
        handleSetFavorite(favoriteAddressIndex - 1)
      }
      remove(index)
    }
  }

  return (
    <div className="h-full w-full flex flex-col">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col h-full w-full max-w-7xl mx-auto"
      >
        <div className="bg-white rounded-lg flex-1 overflow-hidden flex flex-col">
          {/* Header */}
          <div className="px-6 py-4 border-b flex justify-between">
            <h2 className="text-xl font-semibold text-gray-800">
              Informations du médecin
            </h2>
            <div className="flex justify-end space-x-4 ">
              <button
                type="button"
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Annuler
              </button>
              <button
                type="submit"
                className={`px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-md hover:bg-purple-700 ${
                  isSubmitting ? 'opacity-50' : ''
                } border border-purple-600`}
                disabled={isSubmitting}
                onClick={(e) => {
                  e.stopPropagation()
                  handleSubmit(onSubmit)()
                }}
              >
                {isSubmitting && <CircularProgress size={18} />}
                Sauvegarder
              </button>
            </div>
          </div>

          <div className="flex-1 flex flex-col overflow-hidden">
            {/* Doctor Information Section */}
            <div className="p-6 space-y-4">
              {/* RPPS Search */}
              <div className="w-full">
                <div className="mt-1">
                  <RppsAutocompleteByNameInput
                    onChange={handleRppsChange}
                    label="RPPS"
                    value={rppsValue}
                    size="small"
                  />
                </div>
              </div>

              {/* Main Info Grid */}
              <div className="grid grid-cols-4 gap-1">
                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Nom *
                  </label>
                  <input
                    {...register('firstName')}
                    className="w-full px-2 py-1 capitalize border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter first name"
                  />
                  {errors.firstName && (
                    <p className="text-xs text-red-500">
                      {errors.firstName.message}
                    </p>
                  )}
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Prénom *
                  </label>
                  <input
                    {...register('lastName')}
                    className="w-full px-2 py-1 capitalize border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter last name"
                  />
                  {errors.lastName && (
                    <p className="text-xs text-red-500">
                      {errors.lastName.message}
                    </p>
                  )}
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Numero RPPS *
                  </label>
                  <input
                    {...register('rppsNumber')}
                    className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter RPPS number"
                  />
                  {errors.rppsNumber && (
                    <p className="text-xs text-red-500">
                      {errors.rppsNumber.message}
                    </p>
                  )}
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Spécialité
                  </label>
                  <input
                    {...register('skill')}
                    className="w-full px-2 py-1 capitalize border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter skill"
                  />
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    {...register('email')}
                    type="email"
                    className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter email"
                  />
                  {errors.email && (
                    <p className="text-xs text-red-500">
                      {errors.email.message}
                    </p>
                  )}
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Téléphone
                  </label>
                  <input
                    {...register('mobileNumber')}
                    className="w-full px-2 py-1 capitalize border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter phone"
                  />
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Fax
                  </label>
                  <input
                    {...register('phoneNumber')}
                    className="w-full px-2 py-1 capitalize border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter fax"
                  />
                </div>
              </div>
            </div>

            {/* Addresses Section */}
            <div className="px-6 pb-6 flex-1 flex flex-col min-h-0">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">
                Addresses
              </h3>

              {/* Tabs */}
              <div className="border-b border-gray-200">
                <div className="flex space-x-2">
                  {fields.map((field, index) => {
                    const addressName =
                      watch(`addresses.${index}.locationName`) ||
                      `Address ${index + 1}`
                    return (
                      <button
                        key={field.id}
                        type="button"
                        onClick={() => setActiveTab(index)}
                        className={`px-4 py-2 text-sm font-medium rounded-t-lg ${
                          activeTab === index
                            ? 'bg-indigo-50 text-purple-600 border-t border-l border-r border-gray-200'
                            : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                        }`}
                      >
                        {addressName}
                      </button>
                    )
                  })}
                  {/* Add New Tab Button */}
                  <button
                    type="button"
                    onClick={() => {
                      append({})
                      setActiveTab(fields.length)
                    }}
                    className="px-3 py-2 text-sm font-medium text-purple-600 rounded-t-lg hover:bg-purple-50 border-t border-l border-r border-transparent hover:border-gray-200"
                  >
                    <LocalHospitalIcon className="w-4 h-4" />
                  </button>
                </div>
              </div>

              {/* Scrollable Content */}
              <div className="flex-1 overflow-y-auto mt-4">
                {fields.map((field, index) => (
                  <div
                    key={field.id}
                    className={`${
                      activeTab === index ? 'block' : 'hidden'
                    } bg-white rounded-md`}
                  >
                    <div className="relative bg-indigo-50 p-4 rounded-md">
                      {/* Icons positioned absolutely in top-right corner */}
                      <div className="absolute top-2 right-2 flex space-x-2">
                        <Tooltip title="Set as favorite">
                          <button
                            type="button"
                            onClick={() => handleSetFavorite(index)}
                            className={`border border-1 px-1 rounded-sm ${
                              favoriteAddressIndex === index
                                ? 'text-yellow-500 border-yellow-500'
                                : 'text-gray-400 border-gray-200 hover:text-yellow-500'
                            }`}
                          >
                            <Star
                              fill={
                                favoriteAddressIndex === index
                                  ? 'currentColor'
                                  : 'none'
                              }
                            />
                          </button>
                        </Tooltip>
                        <Tooltip title="Delete address">
                          <button
                            type="button"
                            onClick={() => handleRemoveAddress(index)}
                            className="text-red-500 hover:text-red-700 border border-1 px-2 rounded-sm border-gray-200"
                          >
                            <i className="fas fa-times" />
                          </button>
                        </Tooltip>
                      </div>

                      {/* Form Grid */}
                      <div className="grid grid-cols-2 gap-1 mt-6">
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Nom de l&apos;adresse *
                          </label>
                          <input
                            {...register(`addresses.${index}.locationName`)}
                            className="w-full px-2 py-1 capitalize border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter location name"
                          />
                        </div>
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Type d&apos;établissement
                          </label>
                          <select
                            {...register(`addresses.${index}.locationType`)}
                            className="w-full px-2 py-1 capitalize border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                          >
                            <option value="">Select type</option>
                            <option value="Clinique">Clinique</option>
                            <option value="Hospital">Hospital</option>
                            <option value="Etablissement">Etablissement</option>
                          </select>
                        </div>
                        {/* Rest of the form fields */}
                        <div className="col-span-1 space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Adresse ligne 1 *
                          </label>
                          <input
                            {...register(`addresses.${index}.line1`)}
                            className="w-full px-2 py-1 capitalize border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter address line 1"
                          />
                        </div>
                        <div className="col-span-1 space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Adresse ligne 2
                          </label>
                          <input
                            {...register(`addresses.${index}.line2`)}
                            className="w-full px-2 py-1 capitalize border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter address line 2"
                          />
                        </div>
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Code postal *
                          </label>
                          <input
                            {...register(`addresses.${index}.postalCode`)}
                            className="w-full px-2 py-1 capitalize border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter postal code"
                          />
                        </div>
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Ville *
                          </label>
                          <input
                            {...register(`addresses.${index}.city`)}
                            className="w-full px-2 py-1 capitalize border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter city"
                          />
                        </div>
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            Pays *
                          </label>
                          <input
                            {...register(`addresses.${index}.country`)}
                            className="w-full px-2 py-1 capitalize border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter country"
                          />
                        </div>
                        <div className="space-y-1">
                          <label className="text-sm font-medium text-gray-700">
                            FINESS
                          </label>
                          <input
                            {...register(`addresses.${index}.finessNumber`)}
                            className="w-full px-2 py-1 capitalize border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter FINESS number"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default DoctorForm
