import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro'
import { Moment } from 'moment'
import { Control, useController } from 'react-hook-form'
import { makeStyles } from '@mui/styles'
import { FormHelperText } from '@mui/material'

const useStyles = makeStyles(() => ({
  datePicker: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 12,
      backgroundColor: '#f8f9fa',
      border: 'none',
      '& fieldset': {
        border: '1px solid #e9ecef',
      },
      '&:hover': {
        backgroundColor: '#f1f3f5',
      },
      '&.Mui-focused': {
        backgroundColor: '#fff',
        boxShadow: '0 0 0 2px #e9ecef',
      },
    },
    '& .MuiInputBase-input': {
      padding: 16,
    },
  },
}))

type Props = DatePickerProps<Moment> & {
  control?: Control<any>
}

export const DateInputField = ({ control, ...props }: Props) => {
  const classes = useStyles()

  const controllerProps = control
    ? useController({
        control,
        name: props.name || '',
      })
    : undefined

  return (
    <div>
      <DatePicker
        {...props}
        {...(controllerProps?.field || {})}
        slotProps={{
          textField: {
            fullWidth: true,
            InputProps: {
              sx: { '& .MuiInputAdornment-root': { order: -1 } },
            },
            InputLabelProps: {
              shrink: true,
            },
          },
        }}
        className={classes.datePicker}
      />
      {controllerProps?.fieldState.error && (
        <FormHelperText error>
          {controllerProps.fieldState.error.message}
        </FormHelperText>
      )}
    </div>
  )
}
