import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ORGANISM_TYPES, OrganismDTO } from '../../services/dtos/OrganismDto'
import { OrganismService } from '../../services/organismService'
import { BillingPaginatedResponse, FindOrganismQuery } from '../../common/interfaces'


export interface OrganismState {
  organisms: BillingPaginatedResponse<OrganismDTO>
  filters: FindOrganismQuery
  loading: boolean
  error: string | null
}

const initialState: OrganismState = {
  organisms: {
    items: [],
    totalCount: 0,
    page: 1,
    pageSize: 20,
  },
  filters: {
    page: 1,
    pageSize: 20,
    Type: ORGANISM_TYPES.AMO,
  },
  loading: false,
  error: null,
}

export const doFindOrganisms = createAsyncThunk<
  BillingPaginatedResponse<OrganismDTO>,
  void
>('organisms/find', async (_, { getState }) => {
  const state = getState() as { organisms: OrganismState }
  const query = state.organisms.filters

  const response = await OrganismService.findAll(query)
  return response
},
)

export const doCreateOrganism = createAsyncThunk<
  OrganismDTO,
  Partial<OrganismDTO>
>('organisms/create', async (data) => {
  const response = await OrganismService.create(data)
  return response
})

export const doUpdateOrganism = createAsyncThunk<
  OrganismDTO,
  Partial<OrganismDTO>
>('organisms/update', async (data) => {
  if (!data.id) throw new Error('ID is required for update')
  const response = await OrganismService.update(data.id, data)
  return response
})

export const doRemoveOrganism = createAsyncThunk<void, number>(
  'organisms/remove',
  async (id, { dispatch }) => {
    await OrganismService.remove(id)
    dispatch(doFindOrganisms())
  },
)

export const organismReducer = createSlice({
  name: 'organisms',
  initialState,
  reducers: {
    setOrganismFilters: (
      state,
      { payload }: PayloadAction<Partial<FindOrganismQuery>>,
    ) => {
      state.filters = {
        ...state.filters,
        ...payload,
        page: payload.page ?? 1,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doFindOrganisms.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(doFindOrganisms.fulfilled, (state, action) => {
        state.loading = false
        state.organisms = action.payload
      })
      .addCase(doFindOrganisms.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to fetch organisms'
        state.organisms = initialState.organisms
      })
      .addCase(doCreateOrganism.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(doCreateOrganism.fulfilled, (state, action) => {
        state.loading = false
        state.organisms.items.unshift(action.payload)
        state.organisms.totalCount += 1
      })
      .addCase(doCreateOrganism.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to create organism'
      })
      .addCase(doUpdateOrganism.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(doUpdateOrganism.fulfilled, (state, action) => {
        state.loading = false
        const index = state.organisms.items.findIndex(
          (organism) => organism.id === action.payload.id,
        )
        if (index !== -1) {
          state.organisms.items[index] = action.payload
        }
      })
      .addCase(doUpdateOrganism.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to update organism'
      })
      .addCase(doRemoveOrganism.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(doRemoveOrganism.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(doRemoveOrganism.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to remove organism'
      })
  },
})

export default organismReducer.reducer
export const { setOrganismFilters } = organismReducer.actions
